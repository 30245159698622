import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster } from 'react-hot-toast';
import UserConfig from "servicePages/config/userManager";

export default function AdLog(props){
  const {history} = props 
  const {shopName, adName} = props.match.params 
  const today = new Date()
  const todayYear = today.getFullYear() 
  const todayMonth = today.getMonth() + 1 
  const todayDate = today.getDate() 
  const todayText = `${todayYear}-${todayMonth}-${todayDate}`
  const adLogData = [
    {startTime: '09:08:01', endTime:'09:08:17'},
    {startTime: '09:08:48', endTime:'09:09:04'},
    {startTime: '09:09:35', endTime:'09:09:50'},
    {startTime: '09:10:22', endTime:'09:10:38'},
    {startTime: '09:10:48', endTime:'09:11:03'},
    {startTime: '09:11:09', endTime:'09:11:24'},
    {startTime: '09:11:19', endTime:'09:11:34'},
    {startTime: '09:11:50', endTime:'09:12:06'},
    {startTime: '09:11:56', endTime:'09:12:11'},
    {startTime: '09:12:21', endTime:'09:12:37'},
    {startTime: '09:12:42', endTime:'09:12:58'},
    {startTime: '09:12:52', endTime:'09:13:07'},
    {startTime: '09:13:23', endTime:'09:13:39'},
    {startTime: '09:13:29', endTime:'09:13:45'},
    {startTime: '09:13:54', endTime:'09:14:10'},
    {startTime: '09:14:16', endTime:'09:14:31'},
    {startTime: '09:14:25', endTime:'09:14:41'},
    {startTime: '09:14:56', endTime:'09:15:11'},
    {startTime: '09:15:03', endTime:'09:15:18'},
    {startTime: '09:15:12', endTime:'09:15:27'},
    {startTime: '09:15:42', endTime:'09:15:58'},
    {startTime: '09:15:49', endTime:'09:16:05'},
    {startTime: '09:16:13', endTime:'09:16:29'},
    {startTime: '09:16:36', endTime:'09:16:51'},
    {startTime: '09:16:45', endTime:'09:17:00'},
    {startTime: '09:17:16', endTime:'09:17:31'},
    {startTime: '09:17:23', endTime:'09:17:39'},
    {startTime: '09:17:47', endTime:'09:18:02'},
    {startTime: '09:18:10', endTime:'09:18:26'},
    {startTime: '09:18:18', endTime:'09:18:33'},
    {startTime: '09:18:49', endTime:'09:19:04'},
    {startTime: '09:18:57', endTime:'09:19:12'},
    {startTime: '09:19:20', endTime:'09:19:35'},
    {startTime: '09:19:44', endTime:'09:19:59'},
    {startTime: '09:19:51', endTime:'09:20:06'},
    {startTime: '09:20:22', endTime:'09:20:37'},
    {startTime: '09:20:31', endTime:'09:20:46'},
    {startTime: '09:20:53', endTime:'09:21:08'},
    {startTime: '09:21:18', endTime:'09:21:33'},
    {startTime: '09:21:24', endTime:'09:21:39'},
    {startTime: '09:21:55', endTime:'09:22:10'},
    {startTime: '09:22:05', endTime:'09:22:20'},
    {startTime: '09:22:26', endTime:'09:22:41'},
    {startTime: '09:22:52', endTime:'09:23:07'},
    {startTime: '09:22:57', endTime:'09:23:12'},
    {startTime: '09:23:28', endTime:'09:23:43'},
    {startTime: '09:23:39', endTime:'09:23:55'},
    {startTime: '09:23:59', endTime:'09:24:15'},
    {startTime: '09:24:27', endTime:'09:24:43'},
    {startTime: '09:24:30', endTime:'09:24:45'},
    {startTime: '09:25:01', endTime:'09:25:16'},
    {startTime: '09:25:14', endTime:'09:25:29'},
    {startTime: '09:25:32', endTime:'09:25:47'},
    {startTime: '09:26:00', endTime:'09:26:16'},
    {startTime: '09:26:03', endTime:'09:26:19'},
    {startTime: '09:26:34', endTime:'09:26:50'},
    {startTime: '09:26:47', endTime:'09:27:03'},
    {startTime: '09:27:05', endTime:'09:27:21'},
    {startTime: '09:27:34', endTime:'09:27:50'},
    {startTime: '09:27:36', endTime:'09:27:52'},
    {startTime: '09:28:07', endTime:'09:28:23'},
    {startTime: '09:28:21', endTime:'09:28:36'},
    {startTime: '09:28:38', endTime:'09:28:54'},
    {startTime: '09:29:07', endTime:'09:29:23'},
    {startTime: '09:29:09', endTime:'09:29:25'},
    {startTime: '09:29:41', endTime:'09:29:56'},
    {startTime: '09:29:54', endTime:'09:30:10'},
    {startTime: '09:30:11', endTime:'09:30:27'},
    {startTime: '09:30:41', endTime:'09:30:57'},
    {startTime: '09:30:43', endTime:'09:30:59'},
    {startTime: '09:31:45', endTime:'09:32:00'},
    {startTime: '09:32:15', endTime:'09:32:31'},
    {startTime: '09:32:30', endTime:'09:32:46'},
    {startTime: '09:32:46', endTime:'09:33:02'},
    {startTime: '09:33:17', endTime:'09:33:33'},
    {startTime: '09:33:17', endTime:'09:33:33'},
    {startTime: '09:33:48', endTime:'09:34:04'},
    {startTime: '09:34:04', endTime:'09:34:20'},
    {startTime: '09:34:19', endTime:'09:34:35'},
    {startTime: '09:34:50', endTime:'09:35:06'},
    {startTime: '09:34:51', endTime:'09:35:06'},
    {startTime: '09:35:21', endTime:'09:35:37'},
    {startTime: '09:35:38', endTime:'09:35:53'},
    {startTime: '09:35:52', endTime:'09:36:08'},
    {startTime: '09:36:23', endTime:'09:36:39'},
    {startTime: '09:36:24', endTime:'09:36:40'},
    {startTime: '09:36:54', endTime:'09:37:10'},
    {startTime: '09:37:11', endTime:'09:37:27'},
    {startTime: '09:37:25', endTime:'09:37:41'},
    {startTime: '09:37:56', endTime:'09:38:12'},
    {startTime: '09:37:59', endTime:'09:38:14'},
    {startTime: '09:38:27', endTime:'09:38:43'},
    {startTime: '09:38:46', endTime:'09:39:01'},
    {startTime: '09:38:58', endTime:'09:39:14'},
    {startTime: '09:39:29', endTime:'09:39:45'},
    {startTime: '09:39:32', endTime:'09:39:48'},
    {startTime: '09:40:00', endTime:'09:40:16'},
    {startTime: '09:40:19', endTime:'09:40:35'},
    {startTime: '09:40:31', endTime:'09:40:47'},
    {startTime: '09:41:02', endTime:'09:41:18'},
    {startTime: '09:41:06', endTime:'09:41:21'},
    {startTime: '09:41:33', endTime:'09:41:49'},
    {startTime: '09:41:52', endTime:'09:42:08'},
    {startTime: '09:42:04', endTime:'09:42:20'},
    {startTime: '09:42:35', endTime:'09:42:51'},
    {startTime: '09:42:39', endTime:'09:42:55'},
    {startTime: '09:43:06', endTime:'09:43:22'},
    {startTime: '09:43:26', endTime:'09:43:42'},
    {startTime: '09:43:37', endTime:'09:43:53'},
    {startTime: '09:44:08', endTime:'09:44:24'},
    {startTime: '09:44:13', endTime:'09:44:28'},
    {startTime: '09:44:39', endTime:'09:44:55'},
    {startTime: '09:44:59', endTime:'09:45:15'},
    {startTime: '09:45:11', endTime:'09:45:26'},
    {startTime: '09:45:42', endTime:'09:45:57'},
    {startTime: '09:45:46', endTime:'09:46:02'},
    {startTime: '09:46:13', endTime:'09:46:28'},
    {startTime: '09:46:33', endTime:'09:46:49'},
    {startTime: '09:46:44', endTime:'09:47:00'},
    {startTime: '09:47:15', endTime:'09:47:31'},
  ]

  return(
    <div className="flex flex-col container mx-auto pt-12 px-80">
      <p className="text-xl font-nexon self-start pb-8">{shopName} 실시간 광고 표출 로그</p>
      <table className="min-w-full divide-y divide-gray-300 table-auto">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold font-nexon text-gray-900 sm:pl-6 md:pl-0"> # </th>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold font-nexon text-gray-900 sm:pl-6 md:pl-0"> 광고명 </th>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold font-nexon text-gray-900"> 시작 시간 </th>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold font-nexon text-gray-900"> 종료 시간 </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {adLogData.map((data, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-nexon font-medium text-gray-900 sm:pl-6 md:pl-0">{idx}</td>
              <td className="whitespace-nowrap py-4 text-sm font-nexon text-gray-500">{adName}</td>
              <td className="whitespace-nowrap py-4 text-sm font-nexon text-gray-500">{`${todayText} ${data.startTime}`}</td>
              <td className="whitespace-nowrap py-4 text-sm font-nexon text-gray-500">{`${todayText} ${data.endTime}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}