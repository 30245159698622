import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import UserConfig from "servicePages/config/userManager";
import { CtnDataGet } from "servicePages/config/indexedDB";

export default function AdPayment(props){
  // params : advertismenet date info 
  console.log(props)
  const {history} = props 
  // const {params, adName, content, shopData, shopPK, totalPrice} = props.history.location.state.data 
  const [contentBase64, setCtnBase64] = useState(null) 
  const {params, adName, content, shopData, shopPK, totalPrice, ctnPK} = JSON.parse(localStorage.getItem('tempData'))
  const [adList, setAdList] = useState([])
  const [user, setUser] = useState({})
  const { IMP } = window;
  const product = `${shopData.shopName} POP 스크린 광고`
  const method = 'card' 

  IMP.init('imp28060813'); // 'imp00000000' 대신 발급받은 가맹점 식별코드를 사용합니다.  

  useEffect(() => {
    window.scrollTo(0, 0);
    userInit()
    adListGeneration()
    getContentBlob() 
  }, [])


  const userInit = () => {
    const isLogged = UserConfig.isLogged() 
    const userInfo = UserConfig.getUserInfo() 
    setUser(userInfo) 
  }

  const adListGeneration = () => {
    const tempParam = _.cloneDeep(params)
    tempParam.forEach(element => {
      let dateObj = new Date(element['year'], element['month'], 0)
      let lastDate = dateObj.getDate() 
      element['startDate'] = 1 
      element['lastDate'] = lastDate
    })
    setAdList(tempParam)
  }

  const getContentBlob = async() => {
    const ctnBase64 = await CtnDataGet(ctnPK)
    setCtnBase64(ctnBase64)
  }

  const paySubmit = async() => {
    const data = {
      // pg: 'html5_inicis.INIpayTest',
      pg: 'html5_inicis.MOIscree45',
      pay_method: method,                           // 결제수단
      merchant_uid: `mid_0${new Date().getTime()}${user.pk}`,
      amount: totalPrice,                                 // 결제금액
      name: product,
      buyer_name: user.name,                           // 구매자 이름
      buyer_tel: user.phone,                     // 구매자 전화번호
      buyer_email: user.email,               // 구매자 이메일
      buyer_addr: null,                    // 구매자 주소
      buyer_postcode: null,                      // 구매자 우편번호
    }

    IMP.request_pay(data, callbackFunc)
  }

  const callbackFunc = (response) => {
    const {success, imp_uid, merchant_uid} = response
    if (success === true){
      content.base64 = contentBase64
      const data = { 
        imp_uid: imp_uid, 
        merchant_uid: merchant_uid, 
        product: product, 
        method: method, 
        totalPrice: totalPrice, 
        shopPK: shopPK,
        userPK: user.pk,
        adContent: content, 
        realPrice: totalPrice, 
        couponPK: null,
        adSchedule: adList, 
        adName: adName
      }
      console.log(data) 
      props.history.push('/service/payment/ad/complete', {data: data})
    }else{
      return false 
    }
    console.log('response')
    console.log(response) 
  }
  
  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      <div className="border-t-2 border-t-gray-400 h-1"/> 
      {/* <div className="flex flex-col flex-grow h-full"> */}
        <div className="flex flex-row justify-between w-full container mx-auto px-24 py-12 min-h-screen h-max">
          {/* 결제 사항 안내  */}
          <div className="flex flex-col justify-start" style={{width: '55%'}}>
            <div className="flex flex-row justify-start gap-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 self-center text-black font-bold cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => history.replace(`/service/shops/${shopPK}`)}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
              <p className="text-3xl font-nexonBold self-center">예약 확인</p>
            </div>

            <p className="text-3xl font-nexonBold pt-12 self-start">예약 정보</p>
            <div className="flex flex-row justify-between pt-12 pb-2">
              <p className="text-xl font-nexon self-center">광고명</p>
              <p className="text-xl font-nexon self-center">{adName}</p>
            </div>
            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">광고 매체</p>
              <p className="text-xl font-nexon self-center">{shopData.adName}</p>
            </div>
            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">매체 운영매장</p>
              <p className="text-xl font-nexon self-center">{shopData.shopName}</p>
            </div>
            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">운영매장 주소</p>
              <p className="text-xl font-nexon self-center">{shopData.shopAddr}</p>
            </div>

            {/* Divide Line */}
            <div className="flex flex-row border-b-2 border-b-gray-500 h-1 my-6"/> 

            <div className="flex flex-row justify-between pt-2">
              <p className="text-xl font-nexon self-center">광고 기간</p>
              <p className="text-xl font-nexonBold self-center text-rose-500">{`총 ${params.length}개월`}</p>
            </div>

            {adList.map((data, idx) => (
              <div className="flex flex-row justify-start pt-2">
                <p className="pl-2 text-xl font-nexon self-start">{`- ${data.year}년 ${data.month}월 ${data.startDate}일부터 ~ ${data.lastDate}일까지`}</p>
                {data.thisMonth === true ? (<p className="text-lg font-nexonBold self-end text-rose-500 pl-2">(당월 광고) </p>) : null}
              </div> 
            ))}

            {/* Divide Line */}
            <div className="flex flex-row border-b-2 border-b-gray-500 h-1 my-6"/> 

            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">광고 콘텐츠</p>
              <p className="text-xl font-nexon self-center">{content.contentName}</p>
            </div>
            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">콘텐츠 타입</p>
              <p className="text-xl font-nexon self-center">{content.type === 'image' ? "이미지" : "비디오"}</p>
            </div>
            <div className="flex flex-row justify-between py-2">
              <p className="text-xl font-nexon self-center">콘텐츠 해상도</p>
              <p className="text-xl font-nexon self-center">{`${content.width}x${content.height}px`}</p>
            </div>
            
          </div>



          {/* Thnumnail 및 pay */}
          <div className="flex flex-col justify-start" style={{width: '40%'}}>
            <div className="flex flex-col rounded-xl border-2 border-gray-300 p-6 mt-24">
              <div className="flex flex-row justify-start">
                <div className="flex flex-row justify-center" style={{width: '35%'}}>
                  <img className="rounded-lg" src={`${HOST_ADDR.slice(0, -1)}${shopData.shopThumb}`}/> 
                </div>
                <div className="flex flex-col justify-start pl-4" style={{width: '65%'}}>
                  <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col justify-start">
                      <div className="flex flex-row justify-center rounded-lg bg-rose-500 w-24">
                        <p className="text-lg font-nanumBold text-white self-center py-0.5">{shopData.adType}</p>
                      </div>
                      <p className="text-xl font-nanumBold text-black self-start pt-2">{shopData.shopName}</p>
                    </div>
                    <div>
                      <p className="text-xl font-nanumBold text-black self-start">{shopData.startTime} 오픈</p>
                      <p className="text-xl font-nanumBold text-black self-start">{shopData.endTime} 마감</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Divide Line */}
              <div className="flex flex-row border-b-2 border-b-gray-500 h-1 my-6"/> 

              <p className="text-2xl font-nanumBold self-start">보장 항목</p>
              <div className="flex flex-row justify-between py-2 mt-2">
                <p className="text-xl font-nexon self-center">1회당 광고 송출 시간</p>
                <p className="text-xl font-nexon self-center">15초</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="text-xl font-nexon self-center">1일 송출 보장 횟수</p>
                <p className="text-xl font-nexon text-rose-500 self-center">120회</p>
              </div>

              <div className="flex flex-row border-b-2 border-b-gray-500 h-1 my-6"/> 

              <p className="text-2xl font-nanumBold self-start">결제 정보</p>
              <div className="flex flex-row justify-between py-2 mt-2">
                <p className="text-xl font-nexon self-center">총 결제 금액</p>
                <p className="text-xl font-nexon self-center">{totalPrice.toLocaleString()}원</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="text-xl font-nexon self-center">결제 수단</p>
                <p className="text-xl font-nexon self-center">신용/체크카드</p>
              </div>
              <div className="flex flex-row justify-center rounded-lg bg-rose-500 py-2.5 mt-4 cursor-pointer" onClick={() => paySubmit()}>
                <p className="text-2xl text-white font-nexon self-center">결제하기</p>
              </div>

            </div>
          </div>
        </div>
      {/* </div> */}

      <div className="flex flex-col justify-end self-end flex-grow">
        <Footer props={props}/> 
      </div>
    </> 
  )
}