import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import Lottie from 'react-lottie'
import partnerAni from '../../static/animation/partnerAni'

export default function PartnerReqWelcome(props){
  const {history} = props 

  useEffect(() => {

  }, [])

  const movePage = (routeTarget) =>{
    history.push('/service/', {})
  }

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: partnerAni,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto px-28 py-12 flex flex-row justify-center h-screen">
        <div className="flex flex-col flex-grow justify-start self-start w-full h-full mt-12">
          <div className="flex flex-row justify-center w-full h-96">
            <Lottie options={defaultOptions} width={400} height={400}/>
          </div> 
          <p className="text-2xl font-nexonBold text-black self-center pb-12">제휴 입점 신청이 완료되었습니다!</p>
          <p className="text-lg font-nexon text-black self-center pt-2">스크린에어의 제휴 입점을 신청해주셔서 진심으로 감사드립니다.</p>
          <p className="text-lg font-nexon text-black self-center pt-1">최대한 빠른 시일 내에 연락 드리도록 하겠습니다.</p>
          <p className="text-lg font-nexon text-black self-center pt-1">예상 소요일: 1~2 영업일</p>
          <div className="flex flex-row rounded-lg bg-rose-500 mt-4 w-3/12 self-center justify-center py-2 cursor-pointer" onClick={() => movePage('/service/')}>
            <p className="text-lg font-nexon text-white self-center">메인으로 돌아가기</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow self-end">
        <Footer props={props}/> 
      </div>
    </>
  )
}