import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer"
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import UserConfig from "servicePages/config/userManager";
import { CtnDataGet, CtnDataInsert } from "servicePages/config/indexedDB";
import Lottie from "react-lottie";
import Loading from '../../static/animation/loading'
  
export default function ShopDetail(props){
  const {history} = props 
  const navermaps = window.naver.maps 
  // const [navermaps, setNaverMap] = useState(null)
  const dateObj = new Date() 
  const currentYear = dateObj.getFullYear() 
  const currentMonth = dateObj.getMonth() + 1 
  const [yearDate, setYearDate] = useState(currentYear)
  const [tempYear, setTempYear] = useState(currentYear)
  const [monthDict, setMonthDict] = useState({}); 
  const [content, setContent] = useState({})
  const [ctnb64, setCtnB64] = useState(null) 
  const adContentInp = useRef(null) 
  const [shopData, setShopData] = useState({})
  const [isLoading, setLoading] = useState(true) 
  const [ffmpegLoading, setFFmpegLoading] = useState(true) 
  const [info, setInfo] = useState({infoActive: false, infoText:null})
  const [totalPrice, setTotalPrice] = useState(0)
  const [adMonth, setAdMonth] = useState(0)
  const [userLogged, setUserLogged] = useState(false) 
  const [shopLike, setShopLike] = useState(false) 
  const [ctnProgress, setCtnProgress] = useState(false)
  const [resCheck, setResCheck] = useState(false) 
  const [durCheck, setDurCheck] = useState(false) 
  const adNameRef = useRef(null) 
  const pk = props.match.params.pk

  // NOTE: FFmpeg based image resolution control function is deprecated. 
  // const ffmpeg = createFFmpeg({log:true})

  useEffect(() => {
    // ffmpegLoadTest()
    getShopsDetail()
    getPeriod(yearDate)
    setUserLogged(UserConfig.isLogged())
  }, [])

  useEffect(() => {
    console.log('loading')
    if (isLoading === true){
      if (Object.keys(shopData).length === 0 || Object.keys(monthDict).length === 0){
        return false 
      }else{
        setLoading(false) 
      }
    }
  }, [monthDict, shopData])

  // NOTE: FFmpeg based image resolution control function is deprecated. 
  // const ffmpegLoadTest = async() => {
  //   try{
  //     await ffmpeg.load()
  //     setFFmpegLoading(false)
  //   }catch(e){
  //     window.location.reload()
  //     return false 
  //   }
  // }

  const getShopsDetail = async() =>{
    const reqURI = `${HOST_ADDR}app/v1/shops/get/shop/${pk}`
    try{
      const res = await axios.get(reqURI)
      const resData = res.data.data 
      console.log(resData)
      setShopData(resData)
      const isLogin = UserConfig.isLogged()
      if (isLogin === true){
        await getShopLike() 
        // await ffmpegLoadTest()
      }
    }catch(e){ 
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const getShopLike = async() => {
    const reqURI = `${HOST_ADDR}app/v1/shops/like/${pk}`
    const token = UserConfig.getAccKey() 
    const headers = { headers : {Authorization:`Bearer ${token}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      setShopLike(resData.data)
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const getPeriod = async(reqYear) => {
    const shopPK = pk 
    const year = reqYear
    const reqURI = `${HOST_ADDR}app/v1/shops/get/shopPeriod/${shopPK}/${year}/`
    try{
      const res = await axios.get(reqURI)
      const resData = res.data 
      console.log(resData)
      if (resData.result === 'OK'){
        let tempMonthDict = _.cloneDeep(monthDict); 
        tempMonthDict[year] = resData.data
        setMonthDict(tempMonthDict)
        setYearDate(year)
      }
    }catch(e){
      if (e.code === 'ERR_NETWORK'){
        alert("예상치 못한 오류가 발생하였습니다.\n잠시 후 다시 시도해주시기 바랍니다.")
        return false 
      }
      alert("예상치 못한 오류가 발생하였습니다.\n잠시 후 다시 시도해주시기 바랍니다.")
    }
  }

  const upload = () =>{
    if (ctnProgress === true ){
      return false 
    }
    adContentInp.current.click()
  }

  const uploaded = async(e) => {
    // NOTE: 업로드 한 상태에서 다시 업로드하려다 취소한 경우 발생하는 에러 방지 
    if (adContentInp.current.value.length === 0){
      return false 
    }

    // FFMPEG load 
    // try{
    //   await ffmpeg.load() 
    // }catch(e){
    //   console.log('') 
    // }
    
    setCtnProgress(true)

    const file = e.target.files[0];
    const contentName = file.name 
    const type = file.type.split('/')[0] 
    const ext = file.type.split('/')[1] 
    let width = null 
    let height = null 
    let duration = null 

    // marking 
    if (type === 'image'){
      const img = new Image() 
      const reader = new FileReader() 

      let width, height 
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        width = img.width 
        height = img.height 
        reader.readAsDataURL(file) 
      }

      reader.onloadend = (e) => {
        const content = {contentName: contentName, ext: ext, width: width, height: height, type: type, duration:duration}
        if (shopData.adResWidth === width && shopData.adResHeight === height){
          setResCheck(true) 
          setDurCheck(true) 
        }else{
          setResCheck(false) 
        }
        setContent(content) 
        setCtnB64(e.target.result)
      }
    }else if (type === 'video'){
      const video = document.createElement('video')
      video.src = window.URL.createObjectURL(file) 

      video.onloadedmetadata = () => {
        const width = video.videoWidth 
        const height = video.videoHeight 
        const reader = new FileReader() 
        duration = video.duration       

        console.log(width)

        reader.onload = (e) => {
          const content = {contentName: contentName, ext: ext, width: width, height: height, type: type, duration:duration}
          if (duration > 15){
            setDurCheck(false) 
          }else{
            setDurCheck(true) 
          }
          setContent(content) 
          setCtnB64(e.target.result) 
        }
        reader.readAsDataURL(file) 
      }
    }else{
      // TODO: alert 
    }

    setCtnProgress(false)

    // if(type === 'image'){
    //   ffmpeg.FS('writeFile', 'temp.png', await fetchFile(file))
    //   await ffmpeg.run('-i', 'temp.png', '-vf', 'scale=768:1440,setsar=1', 'output.png')
    //   const data = ffmpeg.FS('readFile', 'output.png') 
  
    //   const image = new Image() 
    //   image.src = URL.createObjectURL(new Blob([data.buffer], {type : 'image/png'})) 

    //   image.onload = () => {
    //     const src = image.src 
    //     const blob = new Blob([data.buffer], {type:'image/png'})
    //     const width = image.width 
    //     const height = image.height 
  
    //     const reader = new FileReader() 
    //     reader.onload = (e) => {
    //       const content = {contentName: contentName, ext: ext, width: width, height: height, type: type, duration:duration}
    //       setContent(content) 
    //       setCtnB64(e.target.result) 
    //     }
    //     reader.readAsDataURL(blob) 
    //   }
    // }else if (type === 'video'){
    //   ffmpeg.FS("writeFile", 'temp.mp4', await fetchFile(file)) 
    //   await ffmpeg.run('-i', 'temp.mp4', '-vf', 'scale=768:1440,setsar=1', '-b:v', '2500k', '-ss', '00:00:00', '-to', '00:00:15', 'output.mp4')
    //   const data = ffmpeg.FS('readFile', 'output.mp4') 

    //   const video = document.createElement('video')
    //   video.src = URL.createObjectURL(new Blob([data.buffer], {type : 'video/mp4'}))

    //   video.onloadedmetadata = () =>{
    //     const blob = new Blob([data.buffer], {type:'video/mp4'})
    //     const width = video.videoWidth 
    //     const height = video.videoHeight 
    //     duration = video.duration 

    //     const reader = new FileReader() 
    //     reader.onload = (event) => {
    //       const content = {contentName: contentName, ext: ext, width: width, height: height, type: type, duration:duration}
    //       setContent(content) 
    //       setCtnB64(event.target.result) 
    //     }
    //     reader.readAsDataURL(blob) 
    //   }
    // }
    // ffmpeg.exit()
    // setCtnProgress(false)
    return false 
  }

  const contentView = () => {
    if (content.type === 'image'){
      const image = new Image() 
      image.src = ctnb64
      image.onload = () => {
        const w = window.open("", "_blank", `location=yes,height=${content.height},width=${content.width},scrollbars=yes,status=yes`)
        w.document.write(image.outerHTML)
        w.document.close()
      }
    }else if (content.type === 'video'){
      const video = document.createElement('video')
      video.src = ctnb64
      video.onloadedmetadata = () => {
        const w = window.open("", "_blank", `location=yes,height=${content.height},width=${content.width},scrollbars=yes,status=yes`)
        w.document.write(video.outerHTML)
        w.document.close()
      }
    }
    return false 
  }

  const selectMonth = (idx, year, mode) => {
    let tempDict = _.cloneDeep(monthDict)
    let tempArr = tempDict[yearDate]
    const now = new Date()
    if (mode === 'select'){  
      const oneAfterYear = new Date(now.setMonth(now.getMonth()+12)) 
      const baseYear = oneAfterYear.getFullYear() 
      const baseMonth = oneAfterYear.getMonth()+1 
      // 1년 이내만 광고 설정
      if (yearDate > baseYear){
        alert('광고 스케줄은 1년 이내로만 설정할 수 있습니다.') 
        return false 
      }

      if (yearDate >= baseYear && idx+1 > baseMonth){
        alert('광고 스케줄은 1년 이내로만 설정할 수 있습니다.') 
        return false 
      }
      // 1년 이내만 광고 설정 end 
      if(currentYear === yearDate && idx+1 === currentMonth){
        setInfo({infoActive: true, infoText:"당월 광고는 광고가 시작되는 일을 기준으로 일할 계산하여 결제된 금액의 일부가 부분취소됩니다."})
        tempArr[idx]['thisMonth'] = true 
      } 
      tempArr[idx]['selected'] = true 
      setAdMonth(adMonth+1)
      setTotalPrice(totalPrice+tempArr[idx]['price'])
    }else{
      console.log('unselect')
      tempArr[idx]['selected'] = false
      if(currentYear === yearDate && idx+1 === currentMonth){
        setInfo({infoActive: false, infoText:null})
      }
      setAdMonth(adMonth-1)
      setTotalPrice(totalPrice-tempArr[idx]['price'])
    }

    tempDict[yearDate] = tempArr    
    setMonthDict(tempDict)
  }

  const prevYear = () =>{
    if (yearDate > currentYear){
      setTempYear(yearDate-1)
      setYearDate(yearDate-1)
    }
  }

  const nextYear = async () =>{
    let tempMonthDict = monthDict; 
    let nextYear = yearDate+1 

    if (nextYear in tempMonthDict){
      setYearDate(yearDate+1)
    }else{
      const year = _.cloneDeep(yearDate)+1
      await getPeriod(year) 
    }
  }
  
  const reservation = async() => {

    if (userLogged === false){
      alert("로그인이 필요한 서비스입니다.")
      history.push('/service/login', {})
      return false 
    }

    const allKeys = Object.keys(monthDict) 
    const paramsList = []
    let totalPrice = 0

    allKeys.forEach(key =>{
      monthDict[key].forEach(element=>{
        if(element['selected'] === true){
          let tempDict = {'year':element['year'], 'month':element['month'], 'price':element['price'], 'thisMonth':element['thisMonth']} 
          paramsList.push(tempDict)
          totalPrice += element['price']
        }
      })
    })

    if(paramsList.length === 0){
      alert("광고 기간을 설정해주세요.")
      return false
    }

    if(Object.keys(content).length === 0){
      alert("콘텐츠를 업로드해주세요.")
      return false 
    }

    const adName = adNameRef.current.value 
    if (adName === '' || adName === null || adName === undefined){
      alert("광고명을 입력해주세요")
      return false 
    }

    const ctnPK = await CtnDataInsert(ctnb64)
    const data = {params:paramsList, totalPrice:totalPrice, shopPK:pk, content: content, shopData:shopData, adName:adName, ctnPK:ctnPK}    
    localStorage.setItem('tempData', JSON.stringify(data))
    history.push(`/service/payment/ad/`, {data: data})
    window.location.reload(true)
  }

  const shopLikeFunc = async() => {
    const isLogged = UserConfig.isLogged() 
    if (isLogged === false){
      const confirm = window.confirm('로그인이 필요한 서비스입니다.\n로그인 페이지로 이동하시겠습니까?')
      if (confirm === true){
        history.push('/service/login/')
      }else{
        return false 
      }
    }else{
      const reqURI = `${HOST_ADDR}app/v1/shops/like/${pk}`
      const userToken = UserConfig.getAccKey() 
      const headers = { headers : {Authorization:`Bearer ${userToken}`}}
      if(shopLike === true){
        try{
          const res = await axios.delete(reqURI, headers)
          const resData = res.data 
          await getShopLike()
        }catch(e){
          if (e.response){
            const status = e.response.status 
            const data = e.response.data 
            alert(data.msg)
          }else if (e.request){
            alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
          }
        }
      }else{
        try{
          const res = await axios.post(reqURI, {}, headers)
          const resData = res.data 
          await getShopLike()
        }catch(e){
          if (e.response){
            const status = e.response.status 
            const data = e.response.data 
            alert(data.msg)
          }else if (e.request){
            alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
          }
        }
      }
    }
  }

  const loadingAni = {
    loop: true,
    autoplay: true, 
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  
  return(
    (isLoading === true ? null : (
      <>
        <div className={isLoading === true ? "absolute w-full h-screen bg-black opacity-80 z-20 flex flex-col justify-center" : 'hidden'}>
          <Lottie options={loadingAni} width={400} height={400}/>
          <p className="self-center text-2xl text-center text-white font-nexonBold">데이터 로딩 중...</p>
        </div>

        {/* Header */}
        <div className="container px-24 py-12 mx-auto">
          <div className="flex flex-col flex-grow">
            <ServiceHeader props={props}/> 
          </div> 
        </div> 
        <div className="h-1 border-t-2 border-t-gray-400"/> 
        
        {/* Page Subject */}
        <div className="container flex flex-row justify-between flex-grow px-24 pt-12 mx-auto">
          <div className="flex flex-col justify-center flex-grow w-3/4">
            <div className="flex flex-row justify-center py-1 mb-2 rounded-lg bg-rose-500" style={{width: '10%'}}>
              <p className="self-center text-xl text-center text-white font-nexon">{shopData.adType}</p>
            </div>
            <p className="text-3xl font-nexonBold self-start text-left mb-0.5">{shopData.adName}</p>
            <p className="self-start text-xl text-left font-nexon">운영 매장: {shopData.shopName}</p>
          </div>
          <div className="flex flex-col justify-center flex-grow w-1/4 gap-y-2">
            {shopLike === true ? ( 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="self-end w-12 h-12 font-normal cursor-pointer text-rose-500" onClick={() => shopLikeFunc()}>
                <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="self-end w-12 h-12 font-normal text-black cursor-pointer" onClick={() => shopLikeFunc()}>>
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
              </svg>  
            )}
          </div>
        </div>

        {/* Image Part */}
        <div className="container flex flex-col justify-start flex-grow px-24 pt-8 mx-auto">
          <div className="flex flex-row justify-between h-108 gap-x-2">
            {/* Main image */}
            <div className="flex flex-row justify-center w-2/4">
              <img className="w-full h-full rounded-tl-lg rounded-bl-lg" loading="lazy" src={`${HOST_ADDR.slice(0, -1)}${shopData.shopThumb}`}/> 
            </div>

            <div className="grid w-2/4 grid-cols-2 gap-4">
              {shopData.shopImages.map((data, idx) => (
                <img key={idx} loading="lazy" className={ idx === 1 ? "w-full h-full rounded-tr-lg" : idx === 3 ? "w-full h-full rounded-br-lg" : idx >= 4 ? 'hidden' : "w-full h-full" } src={`${HOST_ADDR.slice(0, -1)}${data}`}/>
              ))}
            </div>
          </div>
        </div>

        <div className="container flex flex-row justify-between flex-grow min-h-screen px-24 pt-8 mx-auto gap-x-12 h-max">
          <div className="flex flex-col flex-grow" style={{width: '60%'}}>
            <p className="self-start pb-6 text-3xl font-nexonBold">매장 정보</p>

            <div className="flex flex-row self-start justify-center px-2 py-1 mb-2 bg-indigo-500 rounded-lg">
              <p className="self-center text-base text-white font-nexon">커피 전문점</p>
            </div>  
            <p className="self-start text-xl font-bold font-nexon">{shopData.shopName}</p>
            <p className="self-start pt-1 text-lg font-bold font-nexon">주소: {shopData.shopAddr}</p>
            <p className="self-start pt-1 text-lg font-bold font-nexon">매장 운영시간: {shopData.startTime} ~ {shopData.endTime}</p>
            <p className="self-start pt-1 text-lg font-bold text-black font-nexon">{shopData.holiList === null || shopData.holiList === '' ? "휴무일: 없음" : `휴무일: 매주 ${shopData.holiList}`}</p>
            
            {
              shopData.adBlockList === null ? null : (
                <>
                  <p className="self-start pt-1 text-lg font-nexonBold text-rose-500">광고 불가 분야</p>
                  <div className="flex flex-row justify-start mt-2 gap-x-2">
                  {shopData.adBlockList.split(',').map((data, idx) => (
                    <div className="flex flex-row rounded-xl bg-rose-500 px-2 py-0.5 justify-center" key={idx}>
                      <p className="self-center pt-1 text-base font-bold text-white font-nexon">{data}</p>
                    </div>            
                  ))}
                  </div> 
                </>
              )
            }

            {/* Divide Line */}
            <div className="flex flex-row h-1 my-6 border-b-2 border-b-gray-500"/> 

            {/* 방문 고객 유형 정보 */}
            <p className="self-start text-2xl font-nexonBold">방문 고객 정보</p>
            <p className="self-start pb-6 text-base font-bold text-gray-500 font-nexon">매장에서 제공받은 데이터입니다.</p>

            <p className="self-start text-xl font-bold font-nexon">월 평균 방문 고객: {shopData.customerCount}</p>

            <div className="flex flex-row justify-between mt-4">
              <p className="self-center text-xl font-bold font-nexon">주요 방문 고객</p>

              <div className="flex flex-col justify-center">
                <div className="flex flex-row items-center self-center mb-2 gap-x-4 justify-items-center">
                  {shopData.customerType !== null ? (
                    (shopData.customerType.map((data, idx) => (
                      <div className="flex flex-row rounded-full bg-rose-500 py-1.5 px-3" key={idx}>
                        <p className="self-center text-base font-bold text-white font-nexon">{data}</p>
                      </div>
                    )))) : null 
                  }
                </div> 
              </div>
            </div>

            <div className="flex flex-row justify-between mt-8">
              <p className="self-center text-xl font-bold font-nexon" style={{width: '48%'}}>나이별 방문자 비율</p>

              <div className="flex flex-col justify-center gap-y-2" style={{width:'52%'}}>
                <div className="flex flex-row justify-start">
                  <div className="flex flex-row justify-start" style={{width: '20%'}}>
                    <p className="self-center text-lg font-bold text-black font-nexon">학생</p>
                  </div>

                  <div className="flex flex-row justify-start" style={{width: '80%'}}>
                    <div className="flex flex-row self-center h-2 mr-2 bg-gray-400 rounded-full" style={{width: shopData.student*10+'%'}}></div>
                    <p className="self-center text-lg font-bold text-black font-nexon">{shopData.student*10}%</p>
                  </div>

                </div>
                <div className="flex flex-row justify-start">
                  <div className="flex flex-row justify-start" style={{width: '20%'}}>
                    <p className="self-center text-lg font-bold text-black font-nexon">직장인</p>
                  </div>

                  <div className="flex flex-row justify-start" style={{width: '80%'}}>
                    <div className="flex flex-row self-center h-2 mr-2 rounded-full bg-rose-500" style={{width: shopData.worker*10+'%'}}></div>
                    <p className="self-center text-lg font-bold text-red-500 font-nexon">{shopData.worker*10}%</p>
                  </div>

                </div>
                <div className="flex flex-row justify-start">
                  <div className="flex flex-row justify-start" style={{width: '20%'}}>
                    <p className="self-center text-lg font-bold text-black font-nexon">어르신</p>
                  </div>
                  <div className="flex flex-row justify-start" style={{width: '80%'}}>
                    <div className="flex flex-row self-center h-2 mr-2 bg-gray-400 rounded-full" style={{width: shopData.elder*10+'%'}}></div>
                    <p className="self-center text-lg font-bold text-black font-nexon">{shopData.elder*10}%</p>
                  </div>
                </div>
              </div>
            </div>

            {/* 성비  */}
            <div className="flex flex-row justify-between mt-8">
              <p className="self-center text-xl font-bold font-nexon" style={{width: '46%'}}>방문 고객 남녀 비율</p>
              <div className="flex flex-col justify-center gap-y-2" style={{width:'54%'}}>
                <div className="flex flex-row justify-start flex-grow">
                  <div className="flex flex-col justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" className="w-16 h-16 font-bold text-indigo-700">
                      <path d="M208 208v264a23.73 23.73 0 0024 24h0a23.73 23.73 0 0024-24" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <path d="M256 336v136a23.73 23.73 0 0024 24h0a23.73 23.73 0 0024-24V208" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <path d="M208 192v88a23.72 23.72 0 01-24 24h0a23.72 23.72 0 01-24-24v-88a48 48 0 0148-48h96a48 48 0 0148 48v88a23.72 23.72 0 01-24 24h0a23.72 23.72 0 01-24-24v-88" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <circle cx="256" cy="56" r="40" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                    </svg>
                    <p className="self-center pl-2 text-lg font-bold text-center text-indigo-700 font-nexonBold">{shopData.male*10}%</p>
                  </div>
                  <div className="flex flex-row self-center h-3 mb-4 bg-indigo-700 rounded-tl-full rounded-bl-full" style={{width: shopData.male*10+'%'}}/> 
                  <div className="flex flex-row self-center h-3 mb-4 bg-red-700 rounded-tr-full rounded-br-full" style={{width: shopData.female*10+'%'}}/> 

                  <div className="flex flex-col justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" className="w-16 h-16 font-bold text-red-700">
                      <path d="M208 368v104a23.73 23.73 0 0024 24h0a23.73 23.73 0 0024-24" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <path d="M256 368v104a23.73 23.73 0 0024 24h0a23.73 23.73 0 0024-24V368M183 274a23.73 23.73 0 01-29.84 16.18h0a23.72 23.72 0 01-16.17-29.84l25-84.28A44.85 44.85 0 01205 144h102a44.85 44.85 0 0143 32.08l25 84.28a23.72 23.72 0 01-16.17 29.84h0a23.73 23.73 0 01-29.78-16.2" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <circle cx="256" cy="56" r="40" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32"/>
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M208 192l-48 160h192l-48-160"/>
                    </svg>
                    <p className="self-center pl-2 text-lg font-bold text-center text-red-700 font-nexonBold">{shopData.female*10}%</p>
                  </div>
                </div>
              </div>
            </div> 

            {/* Divide Line */}
            <div className="flex flex-row h-1 my-6 border-b-2 border-b-gray-500"/> 
          
            {/* 매장 위치 */}
            <p className="self-start text-2xl font-nexonBold">매장 위치</p>

            <div className="flex flex-col flex-grow w-full mt-4 rounded-lg h-108">
              {/* <RenderAfterNavermapsLoaded ncpClientId="4iotvwzl22" error={<p>error</p>} loading={<p>loading</p>}> */}
              <NaverMap style={{width: '100%', height:'100vh'}} mapDivId={"react-naver-map"} defaultCenter={{ lat: shopData.latitude, lng: shopData.longitude }} defaultZoom={22}>
                <Marker style={{width:'100px', height:'100px'}} position={navermaps.LatLng(shopData.latitude, shopData.longitude)} size={navermaps.Size(50, 50)}/> 
              </NaverMap>
              {/* </RenderAfterNavermapsLoaded> */}
            </div>

            {/* Divide Line */}
            <div className="flex flex-row h-1 my-6 border-b-2 border-b-gray-500"/> 

            {/* 매체 정보 */}
            <p className="self-start pb-6 text-3xl font-nexonBold">매체 정보</p>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">스크린 크기</p>
              <p className="self-center text-lg font-nexon">32인치(80.1cm)</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">광고 타입</p>
              <p className="self-center text-lg font-nexon">{shopData.axis === 'vertical' ? "세로형 배너 광고" : "가로형 배너 광고"}</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">광고 콘텐츠 권장 해상도</p>
              <p className="self-center text-lg font-nexon">{`${shopData.adResWidth}x${shopData.adResHeight}`}px</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">재생 가능한 콘텐츠 타입</p>
              <p className="self-center text-lg font-nexon">이미지, 동영상</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">콘텐츠 재생시간</p>
              <p className="self-center text-lg font-nexon">15초</p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p className="self-center text-lg font-nexon">지원가능한 콘텐츠 포맷</p>
              <p className="self-center text-lg font-nexon">JPG, PNG, MP4</p>
            </div>

            <p className="self-start pt-12 pb-2 text-xl font-nexonBold">POP 스크린이란?</p>
            <p className="self-start pb-2 text-lg font-nexon">판매시점광고물(POP, Purchase of Point)을 표출하는 매체로서 고객의 결제 시점에 노출될 수 있도록 매장 카운터 근처에 배치되어 POP 콘텐츠와 함께 배너 광고를 송출합니다.</p>
            <p className="self-start text-lg font-nexon">결제 시점에 매장의 POP 콘텐츠와 함께 광고가 노출되는 만큼 매장 방문 고객분들에게 한번 이상은 노출되는 특징을 갖고 있습니다. </p>
            

            {/* Divide Line */}
            <div className="flex flex-row h-1 my-6 border-b-2 border-b-gray-500"/> 

            {/* Refund policy */}
            <p className="self-start pb-6 text-3xl font-nexonBold">환불 규정</p>
            <div className="flex flex-col flex-grow h-full">
              <div className="flex flex-row justify-start pb-2">
                <p className="self-start text-lg font-nexon">1. 광고 최종 승인 전:</p>
                <p className="self-start pl-2 text-lg font-nexonBold">전체금액 100% 환불</p>
              </div>
              <p className="self-start text-lg font-nexon">2. 최종 승인은 되었지만 아직 광고가 시작하지 않은 경우 아래 조건에 따라 환불이 가능합니다.<br/>(2개월 이상 장기 광고 포함)</p>
              <p className="text-lg font-nexon self-start pl-4 pt-2 pb-0.5">- 7일 전: 전체 금액의 100% 환불</p>
              <p className="text-lg font-nexon self-start pl-4 py-0.5">- 5일 전: 전체 금액의 80% 환불</p>
              <p className="text-lg font-nexon self-start pl-4 py-0.5">- 4일 전: 전체 금액의 40% 환불</p>
              <p className="text-lg font-nexon self-start pl-4 py-0.5">- 3일 전: 전체 금액의 30% 환불</p>
              <p className="text-lg font-nexon self-start pl-4 py-0.5">- 2일 전: 전체 금액의 20% 환불</p>
              <p className="text-lg font-nexon self-start pl-4 py-0.5">- 1일 전: 전체 금액의 10% 환불</p>
              <p className="self-start pt-2 text-lg font-nexon">3. 2개월 이상 장기 광고의 환불은 첫 1달 광고 시작 전까지만 환불이 가능하며 상세 규정은 위 2번의 규정을 따릅니다.</p>
              <p className="self-start pt-2 mb-12 text-lg font-nexon">4. 광고 콘텐츠의 법령 위반 등 광고주의 귀책으로 광고 집행이 중단될 경우 광고비는 반환되지 않습니다.</p>
            </div>
          </div>

          <div className="flex flex-col flex-grow" style={{width: '40%'}}>
            <div className="sticky top-12">

              <div className="flex flex-col p-6 border-2 shadow-lg rounded-xl">
                <p className="self-start text-xl text-black font-nexonBold">광고명</p>
                <input type='text' className="self-start w-full pl-1 mt-2 text-base text-left border-0 border-b-2 border-b-gray-900 font-nanum focus:outline-none focus:border-b-gray-900 focus:ring-0" placeholder="이 광고에 대해서 알려주세요(예시:스크린에어 플랫폼 광고)" ref={(ref) => adNameRef.current = ref} /> 
              </div>

              <div className="flex flex-col p-6 mt-6 border-2 shadow-lg rounded-xl">
                {Object.keys(content).length === 0 ? (
                  <div className="flex flex-col justify-start">
                    <div className="flex flex-row justify-between">
                      <p className="self-start text-base text-black font-nexon">{`광고 콘텐츠를 업로드해주세요.`}</p>
                      <p className="self-start text-base text-black font-nexon">{`권장 해상도:${shopData.adResWidth}x${shopData.adResHeight}px`}</p>
                    </div> 
                    <div className="flex flex-row justify-start hidden pt-2 cursor-pointer gap-x-2" onClick={() => history.push('/service/ctnTemplate/Surface/')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-6 h-6 text-rose-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>
                      <p className="text-base self-center pt-0.5 font-nexon text-rose-500">광고 콘텐츠가 없으신가요?</p>
                    </div> 
                  </div> 
                ) : (
                  <>
                    <div className="flex flex-row justify-between my-1">
                      <p className="self-center text-lg text-black font-nexon"> 콘텐츠</p>
                      <p className="self-center text-lg text-black underline cursor-pointer font-nexon" onClick={() => contentView()}> {content.contentName}</p>
                    </div>
                    <div className="flex flex-row justify-between my-1">
                      <p className="self-center text-lg text-black font-nexon"> 해상도</p>
                      <div className="flex flex-col justify-start">
                        {/* <p className="self-end text-lg text-black font-nexon">{`${content.width}x${content.height}px`}</p> */}
                        <p className={'text-lg text-black font-nexon self-end'}>{`${content.width}x${content.height}px`}</p>
                        <p className="self-end text-sm font-nexonBold text-rose-500">{resCheck === true ? '' : "해상도 불일치"}</p>
                      </div>
                    </div>
                    {content.type === 'video' ? ( 
                      <div className="flex flex-row justify-between my-1">
                        <p className="self-center text-lg text-black font-nexon">콘텐츠 재생시간</p>
                        <div className="flex flex-col justify-start">
                          <p className="self-end text-lg text-black font-nexon">{`${parseInt(content.duration)}초`}</p>
                          <p className={ durCheck === true ? "hidden" : "text-sm font-nexonBold text-rose-500 self-end"}>콘텐츠 길이 초과(최대 15초)</p>
                        </div>
                      </div>
                    ) : null}
                    <div className="flex flex-row justify-between my-1">
                      <p className="self-center text-lg text-black font-nexon"> 콘텐츠 타입</p>
                      <p className="self-center text-lg text-black font-nexon"> {content.type === 'image' ? "이미지" : "비디오"}</p>
                    </div>

                    <div className={resCheck !== true || durCheck !== true ? "h-1 border-t-2 my-1 border-black" : 'hidden'}/> 

                    <div className={resCheck === true ? 'hidden' : "flex flex-row justify-between my-1"}>
                      <p className="self-center text-lg text-black font-nexon">권장 해상도</p>
                      <p className="self-center text-lg text-black font-nexon">{`${shopData.adResWidth}x${shopData.adResHeight}px`}</p>
                    </div>

                    <div className={durCheck === true ? 'hidden' : "flex flex-row justify-between my-1"}>
                      <p className="self-center text-lg text-black font-nexon">비디오 콘텐츠 최대 재생시간</p>
                      <p className="self-center text-lg text-black font-nexon">15초</p>
                    </div>

                    <div className="flex flex-row justify-center mt-2">
                      <p className={resCheck === true && durCheck === true ? 'hidden' : "text-lg font-nexonBold text-rose-500 self-center"}>
                        {resCheck === false && durCheck === false ? "권장 해상도와 최대 재생시간에 맞는 콘텐츠를 업로드해주세요." : 
                        resCheck === false && durCheck === true ? "권장 해상도에 맞는 콘텐츠를 업로드해주세요." : 
                        resCheck === true && durCheck === false ? "업로드할 수 있는 비디오 콘텐츠의 최대 재생시간은 15초입니다." : null }
                      </p>
                    </div>
                  </>
                )}
                
                {/* marking */}
                <div className="flex flex-row justify-center py-4 mt-6 rounded-lg cursor-pointer bg-rose-500" onClick={() => upload()}>
                  {/* marking  */}
                  <p className="self-center text-lg text-white font-nexonBold">{ctnProgress === true ? "콘텐츠 변환 중..." : "광고 콘텐츠 업로드"}</p>
                  <svg className={ctnProgress === true ? "animate-spin h-5 w-5 text-white self-center ml-2" : 'hidden'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <input type='file' className="hidden" ref={(ref) => adContentInp.current = ref} accept=".png, .jpg, .mp4" onChange={(e) => uploaded(e)} /> 
                </div>
              </div>

              <div className="flex flex-col p-6 mt-8 transition-all duration-200 ease-in-out border-2 shadow-lg rounded-xl">
                <p className="self-center pb-4 text-lg text-black font-nanumBold">광고 기간 설정</p>
                <div className="flex flex-row justify-center gap-x-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="self-center w-8 h-8 font-bold text-black cursor-pointer" onClick={() => prevYear()}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                  </svg>
                  <p className="self-center text-xl text-center font-nexonBold">{yearDate}년</p>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="self-center w-8 h-8 font-bold text-black cursor-pointer" onClick={() => nextYear()}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
                <div className="grid grid-cols-3 gap-4 mt-4">
                  {monthDict[yearDate].map((data, idx) => (
                    <div className="relative flex flex-col justify-start border-2 border-gray-300 rounded-lg" key={idx} onClick={data.prev !== true && data.selected !== true ? () => selectMonth(idx, yearDate, 'select') : null}>
                      <p className="self-center pt-2 text-base text-center text-black font-nexonBold">{data.month}월</p>
                      <p className="self-center text-base text-center font-nexonBold text-rose-500">{data.price.toLocaleString()}원</p>
                      <p className="self-center pb-2 text-base text-center text-gray-700 font-nexonBold">{data.remain === null ? "매진" : `${data.remain}구좌 남음`}</p>

                      {data.prev === true ? (
                        <div className="absolute z-20 flex flex-col justify-center w-full h-full bg-gray-800 rounded-lg opacity-90">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="self-center w-8 h-8 mb-1 font-bold text-center text-white">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 00-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 01-.189-.866c0-.298.059-.605.189-.866zm-4.34 7.964a.75.75 0 01-1.061-1.06 5.236 5.236 0 013.73-1.538 5.236 5.236 0 013.695 1.538.75.75 0 11-1.061 1.06 3.736 3.736 0 00-2.639-1.098 3.736 3.736 0 00-2.664 1.098z" clipRule="evenodd" />
                          </svg>
                          <p className="self-center text-lg font-semibold text-center text-white">기간 만료</p> 
                        </div> 
                      ) : null }

                      {/* Checking  */}
                      <div className={data.selected === true ? 'absolute z-20 bg-indigo-500 opacity-90 flex flex-col w-full h-full justify-center rounded-lg' : 'hidden'} onClick={() => selectMonth(idx, yearDate, 'unselect')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="self-center w-10 h-10 font-bold text-center text-white" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </div>  
                    </div>                    
                  ))}
                </div>

                {info.infoActive === true ? (
                  <p className="self-start text-lg font-nanumBold text-rose-500">{info.infoText}</p>
                ) : null }
              </div>

              <div className="flex flex-col p-6 mt-8 mb-8 transition-all duration-200 ease-in-out border-2 shadow-lg rounded-xl">
                <div className="flex flex-row justify-between">
                  <p className="self-center text-lg text-black font-nexonBold">₩{shopData.adPrice.toLocaleString()}원 /1개월</p>
                  <p className="self-center text-base text-gray-500 font-nexon">{shopData.remainAccount}개의 구좌 남음</p>
                </div>
                <div className="flex flex-row justify-between mt-4">
                  <p className="self-start text-lg text-black font-nexon">광고 콘텐츠</p>
                  <p className={Object.keys(content).length === 0 ? "text-lg font-nexon text-red-500 self-start" : "text-lg font-nexonBold text-indigo-500 self-start"}>
                    {Object.keys(content).length === 0 ? "업로드 대기 중" : "업로드 완료"}
                  </p>
                </div>
                <div className="flex flex-row justify-between mt-2">
                  <p className="self-start text-lg text-black font-nexon">광고 기간</p>
                  <p className={adMonth === 0 ? "text-lg font-nexon text-red-500 self-start" : "text-lg font-nexon text-black self-start"}> {adMonth === 0 ? '미설정' : `${adMonth}개월`}</p>
                </div>
                <div className="flex flex-row justify-between mt-2">
                  <p className="self-start text-lg text-black font-nexon">1회당 송출 시간</p>
                  <p className="self-start text-lg text-black font-nexon">15초</p>
                </div>
                <div className="flex flex-row justify-between mt-2">
                  <p className="self-start text-lg text-black font-nexon">1일 송출 보장 횟수</p>
                  <p className="self-start text-lg text-black font-nexon">120회</p>
                </div>

                {adMonth === 0 ? null : (
                  <>
                    <div className="flex flex-row h-1 my-6 border-b-2 border-b-gray-500"/> 

                    <div className="flex flex-row justify-between">
                      <p className="self-center text-lg text-black font-nexon">{`${shopData.adPrice.toLocaleString()}원x${adMonth}개월`}</p>
                      <p className="self-center text-lg text-black font-nexon">{(shopData.adPrice*adMonth).toLocaleString()}원</p>
                    </div>

                    <div className="flex flex-row justify-between mt-2">
                      <p className="self-center text-lg text-black font-nexon">총 결제 금액</p>
                      <p className="self-center text-lg text-black font-nexonBold">{(shopData.adPrice*adMonth).toLocaleString()}원</p>
                    </div>
                    <div className={ Object.keys(content).length === 0 || adMonth === 0 ? "flex flex-row rounded-lg bg-gray-500 justify-center py-4 mt-4 cursor-pointer" : "flex flex-row rounded-lg bg-rose-500 justify-center py-4 mt-4 cursor-pointer"}
                        onClick={Object.keys(content).length === 0 || adMonth === 0 ? null : () => reservation()}>
                      <p className="self-center text-xl text-white font-nexonBold">예약하기</p>
                    </div>
                  </>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col self-end justify-end flex-grow">
          <Footer props={props}/> 
        </div>
      </> 
    ))
  )
}