import React from "react"

export class ApiKeyRepo extends React.Component{
  constructor(props){

  }

  // 사업자 등록 진위여부 공공 API Key 
  static businessAPIKey = {
    encodedKey : '6gyu%2BGtu5QF6%2Bu4gLHM%2BfSSA5ip%2Bs8kmpXhV3rsHAqTg08gQdShkJKV4FcalapvOwIAtFYOsWLDnNOmAyunCpQ%3D%3D',
    decodeKey : '6gyu+Gtu5QF6+u4gLHM+fSSA5ip+s8kmpXhV3rsHAqTg08gQdShkJKV4FcalapvOwIAtFYOsWLDnNOmAyunCpQ=='
  }

  static getBizEncKey(){
    return this.businessAPIKey['encodedKey']
  }

  static getBizDncKey(){
    return this.businessAPIKey['decodeKey']
  }

}