
/* This example requires Tailwind CSS v2.0+ */
import React, { createRef, useRef } from 'react'
import { Link } from 'react-router-dom'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { ChipIcon, CogIcon, PlayIcon, CashIcon } from '@heroicons/react/outline'
import Sample1 from '../../../../static/img/sample01.jpg' 
import Sample2 from '../../../../static/img/sample02.jpg'
import SampleImages from 'static/img/images'
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts' 
import { ContentRepository } from 'pages/contentRepository/container/ctnMain'
import axios from 'axios'
import _ from 'lodash'
import configData from 'pages/reactConfig'
import {HOST_ADDR, SERVER_URL} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: '스크린에어 광고 수익',
        data: [0, 0, 0, 0, 0, 0, 0]
      }],
      options: {
        chart: {
          toolbar : {
            show: false, 
          }, 
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2021-11-23", "2021-11-24", "2021-11-25", "2021-11-26", "2021-11-27", "2021-11-28", "2021-11-29"]
        },
        tooltip: {
          x: {
            format: 'yyyy-MM-dd'
          },
        },
      },
    };
  }
  render() {
    return (
      <div className="flex flex-col mt-5 relative bg-white pt-5 px-4 shadow rounded-lg h-1/12 max-h-full">
        <h3 className="text-xl leading-6 font-medium text-gray-900 antialiased">이달의 광고 수익</h3>
        <ReactApexChart 
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={250}
          className='w-full mb-0 mt-0'/>
      </div>
    )
  }
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export class AdStat extends React.Component{
  constructor(props) {
    super(props);
    this.adImgRef = React.createRef(); 
    this.ctnImgref = React.createRef(); 
    this.unmounted = false; 
    this.state = { 
      ad : [], 
      ctn : []
    }
    this.serverAddr = configData['SERVER_URL']
  }

  async autoControl(){
    let adcurrent = 0; 
    let ctncurrent = 0; 
    let adlen = this.state.ad.length; 
    let ctnlen = this.state.ctn.length; 

    while(true){
      if(this.unmounted === true){
        break
      }else{
        // Ad part 
        if (adlen !== 0){
          for(let i = 0; i < adlen; i++){
            this.state.ad[i].ref.current.className='text-base font-medium text-gray-400 truncate mb-2 subpixel-antialiased'; 
          }
          this.state.ad[adcurrent].ref.current.className='text-base font-medium text-gray-900 antialiased mb-2';
          this.adImgRef.current.src = this.state.ad[adcurrent].thumb;         

          // Ad part 
          adcurrent+=1 
          adcurrent === adlen ? adcurrent = 0 : adcurrent=adcurrent
        }

        if(ctnlen !== 0){
          // User Content part 
          for(let i = 0; i < ctnlen; i++){
            this.state.ctn[i].ref.current.className='text-base font-medium text-gray-400 truncate mb-2 subpixel-antialiased'; 
          }          
          this.state.ctn[ctncurrent].ref.current.className='text-base font-medium text-gray-900 antialiased mb-2';
          // NOTE: 콘텐츠 이미지 로테이션 파트 
          // this.ctnImgref.current.src = this.state.ctn[ctncurrent].thumb;           
          ctncurrent += 1 
          ctncurrent === ctnlen ? ctncurrent = 0 : ctncurrent=ctncurrent; 
        }
        await sleep(3000) 
      }
    }
  }

  getTodayCtn = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}schedule/v2/getTodayCtn/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      let todayCtn = resData.todayCtnList

      todayCtn.forEach((element, idx, object)=>{
        element['ref'] = React.createRef(null) 
        element['thumb'] = SERVER_URL + element['thumb']
        if (idx === 0){
          element['current'] = true 
        }else{
          element['current'] = false 
        }
      })
      
      this.setState({ctn:todayCtn}, ()=>{
        this.getTodayAd()
      })

    }catch(e){
      alert("예기치 못한 문제가 발생하였습니다.")
    }
  }

  getTodayAd = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}schedule/v2/getTodayAd/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      let todayCtn = resData.todayAdList
      todayCtn.forEach((element, idx, object)=>{
        element['ref'] = React.createRef(null) 
        element['thumb'] = SERVER_URL + element['thumb']
        if (idx === 0){
          element['current'] = true 
        }else{
          element['current'] = false 
        }
      })
      this.setState({ad:todayCtn}, ()=>{
        // this.autoControl(); 
      })

    }catch(e){
      alert("예기치 못한 문제가 발생하였습니다.")
    }
  }

  componentDidMount(){
    this.getTodayCtn()
    this.unmounted = false; 
  }

  componentWillUnmount(){
    console.log('unmount'); 
    this.unmounted = true; 
  }

  render(){
    return(
      <div>
        <dl className='mt-5 grid gap-5 md:grid-cols-2 grid-cols-1'>
          {/* 광고 썸네일 정보 */}
          <div className="relative bg-white pt-5 px-4 md:pb-12 pb-2 shadow rounded-lg overflow-hidden h-11/12">
            <h3 className="text-xl leading-6 font-medium text-gray-900">오늘의 광고 스케줄</h3>
            <div className='flex flex-row justify-between h-60'>
              {
                this.state.ad.length === 0 ? (
                  <div className='w-full h-full mt-4 flex flex-col justify-center'>
                    <div className='items-center self-center mt-4 text-gray-500 flex flex-col'>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className='text-xl font-extrabold mt-4 antialiased self-center text-gray-500'>오늘은 광고 일정이 없습니다.</span>
                    </div>
                  </div> 
                ) : (
                  <>
                    <div className="mt-4 overflow-y-auto w-full md:block hidden">
                      {
                        this.state.ad.map((ad) => (
                          <p 
                            key={ad.id}
                            ref={ad.ref}
                            className={"text-base font-medium hover:text-indigo-500 text-gray-900 antialiased mb-2 cursor-pointer"}>({ad.airbox}) {ad.name} / {ad.endDate} 까지</p>
                        ))
                      }
                    </div>
                    {/* <div className='rounded-lg md:w-7/12 w-full'>
                    {
                      this.state.ad.map((ad) =>(
                        ad.current === true ? (<img src={ad.thumb}
                          className='rounded-lg object-contain w-full h-60'
                          ref={this.adImgRef}/>
                          ) : ''
                      ))
                    }
                    </div> */}
                  </>
                )
              }
            </div>
          </div>
          {/* 광고 일정 정보 */}
          <div className="relative bg-white pt-5 px-4 md:pb-12 pb-2 shadow rounded-lg overflow-hidden h-11/12">
            <h3 className="text-xl leading-6 font-medium text-gray-900">오늘의 콘텐츠 스케줄</h3>
            <div className='flex flex-row justify-between h-60'>
              {
                this.state.ctn.length === 0 ? (
                  <div className='w-full h-full mt-4 flex flex-col justify-center'>
                    <div className='items-center self-center mt-4 text-gray-500 flex flex-col'>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className='text-xl font-extrabold mt-4 antialiased self-center text-gray-500'>오늘 예정된 콘텐츠 송출 일정이 없습니다.</span>
                    </div>
                  </div> 
                ) : (
                  <>
                    <div className="mt-4 overflow-y-auto w-full md:block hidden">
                    {
                      this.state.ctn.map((ctn) => (
                        <p 
                          key={ctn.id}
                          ref={ctn.ref}
                          className={"text-base font-medium text-gray-900 antialiased mb-2 hover:text-indigo-500 cursor-pointer"}>({ctn.airbox}) {ctn.name} / {ctn.endDate} 까지
                        </p>
                      ))
                    }
                    </div>
                    {/* <div className='rounded-lg md:w-7/12 w-full'>
                    {
                      this.state.ctn.map((ctn) =>(
                        ctn.current === true ? (<img src={ctn.thumb}
                          className='rounded-lg object-contain w-full h-60'
                          ref={this.ctnImgref}/>) : ''
                      ))
                    }
                    </div> */}
                  </>
                )
              }
            </div>
          </div>
        </dl>
      </div>
    )
  }
}

export class TopStat extends React.Component{
  constructor(props){
    super(props) 
    this.state = { 
      stat : [
        { id: 1, name: '전체 Airbox', stat: '0', unit: "대", icon: ChipIcon, change: 'null', changeType: 'null', href: '/cms/devManager' },
        { id: 2, name: '작동중인 Airbox', stat: '0', unit: "대", icon: CogIcon, change: '75%', changeType: 'null', href: '/cms/devManager' },
        { id: 3, name: '현재 진행중인 광고(업데이트 예정)', stat: '0', unit: "건", icon: PlayIcon, change: 'null', changeType: 'decrease', href: '/cms/adManager' },
        { id: 4, name: '오늘 수익(업데이트 예정)', stat: '0', unit: "원", icon: CashIcon, change: 'null', changeType: 'decrease', href: '/cms/statistic' },
      ], 
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    this.updateAirbox() 
  }

  updateAirbox = async () =>{
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/screeninfo/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      const screenCount = resData.screenCount
      const workingCount = resData.workingCount
      const stat = _.cloneDeep(this.state.stat)
      let change; 
      if (screenCount === 0){
	      change = '0 %' 
      }else{
      	change = ((workingCount / screenCount) * 100).toFixed(2) +'%'
      }
      stat[0]['stat'] = screenCount
      stat[1]['stat'] = workingCount
      stat[1]['change'] = change
      this.setState({stat:stat})
    }catch(e){
      alert("에어박스 데이터를 불러오는데 실패하였습니다.")
      return false 
    }

    // const {data:result} = await axios.get(this.serverAddr+'/devices/screeninfo/', 
    // {
    //   withCredentials: true, 
    //   headers:{
    //     'Content-Type' : 'application/json', 
    //     'Accept': 'application/json'
    //   }
    // }); 
    // if (result['result'] === 'OK'){
    //   const stat = _.cloneDeep(this.state.stat)
    //   const screenCount = result['screenCount']
    //   const workingCount = result['workingCount']
    //   let change; 
    //   if (screenCount === 0){
	  //     change = '0 %' 
    //   }else{
    //   	change = ((workingCount / screenCount) * 100).toFixed(2) +'%'
    //   }

    //   stat[0]['stat'] = screenCount
    //   stat[1]['stat'] = workingCount
    //   stat[1]['change'] = change
    //   this.setState({stat:stat})
    // }
  }

  render(){
    return(
      <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 lg:grid-cols-4 px-2 md:px-0">
      {/* <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"> */}
        {this.state.stat.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
              <div className="absolute bg-rose-500 rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p className="ml-1 text-xl font-semibold text-gray-900">{item.unit}</p>
              {item.change==='null' ? '' :
                <p
                  className={classNames(
                    item.changeType === 'increase' || item.changeType === 'null' ? 'text-green-600' : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold'
                  )}
                >
                  {item.changeType === 'null' ? '' : 
                    item.changeType === 'increase' ?
                    ( <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />)
                    : ( <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />)
                  }

                  <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                  {item.change}
                </p>
              }
              <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link
                    key={item.id}
                    to={item.href}
                    className='font-medium text-indigo-600 hover:text-indigo-500'>
                    {' '}자세히 보기
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
    )
  }
}
