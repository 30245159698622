
import { useHistory, useLocation } from 'react-router-dom'
import { CalendarIcon, ChartBarIcon, DesktopComputerIcon, HomeIcon, UsersIcon, PhotographIcon, SpeakerphoneIcon, DocumentReportIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import ScaIcon from 'static/img/logo/screenAirLogo.png'
import {GrAnnounce} from 'react-icons/gr'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

const secondaryNavigation = [
    // { name: 'Website redesign', href: '#' },
    // { name: 'GraphQL API', href: '#' },
    // { name: 'Customer migration guides', href: '#' },
    // { name: 'Profit sharing program', href: '#' },
]
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function nav(){
  const userInfo = UserConfig.getUserInfo() 
  const {userAuth} = userInfo
  const pathname = window.location.pathname; 
  let navigation
  if (userAuth === 'admin'){
    navigation = [
      { idx: 1, name: 'Dashboard', icon: HomeIcon, href: '/cms/dashboard', current: pathname==='/cms/dashboard' ? true : false },
      { idx: 2, name: '광고 관리', icon: UsersIcon, href: '/cms/adManager', current: pathname==='/cms/adManager' ? true : false },
      { idx: 3, name: '스크린 관리', icon: DesktopComputerIcon, href: '/cms/devManager', current: pathname==='/cms/devManager' ? true : false },
      { idx: 4, name: '스케줄 관리', icon: CalendarIcon, href: '/cms/schedule', current: pathname==='/cms/schedule' ? true : false },
      { idx: 5, name: '콘텐츠 보관함', icon: PhotographIcon, href: '/cms/contentRepo', current: pathname==='/cms/contentRepo' ? true : false },
      { idx: 6, name: '수익 통계', icon: ChartBarIcon, href: '/cms/statistic', current: pathname==='/cms/statistic' ? true : false },
      { idx: 7, name: '광고 송출', icon: SpeakerphoneIcon, href: '/cms/adReq', current: pathname==='/cms/adReq' ? true : false },
      { idx: 8, name: '송출보고서', icon: DocumentReportIcon, href: '/cms/adReport', current: pathname==='/cms/adReport' ? true : false },
      // { idx: 1, name: '광고 모집', icon: GrAnnounce, href: '/cms/statistic', current: pathname==='/cms/exposure' ? true : false },
    ];
  }else{
    navigation = [
      { idx: 1, name: 'Dashboard', icon: HomeIcon, href: '/cms/dashboard', current: pathname==='/cms' ? true : false },
      { idx: 2, name: '스크린 관리', icon: DesktopComputerIcon, href: '/cms/devManager', current: pathname==='/cms/devManager' ? true : false },
      { idx: 3, name: '스케줄 관리', icon: CalendarIcon, href: '/cms/schedule', current: pathname==='/cms/schedule' ? true : false },
      { idx: 4, name: '콘텐츠 보관함', icon: PhotographIcon, href: '/cms/contentRepo', current: pathname==='/cms/contentRepo' ? true : false },
    ];
  }
  return navigation; 
}

  
export default function sideDash(props) {    
    const key = sessionStorage.getItem('key'); 
    let navigation = nav(); 

    return (
      <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto h-screen">
        <div className="flex items-center flex-shrink-0 px-4">
          <img
            className='h-8 w-auto'
            src={ScaIcon}
            alt="ScreenAir"
          />
          <h2 className="ml-2 text-2xl font-extrabold text-gray-900">ScreenAir</h2>
        </div>

        <div className="mt-5 flex-grow flex flex-col h-screen overflow-hidden">
          <nav className="flex flex-col justify-between items-stretch px-2 space-y-8 bg-white h-screen" aria-label="Sidebar">
          {/* <nav className="flex-1 px-2 space-y-8 bg-white" aria-label="Sidebar"> */}
            <div className='flex flex-col justify-center'>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  params={key}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )
                  }
                    aria-hidden="true"
                    // color="red"
                    // size="24"
                  />
                  {item.name}
                  {item.idx == 7 ? (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 ml-2 self-center">
                      Beta
                    </span>
                  ) : "" }
                </Link>
              ))}
            </div>

            <div className="space-y-1">
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                {/* Projects */}
              </h3>
              <div className="space-y-1" role="group" aria-labelledby="projects-headline">
                {secondaryNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }