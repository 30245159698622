import React, { Fragment, useState } from 'react'
import ReactDOM from 'react-dom'
import { Menu, Popover, Transition, Dialog } from '@headlessui/react'
import {
  AcademicCapIcon,
  BadgeCheckIcon,
  BellIcon,
  CashIcon,
  ClockIcon,
  MenuIcon,
  ReceiptRefundIcon,
  UsersIcon,
  XIcon,
  CogIcon,
  EmojiHappyIcon, 
  EmojiSadIcon, 
  TrashIcon, 
  DesktopComputerIcon,
  PencilIcon,
  ExclamationIcon, 
  DocumentReportIcon,
} from '@heroicons/react/outline'
import '@themesberg/flowbite'
import { SearchIcon } from '@heroicons/react/solid'
import { createPopper } from '@popperjs/core'
import { useAsync } from 'react-async';
import _ from 'lodash'
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { isThisISOWeek } from 'date-fns'
import Notification from 'pages/util/notification'
import configData from 'pages/reactConfig'
import OperationTimeModal from './operTimeModal'
import ReportModal from './reportModal'

import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

const user = {
  
  name: sessionStorage.getItem('username'),
  email: 'chelseahagon@example.com',
  role: sessionStorage.getItem('email'), 
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Home', href: '#', current: true },
  { name: 'Profile', href: '#', current: false },
  { name: 'Resources', href: '#', current: false },
  { name: 'Company Directory', href: '#', current: false },
  { name: 'Openings', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]
const stats = [
  { label: 'Vacation days left', value: 12 },
  { label: 'Sick days left', value: 4 },
  { label: 'Personal days left', value: 2 },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


class ScreenReg extends React.Component{
  constructor(props){
    super(props); 
    this.state = { 
      open : false,
      codeIndexArr : null,
      carouselRef : React.createRef(null), 
      regDescription : React.createRef(null), 
      screenName : React.createRef(null), 
      userAuth : null 
    }
    this.codeIndexArr = null 
    this.cancelButtonRef = React.createRef(null) 
    this.regButtonRef = React.createRef(null) 
    this.progressSVG = React.createRef(null)
    this.codeIndexGeneration = this.codeIndexGeneration.bind(this) 
    this.deviceReg = this.deviceReg.bind(this) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    const userInfo = UserConfig.getUserInfo() 
    const {userAuth} = userInfo
    this.setState({userAuth: userAuth})
    this.codeIndexGeneration() 
  }

  codeIndexGeneration = () => {
    let codeIndex = new Array(); 
    for (let i = 0; i < 6; i ++){
      let temp = {idx : i, ref: React.createRef(null)}
      codeIndex.push(temp); 
    }
    this.setState(() => ({codeIndexArr : codeIndex}), () => {

    })
  }

  // marking 
  // TODO: 에러 났을 경우 input tag shake하는 기능 추가 (search keyword : react input tag shake animation)
  codeInput = (e, idx) =>{ 
    // let which = e.which; 
    let data = e.nativeEvent.data; 
    const codeIndexArr = this.state.codeIndexArr;
    if (idx !== 5 && data !== null ){
      let nextRef = this.state.codeIndexArr[idx+1]['ref'] 
      nextRef.current.focus(); 
    }
    if (data === null && idx !== 0){
      let nextRef = this.state.codeIndexArr[idx-1]['ref'] 
      nextRef.current.focus(); 
    }
    if (idx === 5){
      const disableClass = ['bg-gray-600','hover:bg-gray-700','focus:ring-gray-500']
      const activateClass = ['bg-indigo-600','hover:bg-indigo-700','focus:ring-indigo-500']
      // Carousel 이동 
      this.state.carouselRef.current.moveTo(1); 

      // 등록 버튼 조절 
      for(let i = 0; i<disableClass.length; i++){
        this.regButtonRef.current.classList.remove(disableClass[i])
        this.regButtonRef.current.classList.add(activateClass[i])
      }
      this.regButtonRef.current.classList.remove('cursor-not-allowed')
      this.regButtonRef.current.disabled = false 
      this.regButtonRef.current.onclick = this.deviceReg
      
      // Description 변경 
      this.state.regDescription.current.innerHTML = "스크린을 식별할 수 있는 이름을 지어주세요 :)"
    }
  }

  setOpen = () =>{
    if (this.state.open === false){
      this.setState({open: true})
      // console.log(firstInput['ref'])
      // this.state.codeIndexArr[0]['ref'].current.focus(); 
    }else{
      this.setState({open: false})
    }
  }

  deviceReg = async () =>{
    let screenCode = ''; 
    this.progressSVG.current.classList.remove('hidden') 
    this.state.codeIndexArr.forEach(element=>{
      screenCode += element.ref.current.value 
    })
    const screenName = this.state.screenName.current.value; 
    const res = await this.regScreen(screenCode, screenName); 
    if (res['result'] === 'OK'){
      this.setState({open:false})
      this.props.screenUpdate(true, "디바이스 등록 알림", "디바이스가 성공적으로 등록되었습니다.");  
    }
  }

  regScreen = async(code, name) =>{
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/screenReg/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {code : code, name: name}
    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("제품 등록 과정에 오류가 발생하였습니다.")
    }
  }

  render(){
    return(      
      <>
      <button className={this.state.userAuth === 'admin' ? "flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2" : 'hidden'}
        onClick={this.setOpen}>
        스크린 등록
      </button>
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={this.cancelButtonRef} onClose={this.setOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="flex items-start flex-col w-full">
                    <div className='flex flex-row w-full'>
                      <div className="mx-auto flex flex-col items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10 self-center">
                        <DesktopComputerIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <div className="flex flex-col text-left ml-2">   
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        스크린 등록하기 
                      </Dialog.Title>
                      <span className='text-gray-400 text-xs font-medium' ref={this.state.regDescription}>스크린에 표출되고 있는 등록 코드 6자리를 입력해주세요.</span>
                      </div>
                    </div> 
                    
                    <div className="flex flex-row mt-2 ml-12">
                      <Carousel
                        showArrows={false}
                        showStatus={false}  
                        showIndicators={false}
                        showThumbs={false}
                        autoPlay={false}
                        autoFocus={false} 
                        preventMovementUntilSwipeScrollTolerance={true}
                        className='px-0'
                        ref={this.state.carouselRef}
                      >
                        <div className='grid md:grid-cols-6 grid-cols-3 gap-4 px-1 my-2'>
                          {
                            this.state.codeIndexArr !== null ? (
                              this.state.codeIndexArr.map((codeIndex) => (
                                <input type='text' className='w-12 rounded-lg border border-gray-200 focus:border-indigo-400 focus:ring-1 focus:ring-indigo-400 shadow px-4 py-2 text-center' 
                                  maxLength={1}
                                  key={codeIndex.idx}
                                  ref={codeIndex.ref}
                                  
                                  onChange={e => this.codeInput(e, codeIndex.idx)}
                                />
                              ))
                            ) : this.state.codeIndexArr
                          }
                        </div> 

                        <div className='flex flex-row pt-2'>
                          <input type='text' 
                            className='w-10/12 rounded-lg border text-left border-gray-200 focus:border-indigo-400 focus:ring-1 focus:ring-indigo-400 px-4 py-2'
                            placeholder="[서울] 유니크카페 홍대점 1번째 스크린"
                            ref={this.state.screenName}/>
                        </div> 
                      </Carousel>
                    </div>
                    <div className="mt-2 ml-8 pl-4 flex flex-row md:gap-x-0 gap-x-4">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full self-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-sm cursor-not-allowed"
                        ref={this.regButtonRef}
                        disabled>
                        <svg class="hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.progressSVG}>
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        등록
                      </button>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center self-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => this.setOpen(false)}
                        ref={this.cancelButtonRef}
                      >
                        취소
                      </button>
                    </div>
                  </div>

                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }
}

export default class DevManager extends React.Component{

  constructor(props){
    super(props); 
    this.state = {
      screens : null, 
      isLoading : true,
      username: null,
      usermail: null, 
      notiShow : false, 
      notiSubject : null, 
      notiText : null, 
      notiType : 'success', 
      modalOpen : false, 
      startTimeValue : new Date(), 
      currentCode : null, 
      userAuth: null 
    }
    this.serverAddr = configData['SERVER_URL']
    this.cancelBtnRef = React.createRef(null)
    this.operModalRef = React.createRef(null) 
    this.reportModalRef = React.createRef(null) 
  }

  componentDidMount(){
    const userInfo = UserConfig.getUserInfo() 
    const {userAuth} = userInfo
    this.setState({userAuth: userAuth})
    this.screenUpdate() 
  }

  setTimeValue = (newValue) => {
    this.setState({startTimeValue:newValue})
  }

  setCurrentCode = (code) => {
    this.setState({currentCode:code})
  }

  screenUpdate = async (notiShow=false, notiSubject=null, notiText=null, notiType='success') =>{
    this.setState({isLoading: true})
    const {data, isLoading, error} = await this.getScreenInfo(); 
    if (isLoading){
      console.log('isloading'); 
    }else if (error){
      console.log('screen Info loading error'); 
      // Need to alert and redirect function 
    }else if (data){
      console.log(data)
      data.forEach(element=>{
        element['edit'] = React.createRef(null); 
      });
      const userInfo = UserConfig.getUserInfo() 
      const {name, email} = userInfo
      this.setState({screens: data, isLoading: false, username:name, usermail:email, notiShow:notiShow, notiSubject:notiSubject, notiText:notiText})
    }
  }

  getScreenInfo = async() =>{
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/screen/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      return resData 
    }catch(e){
      alert("스크린 정보를 불러오는데 실패하였습니다.")
    }
  }

  nameEdit = (ref, code) =>{
    const prevValue = ref.current.defaultValue; 
    ref.current.disabled=false; 
    ref.current.readOnly = false; 
    ref.current.classList.remove('focus:outline-none'); 
    ref.current.classList.remove('cursor-default'); 
    ref.current.focus(); 
    ref.current.onkeyup = (e) => this.nameEditComplete(e, ref, prevValue, code) 
  }

  nameEditComplete = async (e, ref, prevValue, code) =>{
    if (e.key === 'Enter'){
      ref.current.readOnly = true; 
      ref.current.classList.add('focus:outline-none'); 
      ref.current.classList.add('cursor-default'); 
      ref.current.defaultValue = ref.current.value;
      let postValue = ref.current.defaultValue; 

      // TODO : noti 추가 
      const result = await this.nameEditSync(prevValue, postValue, code); 
      if (result['result'] === 'OK'){
        console.log('ok')
        this.screenUpdate() 
      }else{
        console.log('error') 
      }
    }
  }

  nameEditSync = async(prevValue, postValue, code) =>{

    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/${code}/modifyName/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {name : postValue}

    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      console.log(resData)
      return resData 
    }catch(e){
      console.log('name edit failed')
      console.log(e) 
    }
  }

  screenRemove = async () => {
    // TODO : noti 추가 
    const code = this.state.currentCode
    const result = await this.removeSync(code); 
    if (result['result'] === 'OK'){
      this.setState({modalOpen:false})
      this.screenUpdate() 
      window.location.reload()
    }else{
      console.log('error') 
    }
  }

  removeSync = async(code) =>{
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/${code}/delete/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.delete(reqURI, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("제품 삭제에 실패하였습니다.")
    }
  }

  reboot = async(idx) => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/powerControl/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = { command : 'reboot', idx:idx}

    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        alert("Airbox에 재부팅 명령을 전달하였습니다.")  
      }else{
        alert("Airbox에 재부팅 명령을 전달을 실패하였습니다.")   
      }
    }catch(e){
      console.log('reboot error')
      console.log(e) 
    }
  }

  poweroff = async(idx) => {

    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/powerControl/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {command : 'poweroff', idx:idx}

    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        alert("Airbox에 전원 종료 명령을 전달하였습니다.")
      }else{
        alert("Airbox에 전원 종료 명령 전달을 실패하였습니다.") 
      }
    }catch(e){
      console.log('poweroff error')
      console.log(e) 
    }
  }

  setOpen = (open) => {
    this.setState({modalOpen:open})
  }

  operTimeSet = (code, startTime, endTime) => {
    this.operModalRef.current.setOpen(code, startTime, endTime, this.operTimeCallBack)
  }

  reporting = (code, name) => { 
    this.reportModalRef.current.setOpen(code, name)
  }

  operTimeCallBack = () => {
    this.screenUpdate(true, "시스템 알림", "운영 시간이 정상적으로 변경되었습니다.", "success")
  }

  render(){
    const isLoading = this.state.isLoading; 
    return(
      <>
      {isLoading ? "" : 
        <div className="min-h-full mt-4">
          <OperationTimeModal ref={this.operModalRef}/>
          <ReportModal ref={this.reportModalRef}/> 
          <Notification show={this.state.notiShow} subject={this.state.notiSubject} text={this.state.notiText} type={this.state.notiType}/> 
          <Popover as="header" className="pb-24 bg-gradient-to-r rounded-lg from-rose-300 to-rose-500">
          {/* <Popover as="header" className="pb-24 bg-gradient-to-r rounded-lg from-sky-800 to-cyan-600"> */}
            {({ open }) => (
              <>
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                  <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
                    {/* Blank space for design */}
                    <div className="absolute left-0 py-10 flex-shrink-0 lg:static"></div>
                  </div>
                </div>

                <Transition.Root as={Fragment}>
                  <div className="lg:hidden">
                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-150 ease-in"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-150 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Popover.Panel
                        focus
                        className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                      >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                          <div className="pt-3 pb-2">
                            <div className="flex items-center justify-between px-4">
                              <div>
                                <img
                                  className="h-8 w-auto"
                                  src="https://tailwindui.com/img/logos/workflow-mark-cyan-600.svg"
                                  alt="Workflow"
                                />
                              </div>
                              <div className="-mr-2">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                                  <span className="sr-only">Close menu</span>
                                  <XIcon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                              </div>
                            </div>
                            <div className="mt-3 px-2 space-y-1">
                              {navigation.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                          <div className="pt-4 pb-2">
                            <div className="flex items-center px-5">
                              <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-rose-500">
                                  <span className="text-lg font-medium leading-none text-white">{this.state.username[1].toUpperCase()}</span>
                                </span>
                              </div>
                              <div className="ml-3 min-w-0 flex-1">
                                <div className="text-base font-medium text-gray-800 truncate">{this.state.username}</div>
                                <div className="text-sm font-medium text-gray-500 truncate">{this.state.usermaill}</div>
                              </div>
                              <button
                                type="button"
                                className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                              >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="mt-3 px-2 space-y-1">
                              {userNavigation.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition.Child>
                  </div>
                </Transition.Root>
              </>
            )}
          </Popover>

          <main className="-mt-24 pb-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl">
              <h1 className="sr-only">Profile</h1>
              <div className="grid grid-cols-1 gap-4 items-start mb-4">
                <section aria-labelledby="profile-overview-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">
                    <div className="bg-white p-6">
                        <div className="sm:flex sm:items-center sm:justify-between">
                          <div className="sm:flex sm:space-x-5">
                            <div className="flex flex-row">
                              <span className="inline-flex items-center justify-center mx-auto h-20 w-20 rounded-full bg-rose-500">
                                {this.state.username.length === 1 ? (
                                  <span className="text-5xl font-medium leading-none text-white">{this.state.username[0].toUpperCase()}</span>  
                                ) : null}
                                {this.state.username.length === 2 ? (
                                  <span className="text-4xl font-medium leading-none text-white">{this.state.username}</span>  
                                ) : null}
                                {this.state.username.length >= 3 ? (
                                  <span className="text-4xl font-medium leading-none text-white">{this.state.username[this.state.username.length-2].toUpperCase()}{this.state.username[this.state.username.length-1].toUpperCase()}</span>  
                                ) : null}
                              </span>
                            </div>
                            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-sm font-medium text-gray-600">환영합니다.</p>
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">{this.state.username}님</p>
                            <p className="text-sm font-medium text-gray-600">{this.state.usermail}</p>
                            </div>
                          </div>
                          <div className="mt-5 flex justify-center sm:mt-0">
                            {/* <button className="flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2">
                                스크린 등록
                            </button> */}
                            <ScreenReg screenUpdate={this.screenUpdate}/>
                            {/* <button className="flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-2">
                                제품 제거
                            </button> */}
                          </div>
                        </div>
                    </div>
                  </div>
                </section>
              </div>
              
              { this.state.screens === null || this.state.screens.length === 0 ? (
                <div className='flex flex-row w-full h-48 justify-center'>
                  <div className='items-center self-center mt-4 text-gray-500 flex flex-col'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className='text-xl font-extrabold mt-4 antialiased self-center text-gray-500'>등록된 스크린이 없습니다.</span>
                    <span className='text-xl font-extrabold antialiased self-center text-gray-500'>스크린을 등록해주세요.</span>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {/* screens panel */}
                  <section aria-labelledby="quick-links-title">
                    {/* <div className="rounded-lg overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px"> */}
                    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
                      <h2 className="sr-only" id="quick-links-title">
                        Quick links
                      </h2>
                      {/* {this.state.screens.map((screen) => (
                        <h3> {screen.screenName} </h3> 
                      ))} */}
                      {this.state.screens.map((screen, screenIdx) => (
                        <div
                          key={screen.model}
                          className={classNames(
                            screenIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                            screenIdx === 1 ? 'rounded-bl-none rounded-tr-none' : '',
                            screenIdx === 2 ? 'sm:rounded-tr-lg' : '',
                          //   screenIdx === screens.length - 2 ? 'sm:rounded-bl-lg' : '',
                          //   screenIdx === screens.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                            'relative group bg-white p-6'
                          )}
                        >
                          <div className='flex flex-row'>
                            <span className='text-purple-700 bg-purple-50 rounded-lg inline-flex p-3 ring-4 ring-white'>
                              <CogIcon className="h-6 w-6" aria-hidden="true" />
                            </span>
                            <div className='flex flex-row w-full justify-between'>
                              <div className='flex flex-col'>
                                <input className='items-center self-start ml-2 text-base font-bold antialiased focus:outline-none cursor-default bg-white' defaultValue={screen.screenName} ref={screen.edit} disabled={true}/>
                                {/* <span className='items-center self-start ml-2 text-base font-bold antialiased'>{screen.screenName}</span>  */}
                                <div className='flex flex-row ml-2 mt-1'>
                                  {/* TODO: 광고 모집 중에 대한 status 판별 값 필요 */}
                                  <p className={
                                      screen.network.status === null ? 'text-sm bg-red-700 rounded-md text-white font-medium px-2 w-auto' 
                                      : 'text-sm bg-indigo-700 rounded-md text-white font-medium px-2 w-auto' }>
                                    {screen.network.status === null ? "offline" : "online"}
                                  </p>
                                  <p className={classNames(screen.network.status === true ? 
                                    'text-sm bg-green-100 rounded-md text-green-800 font-medium px-2 w-auto' : 
                                    'text-sm bg-red-100 rounded-md text-red-800 font-medium px-2 w-auto',
                                    'ml-1'
                                    )}>
                                    {
                                      <>
                                      {screen.network.status === true ? "광고 모집 중" : "광고 모집 꺼짐"}
                                      </>
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='relative flex flex-row group self-center'> 
                                {/* marking */}
                                {this.state.userAuth === 'admin' ? (
                                  <>
                                    <DocumentReportIcon className='-ml-4 md:ml-0 w-5 h-5 text-sm text-green-500 self-center cursor-pointer mr-1' onClick={() => this.reporting(screen.screenCode, screen.screenName)}/>
                                    <PencilIcon className='w-5 h-5 text-sm text-indigo-500 self-center cursor-pointer mr-1' onClick={() => this.nameEdit(screen.edit, screen.screenCode)}/>
                                    <TrashIcon className='w-5 h-5 text-sm text-red-500 self-center cursor-pointer' onClick={() => {this.setCurrentCode(screen.screenCode); this.setOpen(true)}} />
                                  </>
                                ) : null}
                                {/* device delete modal */}
                                <Transition.Root show={this.state.modalOpen} as={Fragment}>
                                  <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={this.cancelBtnRef} onClose={() => this.setOpen(false)}>
                                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                      </Transition.Child>

                                      {/* This element is to trick the browser into centering the modal contents. */}
                                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                        &#8203;
                                      </span>

                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                          <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            </div>

                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                스크린 삭제하기
                                              </Dialog.Title>
                                              <div className="mt-2">
                                                <p className="text-sm text-gray-500 whitespace-pre-wrap pr-12">
                                                  연결되어 있는 모든 스케줄 정보와 광고가 함께 삭제됩니다.<br/>
                                                  정말로 삭제하시겠습니까? 
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                              type="button"
                                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                              onClick={() => this.screenRemove()}>
                                              삭제
                                            </button>
                                            <button
                                              type="button"
                                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                              onClick={() => this.setOpen(false)}
                                              ref={this.cancelBtnRef}
                                            >
                                              취소
                                            </button>
                                          </div>
                                        </div>
                                      </Transition.Child>
                                    </div>
                                  </Dialog>
                                </Transition.Root>
                              </div> 
                            </div> 
                          </div>
                          <div className="mt-4">
                            <h3 className="text-lg font-medium">
                                모델명: {screen.model}
                            </h3>

                            <div class='flex flex-row justify-between'>
                              {/* <div class='flex flex-row justify-start'>
                                <p className='text-sm text-gray-500'>운영시간:</p>
                                <input className='border-0 text-sm text-gray-500 px-0 py-0 bg-none display' type='time' timeFormat="HH:mm" dynamic={false} interval="30" defaultValue="08:00"/>
                              </div>  */}
                              <p className="text-sm text-gray-500">
                                  운영시간: {screen.operStartTime} ~ {screen.operEndTime}
                              </p>
                              {/* marking */}
                              {this.state.userAuth === 'admin' ? (
                              <p className="text-sm text-indigo-500 cursor-pointer hover:text-indigo-700" onClick={() => this.operTimeSet(screen.screenCode, screen.operStartTime, screen.operEndTime)}>설정하기</p>
                              ) : null}
                            </div> 
                            {/* <p className="text-sm text-gray-500">
                                최근 운영시간: {screen.running === null ? "없음" : screen.running}
                            </p> */}
                            <div className='flex flex-row'>
                              <p className="text-sm text-gray-500"> 스크린 해상도: </p>
                              {screen.running === null ? (
                                <p className="text-sm text-red-500">
                                  아직 연결안됨
                                </p>
                              ) : (
                                <p className="text-sm text-gray-500">
                                  {screen.resolution}
                                </p>
                              )}
                            </div>

                            <div className='flex flex-row'>
                              <p className='text-sm text-gray-500'>현재 콘텐츠:</p>
                              {screen.content.content === null ? <p className='text-sm text-red-500 ml-1'>없음</p> : 
                              <p className='text-sm text-indigo-500 cursor-pointer ml-1'>{screen.content.content}</p>}
                            </div> 
                            <div className='flex flex-row'>
                              <p className='text-sm text-gray-500'>현재 광고:</p>
                              {screen.content.ad === null ? <p className='text-sm text-red-500 ml-1'>없음</p> : 
                              <p className='text-sm text-indigo-500 cursor-pointer ml-1'>{screen.content.ad}</p>}
                            </div> 
                            <div className='flex flex-row'>
                              <p className='text-sm text-gray-500'>네트워크 상태:</p>
                              <p className={classNames(screen.network.status === 'Good' ? 'text-green-500' : 'text-red-500', 'text-sm ml-1')}>
                                {
                                  <>
                                  {screen.network.status === 'Good' ? '좋아요' : '불안해요'}
                                  </> 
                                }
                              </p>
                              {
                                <>
                                {screen.network.status === 'Good' ? <EmojiHappyIcon className='text-sm w-5 h-5 text-green-500' /> : <EmojiSadIcon className='text-sm w-5 h-5 text-red-500' /> }
                                </>
                              }
                              {/* <p className={classNames(screen.networkStatus === '좋음' ? 'text-green-500' : 'text-red-500', 'text-sm')}>({screen.networkMeasure}ms)</p> */}
                            </div> 
                            <div className={this.state.userAuth === 'admin' ? 'flex flex-row': 'hidden'}>
                              {/* marking */}
                                <button className="mt-2 flex justify-center items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                                        onClick={() => this.reboot(screen.idx)}>
                                    재부팅
                                </button>
                                <button className="mt-2 flex justify-center items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-2"
                                        onClick={() => this.poweroff(screen.idx)}>
                                    시스템 종료
                                </button>
                            </div> 
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div> 
              )}

            </div>
          </main>
        </div>
      }
      </>
    )
  }
}

