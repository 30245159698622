import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ArrowSmDownIcon, ArrowSmUpIcon} from '@heroicons/react/solid';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import ApexCharts from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import axios from 'axios';
import configData from 'pages/reactConfig'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: '스크린에어 광고 수익',
        data: [0, 0, 0, 0, 0, 0, 0]
      }],
      options: {
        chart: {
          toolbar : {
            show: true, 
          }, 
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2021-11-23", "2021-11-24", "2021-11-25", "2021-11-26", "2021-11-27", "2021-11-28", "2021-11-29"]
        },
        tooltip: {
          x: {
            format: 'yyyy-MM-dd'
          },
        },
      },
    };
  }
  render() {
    return (
      <div className="flex flex-col mt-5 relative bg-white pt-5 px-4 shadow rounded-lg h-1/12 max-h-full">
        <div className='flex flex-row justify-between align-middle items-center'>
          <div className='mb-2'>
            <h3 className="text-xl leading-6 font-medium text-gray-900 antialiased">광고 수익 차트 (2021.11.02)</h3>
          </div>
          <div className='mb-2 mr-2'>
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                일간
              </button>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                주간
              </button>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                연간
              </button>
            </span>
          </div>
        </div>
        
        <ReactApexChart 
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={400}
          className='w-full mb-0 mt-0'/>
      </div>
    )
  }
}

export class StatisticMain extends React.Component{
  constructor(props){
    super(props); 
    this.state = { 
      my_swiper : React.createRef(null), 
      stats : [
        { index : 1, name: '오늘 수익', earnings: '0', currency: 'KRW', change: '0', changeType: 'increase' },
        { index : 2, name: '이번 주 수익', earnings: '0', currency: 'KRW', change: '0', changeType: 'increase' },
        { index : 3, name: '이번 달 수익', earnings: '0', currency: 'KRW', change: '0', changeType: 'increase' },
      ], 
      adEarnShopList : [],
      allStats : [
        { name: '전체 수익', earnings: '0', currency: 'KRW', change: '0', changeType: 'increase' },
      ],
      // { name: '전체 수익', earnings: '0', currency: 'KRW', change: '0', changeType: 'increase' or 'decrease' },
    }
    this.serverAddr = configData['SERVER_URL']
  }

  slidePrev = () => {
    this.state.my_swiper.current.swiper.slidePrev() 
  }

  slideNext = () => {
    this.state.my_swiper.current.swiper.slideNext()
  }
  
  // slideControl = () => {
  //   this.slideControl = { 
  //     prev: function() {
  //       this.state.my_swiper.current.slidePrev() 
  //     }, 
  //     next: function() {
  //       this.state.my_swiper.current.slideNext() 
  //     }, 
  //     test: function(){
  //       alert('test') 
  //     }      
  //   }
  //   return this.slideControl
  // }

  componentDidMount(){
    this.updateDevInfo() 
  }

  updateDevInfo = async () =>{
    const key = sessionStorage.getItem('key'); 
    axios.defaults.withCredentials = true 
    const {data:result} = await axios.get(this.serverAddr+'/devices/screen/', 
    {
      withCredentials: true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    if (result['result'] == 'OK'){
      let data = result['data']; 
      let devArray = Array(); 
      data.forEach((element, idx, object)=>{
        devArray.push({index : idx, name:element['screenName'], earnings: '0', currency: 'KRW', change: '0', changeType: 'increase'})
      })
      this.setState({adEarnShopList:devArray})
    }else{

    }
  }

  set_my_swiper = (ev) =>{
    console.log('ev') 
    console.log(ev) 
    console.log(this.state.my_swiper)
    // this.setState({my_swiper:ev})
  }

  render(){
    return(
      <div className='flex flex-col mt-4 h-full overflow-y-auto'>
        {/* First Line  */}
        <div className='grid grid-cols-4 gap-4 md:px-0 px-2'>
          <div className='col-span-3'>
            <div className='grid grid-cols-3'>
              {this.state.stats.map((item) => (
                <div key={item.name} className={classNames(
                  item.index === 1 ? 'rounded-tl-lg rounded-bl-lg' : '', 
                  item.index === 3 ? 'rounded-tr-lg rounded-br-lg' : '', 
                  "py-5 px-4 bg-white flex flex-col")}>
                  <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline md:text-2xl text-lg font-semibold text-indigo-600">
                      {item.earnings}
                      <span className="ml-2 md:text-base text-sm font-medium text-gray-500">{item.currency}</span>
                    </div>
                  </dd>
                  {
                    item.changeType === null ? (
                      <div className='invisible bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-lg text-sm font-medium mt-2 justify-start self-start'>
                          <ArrowSmUpIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                        {item.change}
                      </div>                    
                    ) : (
                      <div className={classNames(
                        item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-lg text-sm font-medium mt-2 justify-start self-start')}>
                        {item.changeType === 'increase' ? (
                          <ArrowSmUpIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowSmDownIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        )}
  
                        <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                        {item.change}
                      </div>
                    )
                  }
                </div>
              ))}
            </div>
          </div>
          <div className='grid grid-cols-1'>
            {this.state.allStats.map((item) => (
                  <div key={item.name} className="py-5 px-4 rounded-lg bg-white flex flex-col">
                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline md:text-2xl text-lg font-semibold text-indigo-600">
                        {item.earnings}
                        <span className="ml-2 md:text-base text-sm font-medium text-gray-500">{item.currency}</span>
                      </div>
                    </dd>
                    {
                      item.changeType === null ? (
                        <div className='bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-lg text-sm font-medium mt-2 justify-start self-start invisible'>
                          <ArrowSmUpIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                          {item.change}
                        </div>
                      ):(
                        <div className={classNames(
                          item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800 invisible',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-lg text-sm font-medium mt-2 justify-start self-start'
                        )}>
                        {item.changeType === 'increase' ? (
                          <ArrowSmUpIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowSmDownIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                        <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                        {item.change}
                      </div>
                      )
                    }
                  </div>
            ))}
          </div>
        </div>
        {/* Second Line  */}
        <div className="md:px-0 px-2">
          <ApexChart /> 
        </div>

        {/* Last Line */}
        <div className='flex flex-col mt-4 bg-white rounded-lg relative md:px-0 px-2'>
          <div className='py-4 px-2'>
            <h2 className='text-lg font-bold antialiased leading-3 px-2 py-2'> 스크린별 광고 수익 </h2>
          </div>
          <div class='z-10 align-top -mt-10 mb-5'> 
            <div class='relative flex flex-row justify-between top-14 z-30'>
                <div>
                  <ChevronLeftIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-12 w-12 text-red-500 cursor-pointer" aria-hidden="true"
                  onClick={this.slidePrev}/>
                </div>
                <div>
                  <ChevronRightIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-12 w-12 text-red-500 cursor-pointer" aria-hidden="true"
                  onClick={this.slideNext}/>
                </div>
            </div>
            <div class='relative z-20 px-6'>
              <Swiper
                spaceBetween={100}
                slidesPerView={4}
                onInit={(ev) => {
                  this.set_my_swiper(ev) 
                }}
                ref={this.state.my_swiper}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}>
                {this.state.adEarnShopList.map((item) => (
                  <SwiperSlide>
                    <div key={item.name} className={classNames(
                      item.index === 1 ? 'rounded-tl-lg rounded-bl-lg' : '', 
                      item.index === 3 ? 'rounded-tr-lg rounded-br-lg' : '', 
                      "px-8 mb-4 bg-white flex flex-col")}>
                      <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                          {item.earnings}
                          <span className="ml-2 text-base font-medium text-gray-500">{item.currency}</span>
                        </div>
                      </dd>
                      <div
                        className={classNames(
                          item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-lg text-sm font-medium mt-2 justify-start self-start'
                        )}>
                        {item.changeType === 'increase' ? (
                          <ArrowSmUpIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowSmDownIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        )}

                        <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                        {item.change}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
          </div> 
        </div>
      </div>
    )
  }
}
