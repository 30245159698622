import React, { Fragment, useState } from 'react'
import { Menu, Popover, Transition, Dialog } from '@headlessui/react'
import _ from 'lodash'
import axios from 'axios';
import TimePicker from 'react-gradient-timepicker';
import configData from 'pages/reactConfig'
import Multiselect from 'multiselect-react-dropdown';

export default class ReportModal extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      open : false, 
      cancelButtonRef : React.createRef(null), 
      code : null, 
      devName : null, 
      isLoading : true, 
      operStartInput : React.createRef(null), 
      operEndInput : React.createRef(null), 
      currentYearMonth : null, 
      defaultYearMonth : null, 
      adlist : null, 
      selectedItemList : Array(), 
    }
    this.serverAddr = configData['SERVER_URL']
    this.multiSelectRef = React.createRef(null) 
    this.atagToMoveRef = React.createRef(null) 
    this.warningTextRef = React.createRef(null) 
    this.dateInputRef = React.createRef(null)
  }

  componentDidMount(){
    let newDate = new Date(); 
    let year = newDate.getFullYear(); 
    let month = newDate.getMonth() + 1; 
    let prev1Month = month - 1; 

    if (month < 10){
      month = '0' + month
    }

    if (prev1Month < 10){
      prev1Month = '0' + prev1Month
    }

    let currentYearMonthStr = year+'-'+month 
    let defaultYearMonthStr = year+'-'+prev1Month

    this.setState({currentYearMonth:currentYearMonthStr, defaultYearMonth:defaultYearMonthStr})
  }

  getAdList = async(code, name, year, month) => { 
    const key = sessionStorage.getItem('key'); 
    axios.defaults.headers.common['Authorization'] = 'Token '+key 
    const {data:result} = await axios.get(this.serverAddr+'/advertisement/getADContent/'+code+'/'+year+'/'+month, 
    {
      withCredentials: true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  setOpen = async (code, name) =>{
    const currentDate = new Date(); 
    const currentYear = currentDate.getFullYear() 
    const prev1Month = (currentDate.getMonth() + 1) -1; 
    if(prev1Month === 1){
      currentYear = currentYear - 1; 
      prev1Month = 12; 
    }
    const adContentList = await this.getAdList(code, name, currentYear, prev1Month) 
    console.log(adContentList)
    const adList = Array(); 
    if (adContentList['data'] !== null){
      adContentList['data'].forEach((element, idx) => {
        adList.push({id: idx, name:element})
      });
    }
    this.setState({code:code, devName:name, open:true, isLoading:false, adlist:adList})
  }

  setClose = () => {
    this.setState({open:false})
  }

  moveToPDF = () => { 
    const selectedItems = this.multiSelectRef.current.getSelectedItems()
    const itemArray = Array() 
    const year = parseInt(this.dateInputRef.current.value.split('-')[0])
    const month = parseInt(this.dateInputRef.current.value.split('-')[1])

    if (selectedItems.length === 0){
      this.warningTextRef.current.innerHTML = "대상광고를 선택해주세요"
      this.warningTextRef.current.classList.remove('hidden')
    }else{
      if (!this.warningTextRef.current.classList.contains('hidden')){
        this.warningTextRef.current.classList.add('hidden') 
      }
      for(let i = 0; i < selectedItems.length; i ++){
        itemArray.push(selectedItems[i]['name'])
      }
      this.atagToMoveRef.current.href='/reporting/'+this.state.code+'/'+year+'/'+month+'/'+itemArray; 
      this.atagToMoveRef.current.click() 
    }
  }

  operEdit = async(code, startTime, endTIme) =>{
    const reqData = {'startTime':startTime, 'endTime':endTIme}
    const result = await axios.put(this.serverAddr+'/devices/'+code+'/modifyOper/', reqData, 
    {
      withCredentials: true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  onSelect(selectedList, selectedItem) {

  }

  onRemove(selectedList, removedItem) {

  }

  async dateOnChage(event) { 
    let changedDate = event.target.value
    const year = changedDate.split('-')[0]
    const month = changedDate.split('-')[1]
    const code = this.state.code 
    const name = this.state.devName
    const adContentList = await this.getAdList(code, name, year, month) 
    const adList = Array(); 
    if (adContentList['data'] !== null){
      adContentList['data'].forEach((element, idx) => {
        adList.push({id: idx, name:element})
      });
    }
    this.multiSelectRef.current.resetSelectedValues()
    this.setState({code:code, devName:name, open:true, isLoading:false, adlist:adList})
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog as="div" className="fixed z-20 inset-0 overflow-y-hidden" onClose={this.setClose}>
          <div className="flex flex-col justify-center align-middle min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className='mt-24 md:mt-96'/>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

              <div className='flex flex-row mx-auto justify-center self-center items-center align-middle bg-white rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:align-middle sm:max-w-lg lg:max-w-xl lg:h-96 sm:w-full sm:p-6 py-2'>
                <div className="flex items-center self-center flex-col w-full">
                  <div className='flex flex-row w-full'>

                    <div className="flex flex-col text-left justify-start ml-2 w-full">   
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        [{this.state.devName}] 송출보고서 출력하기
                      </Dialog.Title>

                      <span className='text-gray-400 text-xs font-medium mb-4'>기간과 대상 광고를 설정해주세요</span>

                      <div className='flex flex-row justify-start gap-x-6 my-1 w-10/12'>
                        <span className='text-base font-normal text-gray-600 self-center w-3/12'>대상기간</span>
                        <input type='month' max={this.state.defaultYearMonth} defaultValue={this.state.defaultYearMonth} className='w-7/12 rounded-lg text-base font-normal self-center cursor-pointer'
                               ref={this.dateInputRef} onChange={(e) => this.dateOnChage(e)}/>
                      </div>

                      <div className='flex flex-row justify-start w-10/12 gap-x-6 my-1'>
                        <span className='text-base font-normal text-gray-600 self-center w-3/12 whitespace-nowrap'>대상광고</span>
                        <Multiselect
                          options={this.state.adlist} // Options to display in the dropdown
                          onSelect={this.onSelect} // Function will trigger on select event
                          onRemove={this.onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          className='sm:w-full md:w-7/12 rounded-lg self-center'
                          ref={this.multiSelectRef}
                        />
                      </div>
                      <p className='self-start text-sm font-semibold text-red-700 antialiased hidden' ref={this.warningTextRef}></p>
                    </div>
                  </div> 
                  <div className="flex flex-row w-full justify-end mt-2 gap-x-2">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => this.setClose(false)}
                      ref={this.cancelBtnRef}>
                      취소
                    </button>
                    <button 
                      type='button'
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                      onClick={() => this.moveToPDF()}>
                      출력
                    </button>
                    <a href="#" target="_blank" className='hidden' ref={this.atagToMoveRef}/> 
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>

        </Dialog>
      </Transition.Root>
      )}
      </>
    )
  }
}
