import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'

export default function LocationMgr(props){
  useEffect(() => {
    document.title ="스크린에어: 디지털 사이니지 매장 광고 플랫폼"
    document.location.href ='/service'
  }, {})

  return(
    <>
    </>
  )
}
