import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/outline'
import { Component } from 'react'
import { render } from 'react-dom'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// Custom lib 
import SampleImages from 'static/img/images'
import configData from 'pages/reactConfig'
import Notification from 'pages/util/notification'

import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

export class WaitAdvertiser extends React.Component{
  constructor(props){
    super(props) 
    this.state = { 
      isLoading : true, 
      open : false, 
      ctnOpen : false, 
      pos : false, 
      waitAdSchedule : null, 
      completeAdSchedule : null, 
      currentIdx : null, 
      my_swiper : React.createRef(null), 
      deterIdxList : null, 
      // Notification state 
      notiRef : React.createRef(null), 
      notiShow : false, 
      notiSubject : null, 
      notiText : null, 
      notiType : 'success', 
      // Schedule state 
      waitSchedulePage : 1, 
      waitScheduleLen : null, 
      waitStartIdx : null, 
      waitEndIdx : null, 
      completedAdPage : 1,
      completedAdLen : null, 
      adStartIdx : null, 
      adEndIdx : null 
    }
    // marking 
    this.size = 4
    this.cancelButtonRef = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    this.setState({isLoading:true})
    this.getWaitAdSchedule()
  }

  setWaitScheduleIdx = () => {
    // Length가 없으면 계산할 필요 X 
    if (this.state.waitScheduleLen === 0) return false; 

    let startIdx = _.cloneDeep(this.state.waitStartIdx)
    let endIdx = _.cloneDeep(this.state.waitEndIdx)
    this.state.waitSchedulePage === 1 ? startIdx =1 : startIdx += this.size 
    endIdx = this.size * this.state.waitSchedulePage 
    if (endIdx >= this.state.waitScheduleLen){
      endIdx = this.state.waitScheduleLen
    }
    this.setState({waitStartIdx:startIdx, waitEndIdx:endIdx})
  }

  setAdSchduleIdx = () => {
    if (this.state.completedAdLen === 0) return false; 

    let startIdx = _.cloneDeep(this.state.adStartIdx)
    let endIdx = _.cloneDeep(this.state.adEndIdx)
    this.state.completedAdPage === 1 ? startIdx =1 : startIdx += this.size 
    endIdx = this.size * this.state.completedAdPage 
    if (endIdx >= this.state.completedAdLen){
      endIdx = this.state.completedAdLen
    }
    this.setState({adStartIdx:startIdx, adEndIdx:endIdx})
  }

  waitSchedulePagination = (method) => {
    let schedulePage = _.cloneDeep(this.state.waitSchedulePage)
    if (method === 'prev'){
      if (this.state.waitSchedulePage !== 1) {
        schedulePage -= 1; 
        this.setState({waitSchedulePage:schedulePage}, () => this.getWaitAdSchedule())
      }else{
        return false; 
      }
    }else{
      if ( (this.size * schedulePage) < this.state.waitScheduleLen) {
        schedulePage += 1; 
        this.setState({waitSchedulePage:schedulePage}, () => this.getWaitAdSchedule())
      }else{
        return false; 
      }
    }
  }

  adSchedulePagination = (method) => {
    let schedulePage = _.cloneDeep(this.state.completedAdPage)
    if (method === 'prev'){
      if (this.state.completedAdPage !== 1) {
        schedulePage -= 1; 
        this.setState({completedAdPage:schedulePage}, () => this.getCptSchedule())
      }else{
        return false; 
      }
    }else{
      if ( (this.size * schedulePage) < this.state.completedAdLen) {
        schedulePage += 1; 
        this.setState({completedAdPage:schedulePage}, () => this.getCptSchedule())
      }else{
        return false; 
      }
    }
  }

  getWaitAdSchedule = async() => {
    axios.defaults.withCredentials = true 
    const url = this.serverAddr+'/advertisement/waitSchedule/'+this.state.waitSchedulePage
    console.log(url) 
    const {data:result} = await axios.get(url,
    {
      withCredentials:true, 
      headers:{
        'Content-Type' : 'application/json',
        'Accept': 'application/json'
      }
    }); 
    this.setState({waitAdSchedule:result['data'], waitScheduleLen:result['length']}, () => {
      this.setWaitScheduleIdx()
      this.getCptSchedule()
    })
  }

  getCptSchedule = async() => {
    axios.defaults.withCredentials = true 
    const url = this.serverAddr+'/advertisement/cptSchedule/'+this.state.completedAdPage
    try{
      const result = await axios.get(url,
      {
        withCredentials:true, 
        headers:{
          'Content-Type' : 'application/json',
          'Accept': 'application/json'
        }
      }); 
      this.setState({completeAdSchedule:result['data']['data'], completedAdLen:result['data']['length'], isLoading:false}, () => {
        this.setAdSchduleIdx()
      })
    }catch(error){
      const status = error.response.status; 
      if (status === 401){
        alert("비정상적인 접근입니다.")
      }else{
        alert("예기치 못한 에러가 발생하였습니다.\n에러코드"+status)
      }
    }
  }

  setCtnOpen = (condition, idx=null) => {
    if (idx !== null){
      this.setState({ctnOpen:condition, currentIdx:idx})
    }else{
      this.setState({ctnOpen:condition})
    }
  }

  setPos = (condition) => {
    this.setState({pos:condition})
  }

  setOpen = (condition) => {
    this.setState({open:condition})
  }

  slidePrev = () => {
    console.log('prev ok')
    console.log(this.state.my_swiper)
    this.state.my_swiper.current.swiper.slidePrev() 
  }
  slideNext = () => {
    console.log('next ok')
    console.log(this.state.my_swiper)
    this.state.my_swiper.current.swiper.slideNext()
  }

  set_my_swiper = (ev) =>{
    // this.setState({my_swiper:ev})
  }

  set_my_swiper2 = (swiper, swipeRef) => {
    this.setState({my_swiper:swipeRef})
  }

  setDeter = (idx) => {
    this.setState({deterIdxList:idx})
  }

  alertCompletedAd = () =>{
    this.state.notiRef.current.setShowAutoManaulSet(true, "exclamation", "시스템 알림", "완료된 광고의 콘텐츠는 미리보기가 제공되지 않아요 :(")     
  }

  reqDeter = async (deter) => {
    let deterAd; 
    if (deter === true){
      // Accept 
      deterAd = true; 
    }else{
      // Negative 
      deterAd = false 
    }
    // this.state.notiRef.current.setShowAutoManaulSet(true, "success", "시스템 알림", "광고 승인이 정상적으로 처리되었습니다.") 
    let data = {'deter':deterAd, 'ad':this.state.deterIdxList}
    axios.defaults.withCredentials = true 
    try{
      const result = await axios.put(this.serverAddr+'/advertisement/setAdSchduleAll', data, 
      {
        withCredentials:true, 
        headers:{
          'Content-Type' : 'application/json',
          'Accept': 'application/json'
        }
      });
      let text; 
      deterAd === true ? text="승인" : text="거절"
      this.state.notiRef.current.setShowAutoManaulSet(true, "success", "시스템 알림", "광고 "+text+"이 정상적으로 처리되었습니다.") 
    }catch(error){
      const status = error.response.status; 
      console.log('error') 
      if (status === 401){
        this.state.notiRef.current.setShowAutoManaulSet(true, "exclamation", "시스템 알림", "비정상적인 접근입니다.")     
      }else{
        this.state.notiRef.current.setShowAutoManaulSet(true, "exclamation", "시스템 알림", "예기치 못한 에러가 발생하였습니다. 에러코드:"+status)     
      }
    }
    this.getWaitAdSchedule()
  }
  
  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <div className="flex flex-col mt-4 px-1">  
        <Notification show={this.state.notiShow} subject={this.state.notiSubject} text={this.state.notiText} type={this.state.notiType} ref={this.state.notiRef}/> 
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 md:px-0 px-2">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <h1 className='mt-2 mb-2 text-xl font-extrabold leading-6'> 대기중인 광고주 </h1> 
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {this.state.waitAdSchedule.length === 0? (
                  // Empty 
                  <div className='w-full h-80 rounded-lg bg-white divide-y divide-gray-200 flex flex-row justify-center'>
                    <div className='items-center self-center mt-4 text-gray-500 flex flex-col'>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className='text-xl font-extrabold mt-4 antialiased self-center text-gray-500'>대기중인 광고가 존재하지 않습니다.</span>
                    </div>
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          신청인
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          결제금액
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> 
                          광고 콘텐츠
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          광고 송출 기간
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          광고 송출 시간
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          재생횟수
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          등록일
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          광고송출
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {this.state.waitAdSchedule.map((ad, idx) => (
                        <tr key={ad.advertisement}>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              {/* 광고주 name */}
                              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                {ad.advertiser.length === 1 ? (
                                  <span className="text-xl font-medium leading-none text-white">{ad.advertiser[0].toUpperCase()}</span>  
                                ) : null}
                                {ad.advertiser.length === 2 ? (
                                  <span className="text-lg font-medium leading-none text-white">{ad.advertiser}</span>  
                                ) : null}
                                {ad.advertiser.length >= 3 ? (
                                  <span className="text-lg font-medium leading-none text-white">{ad.advertiser[ad.advertiser.length-2].toUpperCase()}{ad.advertiser[ad.advertiser.length-1].toUpperCase()}</span>  
                                ) : null}
                              </span>

                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{ad.advertiser}</div>
                              </div>
                            </div>
                          </td>

                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {ad.cost === 0 ? "무료" : ad.cost}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <span className='text-sm font-semibold text-indigo-500 cursor-pointer'
                            onClick={()=>{
                              this.setCtnOpen(true, idx)
                            }}>                              
                                {ad.contentName.length === 1 ? ad.contentName : ad.contentName[0]+" 외 "+(ad.contentName.length-1)+" 개"}
                            </span>
                          </td>
                          <td className="px-6 py-3 text-sm text-gray-500">
                            {moment(ad.startDate).format('YYYY-MM-DD')}~{moment(ad.endDate).format('YYYY-MM-DD')}
                          </td>
                          <td className="px-6 py-3 text-sm text-gray-500">
                            {moment(ad.startDate).format('HH:mm')}~{moment(ad.endDate).format('HH:mm')}
                          </td>

                          <td className="px-6 py-3 text-sm text-gray-500">{ad.count}회</td>
                          <td className="px-6 py-3 text-sm text-gray-500">
                            {moment(ad.regDate).format('YYYY-MM-DD')}
                          </td>
                          <td className="px-2 py-3 text-sm font-medium">
                            <span className="px-2 py-0.5 mr-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800 cursor-pointer"
                            onClick={
                              () => {
                                this.setDeter(ad.idx)
                                this.setPos(true); 
                                this.setOpen(true); 
                              }
                            }>
                              승인
                            </span>
                            <span className="px-2 py-0.5 inline-flex text-xs leading-5 font-semibold rounded-md bg-red-200 text-red-800 cursor-pointer"
                              onClick={
                                () => {
                                  this.setDeter(ad.idx)
                                  this.setPos(false); 
                                  this.setOpen(true); 
                                }
                              }>
                              거절
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {/* marking */}
                {/* Pagination */}
                {this.state.waitScheduleLen === 0 ? "" : (
                  <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        전체 데이터 <span className="font-medium">{this.state.waitScheduleLen}</span>개 중 <span className="font-medium">{this.state.waitStartIdx}-{this.state.waitEndIdx}</span>번째 데이터 표시중
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <a
                        href="#"
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() => this.waitSchedulePagination('prev')}
                        >
                        이전페이지
                      </a>
                      <a
                        href="#"
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() => this.waitSchedulePagination('next')}
                        >
                        다음페이지
                      </a>
                    </div>
                  </nav>
                )}

              </div>
            </div>
          </div>

          {/* Ad modal */}
          <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={this.cancelButtonRef} onClose={() => this.setOpen(false)}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {
                      this.state.pos === true ? (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                      ) : (
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      )
                    }

                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {this.state.pos === true ? "광고 수락하기" : "광고 거절하기" }
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 whitespace-pre-wrap">
                        {this.state.pos === true ? "신청인의 광고 송출 기간과 시간을 잘 숙지하고 광고 송출에 동의힙니다." : "신청인의 광고를 거절합니다.\n한번 거절된 광고는 되돌릴 수 없습니다."}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {
                      this.state.pos === true ? (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greeen-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            this.setOpen(false)
                            this.reqDeter(true)}}>
                          수락
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {
                            this.setOpen(false)
                            this.reqDeter(false)}}>
                          거절
                      </button>
                      )
                    }
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => this.setOpen(false)}
                      ref={this.cancelButtonRef}
                    >
                      취소
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
          {/* Allow/Deny Modal */}

          </Transition.Root>
          {/* Content Modal  */}
          {/* 광고가 없는 경우 DOM 미적용 */}
          {
          this.state.waitAdSchedule.length === 0 ? "" : (
            <Transition.Root show={this.state.ctnOpen} as={Fragment}>
              <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => this.setCtnOpen(false)}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>
          
                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:max-h-full sm:w-full sm:p-6">
                      <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        allowTouchMove={false}
                        observer={true}
                        onInit={(ev) => {
                          this.set_my_swiper(ev) 
                        }}
                        ref={this.state.my_swiper}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => this.set_my_swiper2(swiper, this.state.my_swiper)}>
                        
                        {this.state.waitAdSchedule[(Number(this.state.currentIdx))]['contentPath'].map((item) => (
                          <SwiperSlide>
                            <img src={this.serverAddr+"/media/"+item} className='rounded-lg object-fill w-full h-68'/>
                          </SwiperSlide>
                        ))}
    
                      </Swiper>
                      {this.state.waitAdSchedule[(Number(this.state.currentIdx))]['contentPath'].length === 1 ? (
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="button"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            onClick={() => this.setCtnOpen(false)}>확인하였습니다. 
                          </button>
                        </div>
                      ) : (
                        <div className="mt-5 flex flex-row justify-between sm:mt-6 gap-x-6">
                          <div className='flex-1'>
                            <button type="button" 
                              class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => this.setCtnOpen(false)}>
                              닫기
                            </button>
                          </div>
                          <div className='flex flex-row'>
                            <div>
                              <ChevronLeftIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-12 w-12 text-red-500 cursor-pointer" aria-hidden="true"
                              onClick={() => this.slidePrev()}/>
                            </div>
                            <div>
                              <ChevronRightIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-12 w-12 text-red-500 cursor-pointer" aria-hidden="true"
                              onClick={() => this.slideNext()}/>
                            </div>
                          </div>
                        </div>
                      )}
    
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          )
        }
        {/* 완료된 광고 UI */}
        <div className="flex flex-col mt-4">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 md:px-0 px-2">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <h1 className='mt-2 mb-2 text-xl font-extrabold leading-6'> 완료된 광고 </h1> 
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {this.state.completeAdSchedule.length === 0? (
                <div className='w-full h-80 rounded-lg bg-white divide-y divide-gray-200 flex flex-row justify-center'>
                  <div className='items-center self-center mt-4 text-gray-500 flex flex-col'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className='text-xl font-extrabold mt-4 antialiased self-center text-gray-500'>아직 완료된 광고가 없습니다.</span>
                  </div>
                </div>
                ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        신청인
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        결제금액
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                         광고 콘텐츠
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        광고 송출 기간
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        광고 송출 시간
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        재생횟수
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        등록일
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        처리결과
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {this.state.completeAdSchedule.map((completedAd) => (

                      <tr key={completedAd.advertisement}>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            {/* 광고주 name */}
                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                              {completedAd.advertiser.length === 1 ? (
                                <span className="text-xl font-medium leading-none text-white">{completedAd.advertiser[0].toUpperCase()}</span>  
                              ) : null}
                              {completedAd.advertiser.length === 2 ? (
                                <span className="text-lg font-medium leading-none text-white">{completedAd.advertiser}</span>  
                              ) : null}
                              {completedAd.advertiser.length >= 3 ? (
                                <span className="text-lg font-medium leading-none text-white">{completedAd.advertiser[completedAd.advertiser.length-2].toUpperCase()}{completedAd.advertiser[completedAd.advertiser.length-1].toUpperCase()}</span>  
                              ) : null}
                            </span>

                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{completedAd.advertiser}</div>
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {completedAd.cost === 0 ? "무료" : completedAd.cost}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <span className='text-sm text-gray-900'
                          onClick={()=>{
                            this.alertCompletedAd()
                          }}>                              
                            {completedAd.contentName.length === 1 ? completedAd.contentName : completedAd.contentName[0]+" 외 "+(completedAd.contentName.length-1)+" 개"}
                          </span>
                        </td>
                        <td className="px-6 py-3 text-sm text-gray-500">
                          {moment(completedAd.startDate).format('YYYY-MM-DD')}~{moment(completedAd.endDate).format('YYYY-MM-DD')}
                        </td>
                        <td className="px-6 py-3 text-sm text-gray-500">
                          {moment(completedAd.startDate).format('HH:mm')}~{moment(completedAd.endDate).format('HH:mm')}
                        </td>

                        <td className="px-6 py-3 text-sm text-gray-500">{completedAd.count}회</td>
                        <td className="px-6 py-3 text-sm text-gray-500">
                          {moment(completedAd.regDate).format('YYYY-MM-DD')}
                        </td>
                        <td className="px-2 py-3 text-sm font-medium">
                          {completedAd.result === 'accept' ? (
                            <span className="px-5 py-0.5 mr-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-100 text-green-800">승인됨</span>
                          ) : (
                            <span className="px-5 py-0.5 inline-flex text-xs leading-5 font-semibold rounded-md bg-red-200 text-red-800">거절됨</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> 
                )}
                {/* Pagination */}
                {this.state.completedAdLen === 0 ? "" : (
                  <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        전체 데이터 <span className="font-medium">{this.state.completedAdLen}</span>개 중 <span className="font-medium">{this.state.adStartIdx}-{this.state.adEndIdx}</span>번째 데이터 표시중
                      </p>
                    </div>
                    <div className="flex flex-row md:justify-between justify-start">
                      <a
                        href="#"
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() => this.adSchedulePagination('prev')}
                      >
                        이전페이지
                      </a>
                      <a
                        href="#"
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() => this.adSchedulePagination('next')}
                      >
                        다음페이지
                      </a>
                    </div>
                  </nav>
                )}


              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      </>
    )
  }
}