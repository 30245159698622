import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster } from 'react-hot-toast';
import UserConfig from "servicePages/config/userManager";

export default function HistoryDetail(props){
  const {history} = props 
  const pk = props.match.params.shopPK
  const [payData, setPayData] = useState({})
  const [adData, setAdData] = useState([])
  const [adProgText, setAdProgText] = useState("광고 진행이 확정되었습니다.\n광고 시작일에 맞추어 광고가 송출됩니다.")
  const [isLogConfirmAva, setLogConfirm] = useState(false) 

  useEffect(() => {
    dataInit()
  }, [])

  const dataInit = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}app/v1/payment/get/detail/ad/${pk}/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      console.log(resData)
      setPayData(resData.data)
      setAdData(resData.adData)
      console.log(resData.data)
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const refundReqConfirm = async() => {
    const confirm = window.confirm("정말로 구매를 취소하시겠습니까?")
    if (confirm === true){
      const userToken = UserConfig.getAccKey()
      const reqURI = `${HOST_ADDR}app/v1/payment/refund/ad/${pk}`
      const data = {} 
      const headers = { headers : {Authorization:`Bearer ${userToken}`}}
      try{
        const res = await axios.post(reqURI, data, headers)
        const resData = res.data 
        if (resData.result === 'OK'){
          await dataInit()
        }else{
          const msg = resData.msg 
          alert(msg) 
        }
      }catch(e){
        const errRes = e.response
        const errCode = errRes.data.code
        const errText = errRes.data.msg
        alert(errText)
      }
    }else{
      return false 
    }
  }

  return(
    <>
    {/* Toast */}
    <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
    {/* Header */}
    <div className="container mx-auto px-24 py-12">
      <div className="flex flex-col flex-grow">
        <ServiceHeader props={props}/> 
      </div> 
    </div> 

    <div class="bg-indigo-900 text-center py-4 lg:px-4 hidden">
      <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex px-4" role="alert">
        <span class="flex rounded-full bg-indigo-500 px-2 py-1 text-xs font-bold mr-3">안내</span>
        <div className="flex flex-col justify-start gap-y-2">
          <span class="font-semibold mr-2 text-left">시스템이 본격적으로 오픈되기 전으로 매장과 시스템이 연계되어 있지 않습니다.</span>
          <span class="font-semibold mr-2 text-left">매장 송출 사진 및 실시간 로그는 임의 데이터로 표현됨을 알려드립니다.</span>
        </div>
      </div>
    </div>

    {/* Divide Line */}
    <div className="border-t-2 border-t-gray-400 h-1"/> 

    <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-80 pt-12 mb-12">
      <div className="flex flex-row justify-between mb-2">
        <div className="flex flex-col justify-start gap-y-2">
          <p className="text-lg font-nexon text-gray-500"> 구매번호: {payData.uid}</p>
          <p className="text-xl font-nexon self-start"> {payData.product}</p>
        </div>
        <div className={ payData.status === 'cancel' || payData.statusLevel >= 3 || payData.status === 'deny' ? 'hidden' : "flex flex-col justify-center"}>
          <div className="flex flex-row rounded-full border-2 border-gray-400 shadow-lg cursor-pointer" onClick={() => refundReqConfirm()}>
            <p className="text-sm font-nexon self-center py-2 px-4">구매 취소</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start mt-4 gap-y-1">
        <div className="flex flex-row justify-between">
          <p className="text-xl font-nexonBold text-black py-0.5">광고 진행 매장</p>
          <p className="text-xl font-nexon text-black py-0.5">{payData.shopName}</p>
        </div>
        <div className="flex flex-row justify-between">
        <p className="text-xl font-nexonBold text-black py-0.5">구매일시</p>
        <p className="text-xl font-nexon text-black py-0.5">{payData.paidDate}</p>
        </div>
      <p className={payData.refundDate === null ? 'hidden' : 'text-base text-black font-nexon self-start'}> 취소일시: {payData.refundDate}</p>
      </div>
      
      <p className="text-2xl font-nexonBold text-black pt-12 pb-4"> 광고 진행 단계</p>

      {/* Dot progress UI */}
      <div className='flex flex-row w-full justify-center pt-4 pb-6'>

        <div className='flex flex-col w-4/12 justify-center'>           
          <div className={payData.statusLevel >=1 ? 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-rose-500' : 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-gray-500'}>
            {payData.statusLevel >= 1 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 self-center font-bold text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="self-center text-center text-white font-bold w-12 h-12" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            )}
          </div>
          <p className={payData.statusLevel >= 1 ? 'text-base font-nexon self-center text-center pt-1 text-rose-500 font-semibold' : 'text-base font-nexon self-center text-center pt-1 text-gray-700 font-normal'}>결제 완료</p>
        </div>

        <div className={payData.statusLevel >= 2 ? 'flex flex-row w-4/12 border-b-2 self-center border-b-rose-500' : 'flex flex-row w-4/12 border-b-2 self-center border-b-gray-400'}/>


        <div className='flex flex-col w-4/12 justify-center'>           
          <div className={payData.statusLevel >= 2 ? 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-rose-500' : 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-gray-500'}>
            {payData.statusLevel >= 2 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 self-center text-center text-white font-bond" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="self-center text-center text-white font-bold w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>
            )}
          </div>
          <p className={payData.statusLevel >= 2 ? 'text-base font-nexon self-center text-center pt-1 text-rose-500 font-semibold' : 'text-base font-nexon self-center text-center pt-1 text-gray-700 font-normal'}>매장 승인</p>
        </div>

        <div className={payData.statusLevel >= 3 ? 'flex flex-row w-4/12 border-b-2 self-center border-b-rose-500' : 'flex flex-row w-4/12 border-b-2 self-center border-b-gray-400'}/>


        <div className='flex flex-col w-4/12 justify-center'>           
          <div className={payData.statusLevel >=3 ? 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-rose-500' : 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-gray-500'}>
            {payData.statusLevel >= 3 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 self-center text-center text-white font-bond" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="self-center text-center text-white font-bold w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>
            )}
          </div>
          <p className={payData.statusLevel >= 3 ? 'text-base font-nexon self-center text-center pt-1 text-rose-500 font-semibold' : 'text-base font-nexon self-center text-center pt-1 text-gray-700 font-normal'}>최종 승인</p>
        </div>

        <div className={payData.statusLevel >= 3 ? 'flex flex-row w-4/12 border-b-2 self-center border-b-rose-500' : 'flex flex-row w-4/12 border-b-2 self-center border-b-gray-400'}/>

        <div className='flex flex-col w-4/12 justify-center'>           
          <div className={payData.statusLevel >=3 ? 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-rose-500' : 'flex flex-row w-16 h-16 rounded-full justify-center self-center bg-gray-500'}>
            {payData.statusLevel >= 3 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 self-center text-center text-white font-bond" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="self-center text-center text-white font-bold w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>
            )}
          </div>
          <p className={payData.statusLevel >= 3 ? 'text-base font-nexon self-center text-center pt-1 text-rose-500 font-semibold' : 'text-base font-nexon self-center text-center pt-1 text-gray-700 font-normal'}>광고 진행</p>
        </div>
      </div>

      <div className='flex flex-row w-full justify-start pt-1'>
        <p className='text-xl font-nexon text-black font-semibold'>진행 상태:&nbsp;</p>
        <p className='text-xl font-nexonBold text-rose-500 font-semibold'> {payData.status === 'cancel' ? "취소된 광고" : payData.status === 'deny' ? "광고 게시 거절" : payData.status === 'wait' ? "매장 승인 대기중" : payData.status === 'acceptShop' ? "최종 승인 대기중" : "광고 진행 확정"}</p>
      </div>
      <p className='text-lg font-nexon text-black pt-3'>
        {payData.status === 'cancel' ? "광고 취소(환불)가 완료되었습니다." : 
        payData.status === 'deny' ? "광고 게시가 거절되었습니다." : 
        payData.status === 'wait' ? "매장에서 광고 콘텐츠를 검토하고 있는 중입니다." :
        payData.status === 'acceptShop' ? "관리자가 최종적으로 콘텐츠를 검토하고 있는 중입니다."
        : adProgText}
      </p>

      {/* 매장 송출 사진  */}
      {payData.verifyCtn !== null ? ( 
        <div className="flex flex-row justify-between pt-1">
          <p className="text-lg font-nexon self-center">(테스트용) 매장에서 송출 사진을 제출했습니다.</p>
          <a className="text-lg font-nexon self-center underline text-rose-500" href={`${HOST_ADDR.slice(0, -1)}${payData.verifyCtn}`} download="송출사진.jpg">송출 사진 확인(임의 데이터)</a>
          {/* <img className="w-72 h-72" src={`${HOST_ADDR.slice(0, -1)}${payData.verifyCtn}`}/> */}
        </div> 
      ) : null }
      
      {/* 실시간 송출 로그 */}
      {payData.statusLevel >=3 ? (
        <div className="flex flex-col justify-start">
          <p className="text-lg font-nexon pt-1 underline cursor-pointer" onClick={() => {window.open(`/service/adLog/${payData.shopName}/${payData.adName}`, "_blank", "location=yes,status=yes")}}>실시간 송출 로그 확인 (임의 데이터)</p>
        </div> 
        
      ) : null }

      
      {/* const w = window.open("", "_blank", `location=yes,height=${content.height},width=${content.width},scrollbars=yes,status=yes`) */}

      {isLogConfirmAva === true ? ( 
        // {/* 케이스에 따라 개별 표출되어야 함 (광고 거절은 사유 볼 수 있고, 대기 중일땐 예상 소요기간, 송출이 시작되면 실시간 광고 표출 볼 수 있도록) */}
        <div className='flex flex-row mt-2'>
          <p className='text-lg font-nexon text-black underline'>실시간 송출 로그도 확인할 수 있어요.</p>
        </div>
      ) : null }

      {payData.status === 'deny' ? (
        // {/* 케이스에 따라 개별 표출되어야 함 (광고 거절은 사유 볼 수 있고, 대기 중일땐 예상 소요기간, 송출이 시작되면 실시간 광고 표출 볼 수 있도록) */}
        <div className='flex flex-row mt-2'>
          <p className='text-lg font-nexon text-rose-700'>거절 사유:</p>
          <p className='text-lg font-nexon text-rose-700 pl-2'>{payData.denyText}</p>
        </div>
      ) : null }

      {payData.status === 'wait' || payData.status === 'acceptShop' ? (
        // {/* 케이스에 따라 개별 표출되어야 함 (광고 거절은 사유 볼 수 있고, 대기 중일땐 예상 소요기간, 송출이 시작되면 실시간 광고 표출 볼 수 있도록) */}
        <p className='text-lg text-black font-nexon pt-0.5'>예상 소요 기간: 1 영업일</p>
      ) : null }

      {payData.status === 'cancel' ? null : (
        <>
          <div className='flex flex-row w-full justify-between mt-12'>
            <p className='text-2xl font-nexonBold text-black self-start'>광고 콘텐츠</p>
          </div>

          <div className="flex flex-row justify-between mt-6">
            <div className="flex flex-col" style={{width: '35%'}}>
              {payData.ctnType === '이미지' ? ( 
                <img className="w-72 h-72 cover-full" src={`${HOST_ADDR.slice(0, -1)}${payData.contentURI}`}/> 
              ) : ( 
                <video controls autoPlay muted loop className="w-72 h-72 cover-full" src={`${HOST_ADDR.slice(0, -1)}${payData.contentURI}`} /> 
              )}
            </div>
            <div className="flex flex-col border-t border-b border-gray-400 justify-evenly" style={{width: '65%'}}>
              <div className="flex flex-row justify-between border-b border-gray-400 py-8">
                <p className='text-xl font-nexon self-center'>콘텐츠명</p>
                <p className='text-xl font-nexon self-center underline'>{payData.contentName}.{payData.extension}</p>                
              </div>
              <div className="flex flex-row justify-between border-b border-gray-400 py-8">
                <p className='text-xl font-nexon self-center'>콘텐츠타입</p>
                <p className='text-xl font-nexon self-center'>{payData.ctnType}</p>
              </div>
              <div className="flex flex-row justify-between py-8">
                <p className='text-xl font-nexon self-center'>해상도</p>
                <p className='text-xl font-nexon self-center'>{payData.resolution}px</p>
              </div>
            </div>
          </div>
        </>
      )}

      {adData.length !== 0 ? (
        <>
          <p className='text-2xl font-nexonBold text-black self-start mt-12 pb-4'>구매 정보</p>
          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col justify-start">
              <p className='text-xl font-nexon self-center text-center'>광고 기간</p>  
            </div>
            <div className="flex flex-col justify-start">
              <p className='text-xl font-nexon self-end text-center'>{adData.length}개월</p>  
              {adData.map((data, idx) => (
                <p key={idx} className='text-xl font-nexon text-black self-end text-center pl-1 py-1'>{data.startPeriod} ~ {data.endPeriod}</p>  
              ))}              
            </div>
          </div>
          <p className='text-xl font-nexon self-start text-center pl-1 pt-2 leading-normal'>일일 120회 송출 보장</p>
          <div className="flex flex-row justify-between">
            <p className='text-xl font-nexon self-start text-center pl-1 py-1 leading-normal'>비용 산정</p>
            <p className='text-xl font-nexon self-start text-center pl-1 py-1 leading-normal'>{adData[0].price.toLocaleString()}원x{adData.length}개월</p>
          </div>

          {/* Divide Line */}
          <div className="border-t-2 border-gray-500 h-1 my-8"/> 

          <div className='flex flex-row w-full justify-between pt-2 mb-4'>
            <p className='text-2xl font-nexonBold self-center text-center'>총 결제금액</p>
            <p className='text-2xl font-nexonBold self-center text-center'>{payData.amount.toLocaleString()}원</p>
          </div>
          <div className='flex flex-row w-full justify-between'>
          <p className='text-2xl font-nexonBold self-center text-center'>결제수단</p>
            <p className='text-2xl font-nexonBold self-center text-center'>신용/체크카드</p>
          </div>
        </>
      ) : null }



    </div> 

    <div className="flex flex-col justify-end self-end flex-grow">
      <Footer props={props}/> 
    </div>
    </> 
  )
}