
import React, {useState} from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
// Configuration 
import configData from 'pages/reactConfig'
// PDF render to generate pdf 
import ReactPDF from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer'
import { Page, Text, View, Document, StyleSheet, Font, PDFDownloadLink, Image } from '@react-pdf/renderer';
import createtw from 'react-pdf-tailwind'
import ScreenAirLogo from '../../static/img/screenair/scaLogo.png'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

export default class Reporting extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      originSchName : props.match.params.originSchName, 
      beginProd :  props.match.params.beginProd,
      endProd :  props.match.params.endProd,
      customSchName : props.match.params.customSchName,
      payPK : props.match.params.payPK, 
      adLog : null, 
      isLoading : true 
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    this.reportGeneration()
  }

  getADLog = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}advertisement/getAdSchedule/allLog/${this.state.originSchName}/${this.state.beginProd}/${this.state.endProd}/${this.state.payPK}/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("로그 데이터 로딩에 실패하였습니다.")
    }
  }

  reportGeneration = async() => {
    const adLogData = await this.getADLog()
    console.log(adLogData)

    if (adLogData['result'] === 'OK'){
      this.setState({adLog : adLogData['data'], isLoading:false})
    }else{
      alert("데이터를 불러오는 도중 에러가 발생하였습니다.")
      return false 
    }
    
    Font.register(
      {
        family: 'SpoqaHanSans',
        fonts: [
          {
            src:'https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf', 
            fontWeight:'light'
          },
          {
            src:'https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf', 
            fontWeight:'bold'
          }, 
          {
            src:'https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf', 
            fontWeight:'normal'
          }, 
        ]
      })

    const tw = createtw({
      theme : {
        fontFamily : { 
          sans: ['SpoqaHanSans'], 
        }, 
        extend:{
          colors:{
            custom : "#bada55", 
          }
        }
      }
    })

    const renderPlayCountPerDate = (playCountPerDate) =>{
      const result = []; 
      let idx = 1; 
      for(let key in playCountPerDate){
        result.push(
          <View style={tw('flex flex-row border-b border-gray-700 justify-between')}>  
            <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
              <Text style={tw('text-lg font-normal ')}>{idx}</Text>
            </View>
            <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
              <Text style={tw('text-lg font-normal')}>{key}</Text>
            </View>
            <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
              <Text style={tw('text-lg font-normal')}>{playCountPerDate[key].toLocaleString('ko-KR')}회</Text>
            </View>
          </View>
        )
        idx++ 
      }
      return result 
    }

    const MyDocument = () => (
      <Document>
        <Page size="A4" title="송출보고서" subject="송출보고서" style={tw('p-12 w-full font-sans bg-white')}>
          <View style={tw('flex flex-col')}>
            {/* Subject */}
            <View style={tw('flex flex-row justify-center')}>  
              <Text style={tw('text-4xl font-bold')}>송출보고서</Text>
            </View>
            {/* Metadata */}
            <View style={tw('flex flex-col justify-center')}>  
              <Image src={ScreenAirLogo} style={tw('w-4/12 h-12 self-end')}/>
              <Text style={tw('text-xl font-bold pb-4 self-end -mt-2')}>주식회사 유니크</Text>
              <Text style={tw('text-xl font-semibold')}>광고명: {this.state.customSchName}</Text>
              <Text style={tw('text-xl font-normal')}>매체명: POP스크린</Text>
              {this.state.adLog.length > 1 ? (
                <>
                <Text style={tw('text-xl font-normal')}>광고 사이트</Text>
                {this.state.adLog.map((data, idx) => (
                  <Text style={tw('text-lg font-normal pl-4')}>- {data.device}</Text>
                ))}
                </>
              ) : (
                <Text style={tw('text-xl font-normal')}>광고 사이트: {this.state.adLog[0].device}</Text>
              )}
              <Text style={tw('text-lg font-normal')}>송출횟수 산정범위: {this.state.beginProd} ~ {this.state.endProd}</Text>
              
            </View>
            
            {/* Data */}
            <View style={tw('flex flex-col justify-center pt-6')}>  
              {/* Total count */}

              {this.state.adLog.length > 1 ? (
                <>
                <Text style={tw('text-xl font-normal')}>1. 사이트별 총 송출 횟수 (최소보장횟수)</Text>
                {this.state.adLog.map((data, idx) => (                  
                  <View style={tw('flex flex-row justify-start pl-4')}>
                    <Text style={tw('text-lg font-normal')}>1.{idx+1}. {data.device}: </Text>
                    <Text style={tw('text-lg font-bold text-green-700')}>{data.totalCount.toLocaleString('ko-KR')}회 (3,600회)</Text>
                  </View>
                ))}
                </>
              ) : (
                <Text style={tw('text-xl font-normal')}>1. 총 송출 횟수 (최소보장횟수): {this.state.adLog[0].totalCount.toLocaleString('ko-KR')}회 (3,600회)</Text>
              )}

              <Text style={tw('text-xl font-normal pt-4')}>2. 일별 송출 횟수</Text>
              {this.state.adLog.map((data, idx) => (
                <>
                {idx === 0 ? <Text style={tw('text-lg font-normal pl-4')}>2.{idx+1}. {data.device}</Text>
                 : <Text style={tw('text-lg font-normal pl-4 pt-4')}>2.{idx+1}. {data.device}</Text>}
                <View style={tw('flex flex-col justify-start border border-gray-700 ml-4')}>
                  {/* 일반 송출 횟수 Table header */}
                  <View style={tw('flex flex-row border-b border-gray-700 justify-between')}>  
                    <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
                      <Text style={tw('text-lg font-normal ')}>번호</Text>
                    </View>
                    <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
                      <Text style={tw('text-lg font-normal')}>송출일</Text>
                    </View>
                    <View style={tw('flex flex-row w-4/12 border-r border-gray-700 justify-center')}>
                      <Text style={tw('text-lg font-normal')}>송출횟수</Text>
                    </View>
                  </View>

                  {/* 일반 송출 횟수 Table body */}
                  {renderPlayCountPerDate(data.dateCount)}
                </View>
                </> 
              ))}
            </View>
          </View>
        </Page>
      </Document>
    );
    
    const App = () => ( 
      // <PDFViewer title="송출보고서.pdf" filename="송출보고서.pdf" style={tw('w-full h-full'), {'width':window.innerWidth, 'height':window.innerHeight}}>
      //   <MyDocument /> 
      // </PDFViewer>
      <PDFDownloadLink document={<MyDocument />} title="송출보고서.pdf" fileName={this.state.customSchName+"_송출보고서.pdf"}>
        {({ blob, url, loading, error }) => (
          loading ? 
          <div className='w-full h-screen flex flex-col justify-center'>
            <svg class="animate-spin h-12 w-12 text-rose-500 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <p className='text-lg font-semibold antialiased self-center text-center pt-4'>PDF 문서 생성 중...</p>
          </div> 
          : 
          <div className='w-full h-screen flex flex-col justify-center'>
            <div className='flex flex-row justify-center'>
              <div className='flex flex-row w-1/12 justify-center bg-indigo-500 rounded-lg'>
                <p className='text-lg font-semibold antialiased self-center text-center px-2 py-0.5 text-white'>보고서 다운로드</p>
              </div>
            </div>
          </div> 
          )
        }
        {/* 모든 보고서 정보를 다 수신하였으니 조금만 기다려달라는 UI 반영할 것 */}
        {/* 샘플로 아무 데이터나 넣어서 pdf 생성하게끔 하고 download가 어떻게 진행되는지 볼 것 */}
      </PDFDownloadLink>
    )

    ReactDOM.render(<App />, document.getElementById('root')); 
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? (
        <div className='w-full h-screen flex flex-col justify-center'>
          <svg class="animate-spin h-12 w-12 text-rose-500 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <p className='text-lg font-semibold antialiased self-center text-center pt-4'>조금 오랜 시간이 소요될 예정입니다...🤔</p>
          <p className='text-lg font-semibold antialiased self-center text-center'>해당 페이지를 유지한채 커피 한잔 하고 오시길 권장드립니다 🥺</p>
        </div> 
      ) : 
      <>
      </>
      }
      </>
    )
  }
}