import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster } from 'react-hot-toast';

export default function PartnerReq(props){
  const {history} = props
  const inputRefArr = useRef([])
  const submitEdit = (e, idx) => {
    if (e.key === 'Enter'){
      inputRefArr.current[idx+1].focus()
    }
  }  

  const isNum = (val) => {
    return !isNaN(val)
  }

  const check = async() => {
    const companyName = inputRefArr.current[0].value 
    const phone = inputRefArr.current[1].value 
    const bizNumber = inputRefArr.current[2].value 
    const address = inputRefArr.current[3].value 
    const count = inputRefArr.current[4].value 
    const inquiry = inputRefArr.current[5].value 

    if (companyName === null || companyName === '' || companyName === undefined){
      const error = () => toast.error('상호명을 입력해주세요.') 
      error()       
      return false 
    }

    if (phone === null || phone === '' || phone === undefined){
      const error = () => toast.error('연락처를 입력해주세요.') 
      error()       
      return false 
    }

    if(!isNum(phone)){
      const error = () => toast.error("연락처에는 '-'을 뺴고 숫자만 입력해주세요.") 
      error()       
      return false 
    }

    if (bizNumber === null || bizNumber === '' || bizNumber === undefined){
      const error = () => toast.error('사업자등록번호를 입력해주세요.') 
      error()       
      return false 
    }

    if(!isNum(bizNumber)){
      const error = () => toast.error("사업자등록번호에는 '-'을 뺴고 숫자만 입력해주세요.") 
      error()       
      return false 
    }

    if (address === null || address === '' || address === undefined){
      const error = () => toast.error('매장 주소를 입력해주세요.') 
      error()       
      return false 
    }

    if (count === null || count === '' || count === undefined){
      const error = () => toast.error('매장 평균 방문 고객 수를 입력해주세요.') 
      error()       
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/shops/reqPartner/`
    const data = {
      shopName : companyName,
      bizRegNumber : bizNumber, 
      shopAddr : address, 
      shopAddr2 : null, 
      mobile : phone, 
      visitPerCnt : count, 
      inqText : inquiry, 
    }

    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        history.push('/service/partnerReq/success/', {})
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  return(
    <>
      {/* Toast */}
      <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
      {/* Header */}
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      

      {/* Divide Line */}
      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-24 pt-12">
        <div className="flex flex-row justify-between gap-x-8">
          <div className="flex flex-row justify-start w-3/5">
            <div className="flex flex-col justify-start flex-grow pt-24">
              <p className="text-xl font-nexonBold text-black self-start">스크린에어 제휴 입점 안내</p>
              <p className="text-base font-nexon text-black self-start pt-4">스크린에어의 제휴사가 되어 무료로 홍보매체도 구축하고 광고 수익을 창출하세요!</p>

              <p className="text-xl font-nexonBold text-black self-start pt-8">스크린에어가 무엇인가요?</p>
              <p className="text-base font-nexon text-black self-start pt-2 w-4/5">제휴사 매장에 디지털 사이니지를 무료로 구축해드리고 사이니지 영역의 70%에는 매장의 홍보 콘텐츠를 표출하고 나머지 30% 영역에는 광고를 표출할 수 있도록 중개하는 플랫폼 서비스 입니다.</p>

              <p className="text-xl font-nexonBold text-black self-start pt-8">제휴사 혜택</p>
              <ul className="list-decimal pt-2 pl-6">
                <li className="text-base font-nexon text-black self-start">스크린에어 홍보 매체인 32인치 디지털 사이니지를 무료로 제공해드립니다.</li>
                <li className="text-base font-nexon text-black self-start">홍보에 필요한 콘텐츠를 최대 2개까지 무료로 제작해드립니다.</li>
                <li className="text-base font-nexon text-black self-start">유치되는 광고에 대한 수익도 분배해드립니다.</li>
              </ul>

              <p className="text-xl font-nexonBold text-black self-start pt-8">정말로 어떤 비용도 발생하지 않나요?</p>
              <p className="text-base font-nexon text-black self-start pt-4">그렇습니다 😄<br/>매체 제공부터 설치까지 발생하는 모든 비용은 스크린에어가 부담합니다.</p>

              <p className="text-xl font-nexonBold text-black self-start pt-8">제휴사의 역할</p>
              <ul className="list-decimal pt-2 pl-6">
                <li className="text-base font-nexon text-black self-start">유치된 광고에 대한 사진 촬영(증빙용)</li>
                <li className="text-base font-nexon text-black self-start">광고 요청 발생 시 광고 콘텐츠 검토 및 승인 결정</li>
              </ul>

              <p className="text-xl font-nexonBold text-black self-start pt-8">제휴 입점 조건</p>
              <p className="text-base font-nexon text-black self-start pt-2">카페를 운영하는 개인사업자 또는 프랜차이즈 기업</p>

            </div>
          </div>

          <div className="flex flex-row justify-start w-2/5">
            <div className="flex flex-col flex-grow rounded-lg border-2 border-gray-300 justify-start p-6 shadow-lg">
              <p className="text-xl font-nexonBold text-black self-center">스크린에어 제휴 신청하기</p>
              <div className="flex flex-row justify-start mt-12 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">상호명(법인명)</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='text' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="상호명을 입력해주세요." ref={(ref) => inputRefArr.current[0] = ref} onKeyPress={(e) => submitEdit(e, 0)} /> 

              <div className="flex flex-row justify-start mt-8 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">연락처</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='tel' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="연락처를 입력해주세요. ('-'없이)" maxLength={11} ref={(ref) => inputRefArr.current[1] = ref} onKeyPress={(e) => submitEdit(e, 1)} /> 

              <div className="flex flex-row justify-start mt-8 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">사업자등록번호</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='tel' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="사업자등록번호를 입력해주세요. ('-'없이)" maxLength={10} ref={(ref) => inputRefArr.current[2] = ref} onKeyPress={(e) => submitEdit(e, 2)} /> 

              <div className="flex flex-row justify-start mt-8 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">매장 주소</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='text' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="매장 주소를 입력해주세요."  ref={(ref) => inputRefArr.current[3] = ref} onKeyPress={(e) => submitEdit(e, 3)} /> 

              <div className="flex flex-row justify-start mt-8 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">매장 평균 방문 고객 수</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='text' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="3000~4000명"  ref={(ref) => inputRefArr.current[4] = ref} onKeyPress={(e) => submitEdit(e, 4)} /> 

              <p className="text-lg font-nexon text-black self-start pt-8">요청 또는 문의 사항 </p>
              <textarea rows={5} type='text' className="border-2 border-gray-300 rounded-lg text-black font-nexon pl-2 mt-2 h-48 text-left"
              placeholder="입점 심사와 관련하여 요청 사항이나 문의 사항이 있으시면 자유롭게 기재해주세요."   ref={(ref) => inputRefArr.current[5] = ref}/> 

              <div className="flex flex-row justify-center rounded-lg bg-rose-500 cursor-pointer mt-4 py-2" onClick={() => check()}>
                <p className="text-lg font-nexon text-white self-center">제휴 신청하기</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="flex flex-col justify-end self-end flex-grow">
        <Footer props={props}/> 
      </div>
    </>
  )
}