import React, { Fragment, useState, useCallback, createRef } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ReactDOM } from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { CheckIcon} from '@heroicons/react/solid'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ko'
import Select from 'react-select'
import _, { constant } from 'lodash'
import { DropzoneComponent } from 'react-dropzone-component'
import 'dropzone/dist/dropzone.css'
import 'react-dropzone-component/styles/filepicker.css'
import Dropzone from 'react-dropzone'
import Notification from 'pages/util/notification'
import configData from 'pages/reactConfig'
import DatePicker from 'react-datepicker'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class AdReportMain extends React.Component{
  constructor(props){
    super(props);
    this.serverAddr = configData['SERVER_URL']
    this.state = { 
      isLoading : true, 
      adList : null, 
      selectedAd : {}, 
      nextBtnRef : React.createRef(), 
      nextBtnActStatus : false, 
      firstPhaseUI : React.createRef(), 
      secondPhaseUI : React.createRef(), 
      width: window.innerWidth, 
      height: window.innerHeight, 

      // Second phase 
      secondStartDate: new Date(), 
      secondEndDate: new Date(),
      secondMaximumEndDate : null, 
      firstProgressSvgRef : React.createRef(null),
      secondScheduleInputRef : React.createRef(null), 
      atagToMoveRef : React.createRef(null), 
    }
    const refList = React.createRef([])
  }

  async componentDidMount(){
    const result = await this.getAdSchedule()
    if (result['result'] === 'OK'){
      let adListData = result['data']
      adListData.forEach(element => {
        element['ref'] = React.createRef()
      });
      this.setState({isLoading : false, adList : adListData})
    }else{
      alert("데이터를 불러오는 도중 오류가 발생하였습니다.")
    }
  }

  async getAdSchedule(){
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}advertisement/getADSchedule`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("송출 기록 로딩에 실패하였습니다.")
    }
  }

  scheduleClick(idx){
    const adListLen = this.state.adList.length 
    const clickMarkRef = this.state.adList[idx]['ref']

    for(let i = 0; i < adListLen; i++){
      if (i !== idx){
        this.state.adList[i].ref.current.classList.add('hidden')
      }else{
        this.state.adList[i].ref.current.classList.remove('hidden')
      }
    }

    this.state.nextBtnRef.current.classList.replace('bg-gray-500', 'bg-indigo-500')

    this.setState({
      selectedAd : this.state.adList[idx], 
      nextBtnActStatus : true 
    })
  }

  nextBtnClick(){
    if (this.state.nextBtnActStatus === false){
      return false
    }else{
      this.nextPhase()
    }
  }

  async nextPhase(){

    this.state.firstProgressSvgRef.current.classList.remove('hidden')

    const result = await this.getMaximumDate() 

    if(result['result'] === 'OK'){
      if (result.data.length === 0){
        alert("로그 데이터가 비어있습니다.")
        return false 
      }
      this.setState({secondMaximumEndDate : result['data'], secondStartDate : new Date(this.state.selectedAd.startDateTime), secondEndDate: new Date(result['data'])})
      this.state.firstPhaseUI.current.style.transform = "translateX(" +"-"+this.state.width+"px)"; 
      this.state.firstPhaseUI.current.classList.add('hidden')
      this.state.secondPhaseUI.current.classList.remove('hidden')
      setTimeout(() =>{
        this.state.secondPhaseUI.current.style.transform = "translateX( 0px)"
      },150)
    }else{
      alert("데이터를 불러오는 도중 에러가 발생하였습니다.")
      return false 
    }
  }

  async getMaximumDate(){
    console.log(this.state.selectedAd)
    const scheduleName = this.state.selectedAd.advertisement
    let payPK = this.state.selectedAd.payPK 
    if (payPK === null){
      payPK = 'null'
    }
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}advertisement/getADSchedule/maximum/${scheduleName}/${payPK}/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("최대 일자 로딩에 실패하였습니다.")
    }
  }

  moveToPDF = () =>{
    let payPK = this.state.selectedAd.payPK 
    if (payPK === null){
      payPK = 'null'
    }

    const manualScheduleName = this.state.secondScheduleInputRef.current.value 
    const originSchName = this.state.selectedAd.advertisement
    let beginProd; 
    let endProd; 
    
    if(this.state.secondStartDate.getMonth()+1 < 10){
      beginProd = this.state.secondStartDate.getFullYear() + '-' + '0'+parseInt(this.state.secondStartDate.getMonth()+1)
    }else{
      beginProd = this.state.secondStartDate.getFullYear() + '-' + parseInt(this.state.secondStartDate.getMonth()+1)
    }

    if(this.state.secondStartDate.getDate() < 10){
      beginProd += '-' + '0' + this.state.secondStartDate.getDate()
    }else{
      beginProd += '-' + this.state.secondStartDate.getDate()
    }

    if(this.state.secondEndDate.getMonth()+1 < 10){
      endProd = this.state.secondEndDate.getFullYear() + '-' + '0'+parseInt(this.state.secondEndDate.getMonth()+1)
    }else{
      endProd = this.state.secondEndDate.getFullYear() + '-' + parseInt(this.state.secondEndDate.getMonth()+1)
    }

    if(this.state.secondEndDate.getDate() < 10){
      endProd += '-' + '0' + this.state.secondEndDate.getDate()
    }else{
      endProd += '-' + this.state.secondEndDate.getDate()
    }

    const URI = `/reporting/${originSchName}/${beginProd}/${endProd}/${manualScheduleName}/${payPK}`

    this.state.atagToMoveRef.current.href=URI
    this.state.atagToMoveRef.current.click() 
  }

  makeDetailReport = () =>{
    let payPK = this.state.selectedAd.payPK 
    if (payPK === null){
      payPK = null 
    }
    const manualScheduleName = this.state.secondScheduleInputRef.current.value 
    const originSchName = this.state.selectedAd.advertisement
    let beginProd; 
    let endProd; 
    
    if(this.state.secondStartDate.getMonth()+1 < 10){
      beginProd = this.state.secondStartDate.getFullYear() + '-' + '0'+parseInt(this.state.secondStartDate.getMonth()+1)
    }else{
      beginProd = this.state.secondStartDate.getFullYear() + '-' + parseInt(this.state.secondStartDate.getMonth()+1)
    }

    if(this.state.secondStartDate.getDate() < 10){
      beginProd += '-' + '0' + this.state.secondStartDate.getDate()
    }else{
      beginProd += '-' + this.state.secondStartDate.getDate()
    }

    if(this.state.secondEndDate.getMonth()+1 < 10){
      endProd = this.state.secondEndDate.getFullYear() + '-' + '0'+parseInt(this.state.secondEndDate.getMonth()+1)
    }else{
      endProd = this.state.secondEndDate.getFullYear() + '-' + parseInt(this.state.secondEndDate.getMonth()+1)
    }

    if(this.state.secondEndDate.getDate() < 10){
      endProd += '-' + '0' + this.state.secondEndDate.getDate()
    }else{
      endProd += '-' + this.state.secondEndDate.getDate()
    }

    beginProd += ' 00:00:00'
    endProd += ' 23:59:59'

    const userToken = UserConfig.getAccKey()

    this.state.atagToMoveRef.current.href=`${HOST_ADDR}advertisement/getAdSchedule/allLogDetailed/${originSchName}/${beginProd}/${endProd}/${userToken}/${payPK}/`; 
    this.state.atagToMoveRef.current.click() 
  }

  render(){
    const isLoading = this.state.isLoading
    return(
      <>
      {isLoading === true ? "" : (
        <>
        {/* // First Phase UI  */}
        <div className='flex flex-col bg-white mt-4 p-6 rounded-lg w-full transition-all ease-in-out duration-150' ref={this.state.firstPhaseUI}>
          <div className='flex flex-row justify-between'>
            {/* title & subtitle */}
            <div className='flex flex-col justify-center'>
              <span className='text-lg font-semibold antialiased self-start text-black'>광고 스케줄 선택하기</span>
              <span className='text-base font-normal antialiased self-start text-gray-600'>송출 보고서를 생성할 광고 스케줄을 선택해주세요</span>
            </div>
            {/* Progress */}
            <div className='flex flex-row justify-center'>
              <div className='rounded-full bg-rose-500 w-4 h-4 self-center'/> 
              <div className='border-t-2 border-gray-300 h-0.5 w-4 self-center'/> 
              <div className='rounded-full bg-gray-500 w-4 h-4 self-center'/> 
            </div>
          </div>

          <div className='flex flex-row w-full justify-end pt-4 pb-2'>
            <div className='flex flex-row bg-gray-500 rounded-lg' ref={this.state.nextBtnRef}>
              <button className='text-lg font-semibold antialiased self-center text-white px-3 py-1' onClick={() => this.nextBtnClick()}>다음으로</button>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white self-center hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.state.firstProgressSvgRef} >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>

          {/* Schedule card list */}
          <div className='grid sm:grid-cols-1 lg:grid-cols-4 gap-4 pt-4'>
            {this.state.adList.map((data, idx) => (
              <div className='flex flex-row justify-between border-2 border-gray-400 rounded-lg py-4 px-2' onClick={() => this.scheduleClick(idx)}>
                <div className='flex flex-col justify-start w-10/12'>
                  <span className='text-base font-semibold antialiased self-start text-black pb-2'>광고 스케줄: {data.advertisement}</span>
                  <span className='text-sm font-semibold antialiased self-start text-rose-500'>기간: {data.startDateTime}~{data.endDateTime}</span>
                  <span className='text-sm font-semibold antialiased self-start text-indigo-600'>콘텐츠: {data.contents[0]} {data.contents.length > 1 ? ("외 "+(data.contents.length-1)+"개"):""} </span>
                  <span className='text-sm font-semibold antialiased self-start text-indigo-600'>운영매장: {data.devices[0]} {data.devices.length > 1 ? ("외 "+(data.devices.length-1)+"개의 매장"):""} </span>
                </div>
                <div className='flex flex-col justify-center w-2/12 hidden' ref={data.ref}>
                  <CheckIcon className='text-indigo-500 self-center w-7 h-7'/>
                </div>
                
              </div>
            ))}

          </div>
        </div>

        {/* Second phase */}
        <div className='flex flex-col bg-white mt-4 p-6 rounded-lg w-full transition-all ease-in-out duration-150 hidden' style={{transform: 'translateX( +'+this.state.width+"px)"}} ref={this.state.secondPhaseUI}>
          <div className='flex flex-row justify-between'>
            {/* title & subtitle */}
            <div className='flex flex-col justify-center'>
              <span className='text-lg font-semibold antialiased self-start text-black'>기간 설정하기</span>
              <span className='text-base font-normal antialiased self-start text-gray-600'>송출 보고서의 기간을 설정해주세요.</span>
            </div>
            {/* Progress */}
            <div className='flex flex-row justify-center'>
              <div className='rounded-full bg-rose-500 w-4 h-4 self-center'/> 
              <div className='border-t-2 border-gray-300 h-0.5 w-4 self-center'/> 
              <div className='rounded-full bg-rose-500 w-4 h-4 self-center'/> 
            </div>
          </div>

          <div className='flex flex-col sm:flex-col lg:flex-row w-full'>
            <div className='flex flex-col justify-start w-full sm:w-full lg:w-6/12'>
              <span className='text-lg font-semibold antialiased self-start text-black pt-4 pb-1'>광고 스케줄</span>
              <input type='text' placeholder={this.state.selectedAd.advertisement} defaultValue={this.state.selectedAd.advertisement} className='text-base font-semibold antialiased self-start text-black rounded-lg w-full md:w-5/12' ref={this.state.secondScheduleInputRef}/> 
              <span className='text-sm font-semibold antialiased self-start text-rose-500'>보고서에 나타낼 스케줄 명을 변경할 수 있습니다.</span>
              <span className='text-base font-normal antialiased self-start text-black pt-4'>광고 기간: {this.state.selectedAd.startDateTime}~{this.state.selectedAd.endDateTime}</span>

              {this.state.nextBtnActStatus === true ? (
                (this.state.selectedAd.contents.length === 1 ? (
                  <span className='text-base font-normal antialiased self-start text-black'>콘텐츠: {this.state.selectedAd.contents[0]}</span>
                ) :(
                  <div className='flex flex-col justify-center'>
                    <span className='text-lg font-normal antialiased self-start text-black'>콘텐츠리스트</span>
                    <ul className='list-inside list-disc pl-3'>
                      {this.state.selectedAd.contents.map((content) =>(
                        <li>
                          {content}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : null}

              {this.state.nextBtnActStatus === true ? (
                (this.state.selectedAd.devices.length === 1 ? (
                  <span className='text-base font-normal antialiased self-start text-black'>운영매장: {this.state.selectedAd.devices[0]}</span>
                ) :(
                  <div className='flex flex-col justify-center'>
                    <span className='text-lg font-normal antialiased self-start text-black'>매장 리스트</span>
                    <ul className='list-inside list-disc pl-3'>
                      {this.state.selectedAd.devices.map((content) =>(
                        <li>
                          {content}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : null}
            </div>

            <div className='flex flex-col justify-start w-full sm:w-full lg:w-6/12'>
              <span className='text-lg font-semibold antialiased self-start text-black pt-4'>시작일자</span>
              {this.state.nextBtnActStatus === true ? (
              <DatePicker 
                selected={this.state.secondStartDate}
                onChange={(date) => this.setState({secondStartDate:new Date(date)})}
                dateFormat="yyyy-MM-dd(EE) 부터"
                name="startDate"
                minDate={new Date(this.state.selectedAd.startDateTime)}
                maxDate={new Date(this.state.secondMaximumEndDate)}
                className='mt-2 block w-full md:w-6/12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'/>
              ) : null }

              <span className='text-lg font-semibold antialiased self-start text-black pt-4'>종료일자</span>
              {this.state.nextBtnActStatus === true ? (
              <DatePicker 
                selected={this.state.secondEndDate}
                onChange={(date) => this.setState({secondEndDate:new Date(date)})}
                dateFormat="yyyy-MM-dd(EE) 까지"
                name="endDate"
                minDate={new Date(this.state.selectedAd.startDateTime)}
                maxDate={new Date(this.state.secondMaximumEndDate)}
                className='mt-2 block w-full md:w-6/12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'/>
              ) : null }

              {this.state.secondMaximumEndDate !== null ? (
                <span className='text-sm font-semibold antialiased self-start text-rose-500'>현재까지 진행된 마지막 광고 일자: {this.state.secondMaximumEndDate}</span>
              ) : null }


              <div className='flex flex-row w-full justify-start pt-4'>
                <div className='flex flex-row bg-indigo-500 rounded-lg'>
                  <button className='text-lg font-semibold antialiased self-center text-white px-3 py-1' onClick={() => this.moveToPDF()}>보고서 생성하기</button>
                </div>
              </div>

              <div className='flex flex-row w-full justify-start pt-2 pb-2'>
                <div className='flex flex-row bg-indigo-500 rounded-lg'>
                  <button className='text-lg font-semibold antialiased self-center text-white px-3 py-1' onClick={() => this.makeDetailReport()}>상세 로그 리포트 생성</button>
                </div>
              </div>

            </div>
          </div>
          
          {/* a tag to generate PDF */}
          <a href="#" target="_blank" className='hidden' ref={this.state.atagToMoveRef}/> 
        </div>
        </>
      )}
      </>
    )
  }
}