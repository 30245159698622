
import { CogIcon, CheckIcon } from "@heroicons/react/outline"
import { ExclamationIcon } from "@heroicons/react/solid"
import React, { createRef, useRef } from "react"
import ReactDatePicker from "react-datepicker"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { ko } from 'date-fns/esm/locale'
import DatePickerCustom from './datepicker/datepicker.js'
import axios from 'axios';
import _ from "lodash"
import moment from "moment"
import Notification from 'pages/util/notification'
import configData from 'pages/reactConfig'
import {HOST_ADDR, SERVER_URL} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function modular(index){
  if(index % 3 === 0 || index === 0){
    return true; 
  }else{
    return false; 
  }
}

export default class ScheduleReg extends React.Component{
  constructor(props){
    super(props); 
    this.serverAddr = configData['SERVER_URL']
    this.state = { 
      startDate: new Date(), 
      endDate: new Date(), 
      fileCounter: 0, 
      isLoading : true, 
      screenList : null, 
      contentList : null, 
      host : this.serverAddr+"/media/",
      dateInputRef : React.createRef(null),
      startSchedule : React.createRef(null), 
      endSchedule : React.createRef(null), 
      ctnSelectCount : 1, 
      ctnSpanRef : React.createRef(null), 
      notiShow : false, 
      notiSubject : null, 
      notiText : null, 
      notiType : 'success'
    }
    this.scheduleName = React.createRef(null) 
    this.adCountRef = React.createRef(null)
    this.adLocation= React.createRef(null) 
    this.adLogActive = React.createRef(null) 
    this.adPrice = React.createRef(null) 
    this.handleChange = this.handleChange.bind(this); 
    this.endHandleChange = this.endHandleChange.bind(this); 
    this.devHandleChange = this.devHandleChange.bind(this); 
    this.ctnHandleChange = this.ctnHandleChange.bind(this); 
    this.format = "HH:mm" 
  }

  componentDidMount(){
    this.devCtnUpdate() 
  }

 
  devCtnUpdate = async(notiShow=false, notiSubject=null, notiText=null, notiType='success') => {
    const {data, isLoading, error} = await this.getDevCtn(); 
    if(isLoading){

    }else if(error){

      // Need to alert and redirect function 
    }else if(data){
      this.setState({isLoading:true})

      let screenList = data['screenList']; 
      let contentList = data['contentList']; 
      let id =0, ctnid = 0; 
      screenList.forEach(element=>{
        element['id'] = id; 
        element['ref'] = React.createRef(); 
        element['status'] === null ? element['status'] = 'offline' : element['status'] = 'online'; 
        'fromEmail' in element ? element['fromEmail'] = element['fromEmail'] : element['fromEmail'] = null 
        'fromName' in element ? element['fromName'] = element['fromName'] : element['fromName'] = null 
        id++; 
      })
      contentList.forEach(element=>{
        element['id'] = ctnid; 
        element['ref'] = React.createRef(); 
        element['contentSrc'] = this.state.host + element['contentSrc']; 
        element['spanRef'] = React.createRef(); 
        ctnid++; 
      })
      this.setState({screenList:screenList, contentList:contentList, isLoading:false, notiShow:notiShow, notiSubject:notiSubject, 
                    notiText:notiText, ctnSelectCount:1, startDate:new Date(), endDate:new Date()})
    }
  }

  truncate = (str) =>{
    str = String(str); 
    return str.length > 20 ? str.substring(0, 20) + "..." : str; 
  }

  getDevCtn = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}schedule/getDevCtnAd/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("데이터를 불러오는 중 에러가 발생하였습니다.\n관리자에게 문의하여 주십시오.")
    }
  }

  devHandleChange(ref){
    console.log(ref) 
    if (ref.current.className === 'flex flex-col justify-center mr-2'){
      ref.current.className = 'flex flex-col justify-center hidden mr-2'
      ref.current.name = ""
    }else{
      ref.current.className = 'flex flex-col justify-center mr-2'
      ref.current.name="selected"
    }
  }

  ctnHandleChange(ref, spanRef){
    let ctnCount = _.cloneDeep(this.state.ctnSelectCount)
    if (ref.current.className === 'z-10 w-full h-full flex flex-row justify-center bg-gray-600 opacity-70'){
      ref.current.className = 'z-10 flex flex-row justify-center hidden w-full h-full bg-gray-600 opacity-70'
      ref.current.name = ""
      ctnCount -= 1
      this.setState({ctnSelectCount : ctnCount})
      spanRef.current.innerHTML = null
      spanRef.current.value = null
    }else{
      ref.current.className = 'z-10 flex flex-row justify-center w-full h-full bg-gray-600 opacity-70'
      ref.current.name="selected"
      ctnCount += 1
      this.setState({ctnSelectCount : ctnCount})
      spanRef.current.innerHTML = this.state.ctnSelectCount+' 번째'
      spanRef.current.value = this.state.ctnSelectCount
    }
  }

  handleChange(date){
    console.log(date) 
    console.log(date.toString()) 
    this.setState({
      startDate:date
    }, ()=>{
      console.log('startDate') 
      console.log(this.state.startDate)
    })
  }

  endHandleChange(date){
    console.log(date) 
    this.setState({
      endDate: date
    }, ()=>{
      console.log(this.state.endDate)
    })
  }

  oneMonthChecking = (e, ref) =>{
    let startDateTemp = _.cloneDeep(this.state.startDate)
    let endDateTemp = _.cloneDeep(this.state.endDate)

    if (e.target.checked === false){
      this.setState({startDate : new Date(), endDate : new Date()})
    }else{
      startDateTemp.setHours(0)
      startDateTemp.setMinutes(0)
      endDateTemp.setHours(0)
      endDateTemp.setMinutes(0)
      endDateTemp.setMonth(new Date().getMonth()+1)
      this.setState({startDate : startDateTemp, endDate : endDateTemp})
    }
  }

  adCount = (e) =>{
    if (e.target.checked === true){
      this.adCountRef.current.value = 60 
    }else{
      this.adCountRef.current.value = null
    }
  }

  adScheduleSubmit = async () =>{
    const name = this.scheduleName.current.value 
    const startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD')
    const scrList = _.cloneDeep(this.state.screenList)
    const ctnlist = _.cloneDeep(this.state.contentList)
    const locationValue = this.adLocation.current.value 
    const adLogActive = this.adLogActive.current.value 
    const adPrice = this.adPrice.current.value 
    let screenList = Array() 
    let contentList = Array() 

    if (name === undefined || name === '' || name === null){
      alert("광고 이름을 등록해주세요")
      return false 
    }

    if (adPrice === undefined || adPrice === '' || adPrice === null){
      alert("광고 비용을 등록해주세요")
      return false 
    }

    if (name === undefined || name === '' || name === null){
      alert("광고 이름을 등록해주세요")
      return false 
    }

    scrList.forEach(element=>{
      if (element['ref'].current.name === 'selected'){
        screenList.push(element['idx']) 
      }
    })

    ctnlist.forEach(element=>{
      if (element['ref'].current.name === 'selected'){
        let sequence = element['spanRef'].current.value 
        let ctn = {'idx' : element['idx'], 'seq' : sequence}
        contentList.push(ctn)
      }
    })

    if (screenList.length === 0){
      alert("스크린을 선택해주세요")
      return false 
    }
    if (contentList.length === 0){
      alert("콘텐츠를 선택해주세요")
      return false 
    }

    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}advertisement/v2/reg/`
    const data = { advertisement:name, startDate: startDate, endDate: endDate, scrList: screenList, ctnList : contentList, adPrice : adPrice, adLogActive: adLogActive, location: locationValue }
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    
    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        this.devCtnUpdate(true, "광고 등록 알림", "광고 일정이 성공적으로 등록되었습니다.")
      }
    }catch(e){
      alert("광고 등록에 실패하였습니다.")
    }
  }

  render(){
    const isLoading = this.state.isLoading; 
    return(
      <>
      {isLoading ? "" : 
        <div className='mt-4 overflow-y-hidden h-2/6'>
        <Notification show={this.state.notiShow} subject={this.state.notiSubject} text={this.state.notiText} type={this.state.notiType}/> 
        {/* Top Info in container */}
        <div className="p-4 mb-4 border-l-4 border-indigo-400 bg-blue-50">
          <div className="flex flex-col justify-between md:flex-row gap-y-2 md:gap-y-0">
            <div className='flex flex-row items-center'> 
              <div className="flex-shrink-0">
                <ExclamationIcon className="w-5 h-5 text-indigo-400" aria-hidden="true" />
              </div>
              <div className='ml-2'> 
                <p className="text-xs text-blue-700 md:text-sm">
                  스케즐 정보를 입력하신 후{' '}
                  <a href="#" className="font-medium text-blue-700 underline hover:text-blue-600">
                    매장과 콘텐츠를 선택한 뒤 등록하기 버튼을 눌러주세요 :) 
                  </a>
                </p>
              </div> 
            </div> 
            <div className='flex items-center pl-6 md:pl-0'>
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-800 border border-transparent rounded-md shadow-sm hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900"
                onClick={this.adScheduleSubmit}>
                등록하기 
              </button>
            </div> 
          </div>
        </div>
        {/* main grid-3 */}
        <div className='overflow-y-hidden h-2/6'>
          <div className="overflow-y-auto shadow sm:rounded-md md:overflow-hidden h-5/6">
            <div className="h-full px-4 py-6 bg-white sm:p-6">
              <div className='flex flex-col w-full h-108'>
                <div className='flex flex-col w-full h-full overflow-y-auto md:grid md:grid-cols-3 md:gap-3 gap-y-8 md:overflow-y-hidden'>
                  <div className='flex flex-col'>
                    <div className='flex flex-row'>
                      <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                        Step 1. 광고 정보 등록  
                      </h2>
                    </div> 
                    <p className="mt-1 text-sm text-gray-500">
                      광고를 등록하기 위해 아래 폼을 작성해주세요 :) 
                    </p>
                    <div className='mt-4'>
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        광고 이름 
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="cc-given-name"
                        placeholder="광고의 이름을 알려주세요 :)"
                        ref={this.scheduleName}
                        className="block w-11/12 px-3 py-2 mt-2 text-sm border border-gray-300 rounded-md shadow-sm md:w-9/12 focus:outline-none focus:ring-gray-900 focus:border-gray-900"
                        required/>
                    </div>
                    <div className='mt-4'>
                      <div className='flex flex-row justify-between w-11/12 md:w-9/12'>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          시작 일정 
                        </label>
                        <div className='flex flex-row justify-center gap-1 align-middle'>
                          <input type="checkbox" 
                              class="form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                              ref={this.state.dateInputRef}
                              onChange={(e) => this.oneMonthChecking(e, this.state.indefiniteCheck)}
                              />
                          <label htmlFor="first-name" className="block mr-1 text-sm font-medium text-gray-700">
                            1개월
                          </label>
                        </div>
                      </div>
                      <DatePicker selected={this.state.startDate}
                        onChange={(this.handleChange)}
                        ref={this.state.startSchedule}
                        dateFormat="yyyy-MM-dd(EE) a hh:mm 부터"
                        name="startDate"
                        showTimeSelect
                        locale={ko}
                        className='block w-11/12 px-3 py-2 mt-2 border border-gray-300 rounded-md shadow-sm md:w-9/12 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm' />
                    </div>
                    <div className='mt-4'>
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        종료 일정
                      </label>
                      <DatePicker selected={this.state.endDate}
                        onChange={(this.endHandleChange)}
                        ref={this.state.endSchedule}
                        dateFormat="yyyy-MM-dd(EE) a hh:mm 까지"
                        name="endDate"
                        showTimeSelect
                        locale={ko}
                        className='block w-11/12 px-3 py-2 mt-2 border-gray-300 rounded-md shadow-sm md:w-9/12 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'/>
                    </div>

                    {/* marking  */}
                    <div className='mt-4'>
                      <div className='flex flex-row justify-between w-9/12 gap-1 align-middle'>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          광고 로그 수집 여부
                        </label>
                      </div>
                      <select className='flex flex-row w-11/12 py-2 mt-2 text-sm border-gray-300 rounded-lg md:w-9/12 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                              ref={this.adLogActive}>
                        <option value="false">수집 안함</option>
                        <option value="true">수집</option>
                      </select>
                    </div>
                    <div className='mt-4'>

                      <div className='flex flex-row justify-between w-9/12 gap-1 align-middle'>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          광고 비용
                        </label>
                      </div>

                      <input
                        type="number"
                        name="first-name"
                        id="first-name"
                        autoComplete="cc-given-name"
                        placeholder="27500"
                        ref={this.adPrice}
                        defaultValue={0}
                        className="block w-11/12 px-3 py-2 mt-2 text-sm border border-gray-300 rounded-md shadow-sm md:w-9/12 focus:outline-none focus:ring-gray-900 focus:border-gray-900"
                        required/>

                    </div>
                    <div className='mt-4'>
                      <div className='flex flex-row justify-between w-9/12 gap-1 align-middle'>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          광고 송출 위치
                        </label>
                      </div>
                      {/* marking */}
                      <select className='flex flex-row w-11/12 py-2 mt-2 text-sm border-gray-300 rounded-lg md:w-9/12 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                              ref={this.adLocation}>
                        <option value="vertical">우측 배너</option>
                        <option value="horizontal">하단 배너</option>
                      </select>
                    </div>
                  </div> 
                  <div className='flex flex-col h-108'>
                    <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                      Step 2. 스크린 선택하기 
                    </h2>
                    <p className="mt-1 mb-4 text-sm text-gray-500">
                      송출을 원하시는 스크린을 선택해주세요. 
                    </p>

                    <div className='flex flex-col w-11/12 h-full pr-2 overflow-y-auto md:w-10/12 md:h-104'>
                      {this.state.screenList.length === 0 ? (
                        <div className='flex flex-row justify-center'>
                          <div className='flex flex-col items-center self-center mt-4 text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className='self-center mt-4 text-xl antialiased font-extrabold text-gray-500'>등록된 스크린이 없습니다.</span>
                            <span className='self-center text-xl antialiased font-extrabold text-gray-500'>스크린을 등록해주세요.</span>
                          </div>
                        </div>
                      ) : (
                        <>
                        {this.state.screenList.map((screen) => (
                          <div className='flex flex-col w-full'>
                            <div className='flex flex-row mb-4 border border-gray-200 rounded-lg shadow-md cursor-pointer'
                              onClick={() => this.devHandleChange(screen.ref)}>
                              <div className='px-2 py-2'>
                                <span
                                  className={classNames('text-purple-700','bg-purple-50','rounded-lg inline-flex p-3 ring-4 ring-white')}>
                                  <CogIcon className="w-6 h-6" aria-hidden="true" />
                                </span>
                              </div> 
                              <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-col justify-center'>
                                  <span className='items-start self-start ml-2 text-base antialiased font-bold'> {`${screen.screen} (${screen.screenType})`} </span> 
                                  <p className={
                                    screen.status === 'offline' ? 'item-start text-sm bg-red-700 rounded-md text-white font-bold px-2 inline-flex self-start ml-2 mt-1':
                                    'text-sm bg-indigo-700 rounded-md text-white font-bold px-2 w-auto inline-flex self-start ml-2 mt-1'
                                  }>
                                    {screen.status}
                                  </p> 
                                </div>
                                <div id={"dev_"+screen.id} className='flex flex-col justify-center hidden mr-2' ref={screen.ref}>
                                  <CheckIcon className='self-center w-6 h-6 text-indigo-500'/> 
                                </div> 
                              </div> 
                            </div>
                          </div>
                        ))}
                        </>
                      )}
                    </div>        
                  </div> 
                  <div className='flex flex-col h-full'>
                    <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                      Step 3. 콘텐츠 선택하기
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      어떠한 콘텐츠를 송출할까요? 편하게 골라주세요 :) 
                    </p>
                    <div className='flex flex-col w-full h-108 md:overflow-y-auto'>
                      {this.state.contentList.length === 0 ? (
                        <div className='flex flex-row justify-center mt-4'>
                          <div className='flex flex-col items-center self-center mt-4 text-gray-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className='self-center mt-4 text-xl antialiased font-extrabold text-gray-500'>등록된 콘텐츠가 없습니다.</span>
                            <span className='self-center text-xl antialiased font-extrabold text-gray-500'>콘텐츠를 등록해주세요.</span>
                          </div>
                      </div>
                      ) : (
                        <div className='grid grid-cols-2 gap-4 px-0 mt-4 mb-12 overflow-x-hidden md:grid md:grid-cols-3 md:gap-4 md:overflow-y-auto md:px-2'>
                          {this.state.contentList.map((content) => (
                            <div className='justify-center'>
                              <div id={"ctn_"+content.id}
                              className='flex justify-center w-full overflow-hidden bg-gray-100 rounded-lg cursor-pointer aspect-w-10 aspect-h-7'
                              onClick={() => this.ctnHandleChange(content.ref, content.spanRef)}> 
                                <img src={`${SERVER_URL}${content.contentThumb}`} loading='lazy' className = 'object-cover pointer-events-none'/> 
                                {/* {content.contentType === '이미지' ? (
                                  <img src={content.contentSrc} loading='lazy' className = 'object-cover pointer-events-none'/> 
                                ) : (
                                  <video src={`${content.contentSrc}#t=1`} className = 'object-cover pointer-events-none'/> 
                                )} */}
                                <div ref={content.ref}
                                  className='z-10 flex flex-row justify-center hidden w-full h-full bg-gray-600 opacity-70'>
                                    {/* <CheckIcon className='items-center self-center w-10 h-10 font-extrabold text-white align-middle opacity-100'/>  */}
                                    <span className='items-center self-center font-extrabold text-white align-middle opacity-100' ref={content.spanRef}></span>
                                </div>
                              </div> 
                              <div className='flex justify-center'> 
                                <p className='block mt-1 text-sm font-medium text-gray-900 truncate pointer-events-none'>
                                  {this.truncate(content.contentName)}
                                </p>
                              </div>
                            </div> 
                          ))}
                        </div>
                      )}
                    </div> 
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      }
      </>
    )
  }
}