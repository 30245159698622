import React, { useCallback, useRef, Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react'

// Calendar 
import Calendar from '@toast-ui/react-calendar';
import TUICalendar from '@toast-ui/react-calendar';
import { ISchedule, ICalendarInfo } from "tui-calendar";

// Icons 
import { ExclamationIcon, DotsVerticalIcon } from "@heroicons/react/solid"
import { ChevronLeftIcon, ChevronRightIcon, XIcon, PencilIcon, PlusCircleIcon, TrashIcon, CogIcon, PhotographIcon, FilmIcon} from "@heroicons/react/outline"

// tui calendar lib 
import 'tui-calendar/dist/tui-calendar.css';
// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

// Network module 
import axios from 'axios';
import _, { conformsTo } from 'lodash';
import { list } from 'purgecss/node_modules/postcss';
// Date Module 
import moment from "moment"

import configData from 'pages/reactConfig'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class SideEditor extends React.Component{
  constructor(props){
    super(props); 
    this.state = { 
      scheduleName : null,
      scheduleType : null, 
      startDate : null, 
      endDate : null, 
      screens : [], 
      contents : []
    }
    this.scheduleTypeIcon = React.createRef(null) 
    this.startDate = React.createRef(null)
    this.endDate = React.createRef(null)
    this.detailUpdate = this.detailUpdate.bind(this)
    this.serverAddr = configData['SERVER_URL']
  }

  detailUpdate = (scheduleInfo) => {
    console.log(scheduleInfo)
    const scheduleName = scheduleInfo['title']
    let scheduleType; 
    scheduleInfo['calendarId'] === "1" ? scheduleType = '사용자 지정 스케줄' : scheduleType = '광고 스케줄'
    const start = this.dateFormatting(scheduleInfo['start'])
    const end = this.dateFormatting(scheduleInfo['end'])
    this.setState({scheduleName:scheduleName, scheduleType:scheduleType, startDate:start, endDate:end, contents:scheduleInfo['contents'], screens:scheduleInfo['screens']}, () => 
    console.log(this.state))
    this.startDate.current.style.width = this.startDate.current.value.length-2+'ch'
  }

  dateFormatting = (datearg) => {
    let dateinfo = datearg.split('T')
    let hourinfo = dateinfo[1].split('.') 
    dateinfo = dateinfo[0] 
    hourinfo = hourinfo[0].slice(0,5)
    return dateinfo+' '+hourinfo
  }

  closing = () => {
    this.props.closeSide() 
  }

  
  screenDelete = async (e, idx) =>{
    // await this.props.allUpdate()
    const currentSchedule = this.state.scheduleName
    const currentScreenList = _.cloneDeep(this.state.screens)
    const currentType = _.cloneDeep(this.state.scheduleType)
    currentScreenList.forEach((element, index, object)=>{
      if (idx === element['idx']){
        object.splice(index, 1)
      }
    })
    let type = null 
    currentType === "광고 스케줄" ? type = 'ad' : type = 'schedule'
    let req = {'schedule': currentSchedule, 'idx': idx, target:'screen', type:type}
    let res = await this.deleteElement(req)
    if (res['result'] === 'OK'){
      await this.props.allUpdate() 
    }
    if (currentScreenList.length === 0){
      this.closing() 
    }
    this.setState({screens:currentScreenList})
  }

  contentDelete = async (e, idx) => {
    const currentSchedule = this.state.scheduleName
    const currentContentList = _.cloneDeep(this.state.contents)
    const currentType = _.cloneDeep(this.state.scheduleType)

    currentContentList.forEach((element, index, object)=>{
      if (idx === element['idx']){
        object.splice(index, 1)
      }
    })

    let type = null 
    currentType === "광고 스케줄" ? type = 'ad' : type = 'schedule'
    let req = {'schedule': currentSchedule, 'idx': idx, target:'content', type:type}
    let res = await this.deleteElement(req)

    if (res['result'] === 'OK'){
      await this.props.allUpdate() 
    }

    if (currentContentList.length === 0){
      this.closing() 
    }
    this.setState({contents:currentContentList})
  }

  scheduleDelete = async(e) =>{
    const currentSchedule = this.state.scheduleName
    const currentType = _.cloneDeep(this.state.scheduleType)
    let type = null 
    currentType === "광고 스케줄" ? type = 'ad' : type = 'schedule'
    let req = {'schedule': currentSchedule, target:'schedule', type:type}
    let res = await this.deleteElement(req)
    if (res['result'] === 'OK'){
      this.closing() 
      await this.props.allUpdate() 
    }
  }

  deleteElement = async(req) => {
    axios.defaults.withCredentials = true 
    const {data:result} = await axios.post(this.serverAddr+'/schedule/delete/',req,
    {
      withCredentials : true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  render(){
    return(
      <div className="w-full h-full max-w-md">
        <div className="h-full flex flex-col bg-white overflow-y-auto rounded-lg shadow-sm">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-start justify-between">
              <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                스케줄 상세정보 
              </h2>
              <div className="ml-3 h-7 flex items-center">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                  onClick={this.closing}>
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className='w-full border border-b-0 border-gray-100 -mt-1 mb-2'></div>
          {/* Main */}
          <div className='h-full overflow-y-auto overflow-x-hidden'>
            <div className="pb-1 sm:pb-6">
              <div>
                <div className="px-4 sm:flex sm:items-end sm:px-6">
                  <div className="sm:flex-1">
                    <div>
                      <div className="flex flex-row items-center">
                        <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">{this.state.scheduleName} </h3>
                        <span className={this.state.scheduleType === '사용자 지정 스케줄'? 'bg-indigo-500 ml-2 w-2 h-2 self-center rounded-full' 
                        : 'bg-rose-500 ml-2 w-2 h-2 self-center rounded-full'} ref={this.scheduleTypeIcon}>
                          <span className='sr-only'>userSchedule </span>
                        </span>
                      </div>

                      <p className={this.state.scheduleType === '사용자 지정 스케줄' ? "text-sm text-indigo-500" : "text-sm text-rose-500"} ref={this.scheduleType}>{this.state.scheduleType}</p>
                      <div className='flex flex-row justify-start mt-0.5'>
                        <div className='flex flex-row justify-start'>
                          <input className='text-sm text-gray-500 antialiased focus:outline-none cursor-default w-auto inline-block'
                            value={this.state.startDate}
                            ref={this.startDate}/>
                          <p className="text-sm text-gray-500 mr-2">~</p>
                          <input className='text-sm text-gray-500 antialiased focus:outline-none cursor-default w-auto inline-block'
                            value={this.state.endDate}
                            ref={this.endDate}/>
                        </div>
                        {/* TODO : 차후 반영 */}
                        {/* <PencilIcon className='w-4 h-4 text-sm text-indigo-500 self-center cursor-pointer'/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0 max-h-calendar flex flex-col justify-between">
              <div className='flex flex-col h-screen overflow-y-auto'>
                <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6 h-full">
                  <div className='h-1/6'>
                    <div className='flex flex-row justify-start'>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">스크린 리스트</dt>
                      {/* TODO : 차후 반영 */}
                      {/* <PlusCircleIcon className='w-4 h-4 text-indigo-500 self-center cursor-pointer'/> */}
                    </div>  
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                      {
                        this.state.screens.map((screen)=>(
                          <div className='flex flex-row justify-between mb-1 mt-2'>
                            <div className='flex flex-row justify-center justify-items-center'>
                              <CogIcon className='w-4 h-4 text-indigo-500 justify-self-center self-center items-center justify-center align-middle mr-1'/>
                              <p className='text-sm text-gray-900'>{screen.screen}</p>
                            </div>
                            <TrashIcon className='w-4 h-4 text-red-500 self-center items-center justify-center align-middle cursor-pointer'
                            onClick={(e) => this.screenDelete(e, screen.idx)}/> 
                          </div>
                        ))
                      }
                      <div className='mt-4'>
                        <div className='flex flex-row justify-start mt-2 mb-1'>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">콘텐츠 리스트</dt>
                          {/* <PlusCircleIcon className='w-4 h-4 text-indigo-500 self-center cursor-pointer'/> */}
                        </div> 
                        {
                          this.state.contents.map((content) => (
                            <div className='flex flex-row justify-between mb-1 mt-2'>
                              <div className='flex flex-row justify-center justify-items-center'>
                                {content.type.includes('image') === true ? (
                                  <PhotographIcon className='w-4 h-4 text-indigo-500 justify-self-center self-center items-center justify-center align-middle mr-1'/>
                                ) : (
                                  <FilmIcon className='w-4 h-4 text-indigo-500 justify-self-center self-center items-center justify-center align-middle mr-1'/>
                                )}
                                <p className='text-sm text-gray-900'>{content.content}</p>
                              </div>
                              
                              <TrashIcon className='w-4 h-4 text-red-500 self-center items-center justify-center align-middle cursor-pointer' onClick={(e)=>this.contentDelete(e, content.idx)}/> 
                          </div>
                          ))
                        }
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
              
              {this.state.scheduleType === '사용자 지정 스케줄' ? (
              <div className='flex flex-row mt-4 px-6'>
                <button
                  type="button"
                  className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 sm:flex-1"
                  onClick={(e) => this.scheduleDelete(e)}
                >
                스케줄 삭제
                </button>
              </div>
              ) : (
              <div className='flex flex-row mt-4 px-6'>
                <button
                  type="button"
                  className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none sm:flex-1" disabled={true}
                >
                광고 스케줄은 삭제할 수 없습니다.
                </button>
              </div>
              )}

            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default class ScheduleCalendar extends React.Component{
  constructor(props){
    super(props); 
    this.start = new Date(new Date().setMonth(new Date().getMonth()+1)) 
    this.end = new Date(new Date().setDate(this.start.getDate()+3))
    this.state = {
      schedule: [],
      calendars: [
        { id: "1", name: "사용자 지정 스케줄", color: "#ffffff", bgColor: "#6875f5", dragBgColor: "#6875f5", borderColor: "#6875f5" },
        { id: "2", name: "광고 스케줄", color: "#ffffff", bgColor: "#e31040", dragBgColor: "#e31040", borderColor: "#e31040"}
      ], 
      cal : React.createRef(null), 
      label : React.createRef(null), 
      isLoading : true
    } 
    this.sideEditor = React.createRef(null)
    this.sideDiv = React.createRef(null) 
    this.calDiv = React.createRef(null) 
    this.templates = {
      time : function(schedule){
        console.log(schedule);
        return this._getTimeTemplate(schedule, false);
      }
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    this.updateSchedule() 
  }

  // marking 
  updateSchedule = async() => {
    console.log('update Schedule') 
    let screenInfo = await this.getSchedule() 
    let calendarList = []
    if (screenInfo['result'] === 'OK'){
      screenInfo['schedule'].forEach((element, index)=>{
        let calendarID; 
        if(element['scheduleType'] === 'userSchedule'){
          calendarID = '1' 
        }else{
          calendarID = '2' 
        }
        let calendar = {calendarId: calendarID, category: "allday", isVisible: true, title: element['name'],
                       id: String(index), body: element['name'], start:element['startSchedule'], end:element['endSchedule'],
                      'screens':element['screen'], 'contents':element['contents'], 'ref':React.createRef(null)}
        calendarList.push(calendar) 
      })
    }else{

    }
    this.setState({schedule:calendarList, isLoading:false}, ()=>{
      let label = this.getLabel() 
      this.state.label.current.innerHTML = label 
    })
  }

  dateFormatting = (datearg) => {
    let dateinfo = datearg.split('T')
    let hourinfo = dateinfo[1].split('.') 
    dateinfo = dateinfo[0] 
    hourinfo = hourinfo[0].slice(0,5)
    return dateinfo+' '+hourinfo
  }

  _getTimeTemplate = (schedule, isAllDay) =>{
    var html = [];
    if (!isAllDay) {
      html.push("<strong>" + this._getFormattedTime(schedule.start) + "</strong> ");
    }
    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(" Private");
    } else {
      if (schedule.isReadOnly) {
        html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
      } else if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      } else if (schedule.location) {
        html.push('<span class="calendar-font-icon ic-location-b"></span>');
      }
      html.push(" " + schedule.title);
    }
    return html.join("");
  }

  _getFormattedTime = (time) => {
    const date = new Date(time);
    const h = date.getHours();
    const m = date.getMinutes();
    return `${h}:${m}`;
  }

  // marking 
  onClickSchedule = (e, ref) => {
    const { calendarId, id } = e.schedule;
    const el = this.state.cal.current.calendarInst.getElement(id, calendarId);
    const scheduleID = parseInt(e.schedule.id)
    const currentSchdule = this.state.schedule[id] 

    console.log('currentschedule') 
    console.log(currentSchdule)

    this.sideEditor.current.detailUpdate(currentSchdule)

    this.sideDiv.current.classList.add('translate-x-0') 
    this.sideDiv.current.classList.remove('translate-x-full') 
    this.sideDiv.current.classList.remove('hidden') 
    this.calDiv.current.classList.add('w-9/12')
    this.calDiv.current.classList.remove('w-full')
  };

  onBeforeCreateSchedule = (scheduleData) => {

    const schedule = {
      id: String(Math.random()),
      title: scheduleData.title,
      isAllDay: scheduleData.isAllDay,
      start: scheduleData.start,
      end: scheduleData.end,
      category: scheduleData.isAllDay ? "allday" : "time",
      dueDateClass: "",
      location: scheduleData.location,
      raw: {
        class: scheduleData.raw["class"]
      },
      state: scheduleData.state, 
    };

    this.state.cal.current.calendarInst.createSchedules([schedule]);
  };

  onBeforeDeleteSchedule = (res) => {
    console.log(res);
    const { id, calendarId } = res.schedule;
    this.state.cal.current.calendarInst.deleteSchedule(id, calendarId);
  }

  onBeforeUpdateSchedule = (e) => {
    console.log(e);
    const { schedule, changes } = e;
    this.state.cal.current.calendarInst.updateSchedule(
      schedule.id,
      schedule.calendarId,
      changes
    );
  }

  prevMonth = () =>{
    this.state.cal.current.calendarInst.prev() 
    let label = this.getLabel() 
    this.state.label.current.innerHTML = label
  }
  nextMonth = () =>{
    this.state.cal.current.calendarInst.next()
    let label = this.getLabel() 
    this.state.label.current.innerHTML = label
  }
  todayFunc = () =>{
    this.state.cal.current.calendarInst.today() 
    let label = this.getLabel() 
    this.state.label.current.innerHTML = label
  }

  getLabel = () => {
    let dateCal = this.state.cal.current.calendarInst.getDate()
    let year = dateCal._date.getFullYear()
    let month = dateCal._date.getMonth()+1
    let labelText = year+"년 "+month+"월"
    return labelText
  }

  getSchedule = async() => {
    axios.defaults.withCredentials = true 
    const {data:result} = await axios.get(this.serverAddr+'/schedule/getSchedule/',
    {
      withCredentials: true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  closeSide = () => {
    this.sideDiv.current.classList.remove('translate-x-0') 
    this.sideDiv.current.classList.add('translate-x-full') 
    this.sideDiv.current.classList.add('hidden') 
    this.calDiv.current.classList.remove('w-9/12')
    this.calDiv.current.classList.add('w-full')
  }

  render(){
    const isLoading = this.state.isLoading
    return(
      <div className="w-full h-full mt-4 overflow-y-hidden">
        <div className="bg-blue-50 border-l-4 border-indigo-400 p-4 mb-4 ">
          <div className="flex flex-row justify-between">
            <div className='flex flex-row items-center'> 
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
              </div>
              <div className='ml-2'> 
                <p className="text-sm text-blue-700">
                  아래 달력을 이용하여 기존의 스케줄을 {''}
                  <a href="#" className="font-medium underline text-blue-700 hover:text-blue-600">
                    수정하거나 삭제할 수 있습니다
                  </a>
                  :) 
                </p>
              </div> 
            </div> 
          </div>
        </div>
      {isLoading === true ? (
        <div className='w-full h-full flex flex-col justify-center'>
          <svg class="animate-spin h-12 w-12 text-rose-500 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div> 
      ) : (
        <div className='w-full overflow-y-hidden flex flex-col'> 
          <div className='flex flex-row justify-start'>
            <button className='rounded-full border border-gray-300 px-4 py-2 mb-2 mr-2 bg-white hover:border-indigo-500' onClick={this.todayFunc}>
              <p className="text-sm text-gray-700">오늘</p>
            </button>
            <button className='rounded-full border border-gray-300 px-2 py-2 mb-2 mr-1 bg-white hover:border-indigo-500' onClick={this.prevMonth}>
              <ChevronLeftIcon className='h-5 w-5 text-gray-700'/>
            </button>
            <button className='rounded-full border border-gray-300 px-2 py-2 mb-2 mr-2 bg-white hover:border-indigo-500' onClick={this.nextMonth}>
              <ChevronRightIcon className='h-5 w-5 text-gray-700'/>
            </button>
            <p className="text-xl font-normal text-gray-700 self-center mb-2 font-antialiased" ref={this.state.label}></p>
          </div>

          <div className='w-full flex flex-row'>
            <div className='flex flex-col w-full transition-width duration-200 ease-in-out transform' ref={this.calDiv}>
              <TUICalendar
                ref={this.state.cal}
                height="650px"
                view="month"
                month={
                  {daynames : ["일","월",'화','수','목','금','토']}
                }
                useCreationPopup={true}
                useDetailPopup={true}
                // template={() => this.templates()}
                calendars={this.state.calendars}
                schedules={this.state.schedule}
                onClickSchedule={(e) => this.onClickSchedule(e)}
                // onBeforeCreateSchedule={(scheduleData) => this.onBeforeCreateSchedule(scheduleData)}
                // onBeforeDeleteSchedule={(res) => this.onBeforeDeleteSchedule(res)}
                // onBeforeUpdateSchedule={(e) => this.onBeforeUpdateSchedule(e)}
                isReadOnly={true}
                disableClick={true}
                disableDblClick={false} 
                useDetailPopup={false}
              />
            </div>
            {/* ssong */}
            <div className='w-3/12 pl-2 transition-width transform duration-200 ease-in-out translate-x-full hidden' ref={this.sideDiv}>
              <SideEditor ref={this.sideEditor} closeSide={this.closeSide} allUpdate={this.updateSchedule} /> 
            </div>
          </div>
        </div> 
      )}
      </div>
    )
  }
}

