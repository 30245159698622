/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import axios from 'axios'
import _ from 'lodash'
import { Redirect } from 'react-router'
import { PwChangeModal } from 'pages/util/notification'
import configData from 'pages/reactConfig'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

const pageDes = {
  dashboard : {subject : "Dashboard", description: "다양한 정보들의 현황을 제공하는 페이지입니다."}, 
  adManager : {subject : '광고 관리', description : '광고 관리 페이지입니다.'},
  prodsManager : {subject : '제품 관리 ', description : '매장을 추가하고 관리할 수 있는 기능을 제공하는 페이지입니다.'},
  schManager : {subject : '스케줄 관리', description : '매장에 송출할 콘텐츠의 일정을 설정해보세요 :) '},
  ctnRepo : {subject : '콘텐츠 보관함', description : '콘텐츠를 업로드하고 관리할 수 있는 보관함입니다. '},
  adStat : {subject : '수익 통계', description : '지금까지 Airbox를 통해 창출한 수익을 관리하세요 :)'},
  adReq : {subject : '광고 송출', description : '보유하고 있는 에어박스에 여러 광고를 송출해보세요 :)'}, 
  adReport : {subject : '송출보고서 내보내기', description : '진행된 광고에 대한 송출보고서를 생성합니다.'}
}

// const userNavigation = [
//   // { name: 'Your Profile', href: '#' },
//   // { name: 'Settings', href: '#' },
//   { name: 'Sign out', href: 'http://192.168.1.50:8000/api/v1/users/auth/logout/' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function pageKeyRes(){
  const pathname = window.location.pathname; 
  let pieces = pathname.split('/');
  let realPath = pieces[pieces.length-1]; 
  console.log('pathname')
  console.log(pathname)
  console.log(pieces)
  console.log(realPath)
  const convertTable = {
    dashboard : 'dashboard',
    adManager : 'adManager',
    devManager : 'prodsManager',
    schedule : 'schManager',
    contentRepo : 'ctnRepo',
    statistic : 'adStat',
    adReq : 'adReq', 
    adReport : 'adReport'
  }
  return convertTable[realPath]
}

export default class Example extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      userNavigation : [
        { name: '패스워드 변경', href: '#', func:this.pwChangeHandler },
        { name: '로그아웃', href: '#', func:this.logout },
      ],
      username : null,
      usermail : null, 
      isLoading : true
    }
    this.notiRef = this.props.notiRef
    this.sideNavDiv = this.props.sideNavDiv
    this.mainDiv = this.props.mainDiv
    this.pwModalRef = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    const userInfo = UserConfig.getUserInfo() 
    const {name, email} = userInfo
    this.setState({username: name, usermail : email, isLoading:false})
  }

  logout = async () =>{
    localStorage.clear()
    UserConfig.logout()
    document.location.href='/cms'
  }

  // marking 
  pwChangeHandler = () => {
    this.pwModalRef.current.modalHandler(true)
  }

  notiHandle = (e) => {
    this.notiRef.current.notificationHandler(true)
  }

  sideNavUIControl = (e) => { 
    if (this.sideNavDiv.current.classList.contains('w-0')){
      // this.mainDiv.current.classList.replace('w-full', 'w-32')
      this.mainDiv.current.classList.add('translate-x-60')
      this.sideNavDiv.current.classList.replace('w-0', 'w-60')
      this.sideNavDiv.current.classList.replace('opacity-0', 'opacity-100')
      // this.sideNavDiv.current.classList.replace('-translate-x-full', 'translate-x-0')
    }else{
      // this.sideNavDiv.current.classList.replace('translate-x-0', '-translate-x-full')
      this.sideNavDiv.current.classList.replace('opacity-100', 'opacity-0')
      this.sideNavDiv.current.classList.replace('w-60', 'w-0')
      // this.mainDiv.current.classList.replace('w-32', 'w-full')
      this.mainDiv.current.classList.replace('translate-x-60', 'translate-x-0')
    }
  }

  render(){
    const pagePath = pageKeyRes(); 
    return(
      <>
      <PwChangeModal ref={this.pwModalRef}/>
      {this.state.isLoading === true ? "" : (
        <div className='flex flex-col w-full px-2 md:px-0'>
          <div className='bg-white rounded-lg py-3 px-4 flex flex-row justify-between'>
            <div className='flex flex-row justify-start space-x-4'>
              <div className='flex flex-col justify-center md:hidden'>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" onClick={(e) => this.sideNavUIControl(e)}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
                </svg>
              </div>
              <div className='flex flex-col'>
                {/* const  */}
                <h2 className='text-gray-900 md:text-xl text-lg font-bold align-middle'>{pageDes[pagePath]['subject']}</h2>
                {/* <h2 className='text-gray-900 text-xl font-bold align-middle'>Dashboard</h2> */}
                <span className='text-gray-500 md:text-sm text-xs font-medium tracking-tight md:tracking-normal'>{pageDes[pagePath]['description']}</span>
              </div>
            </div>

            <div className='flex flex-row self-center relative z-10'>
              <button type="button" className="bg-white p-3 text-gray-500 rounded-full hover:text-indigo-700 focus:outline-none hidden md:block" onClick={(e) => this.notiHandle(e)}>
                <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-rose-500 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rose-500 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  {/* <img className="h-12 w-12 rounded-full" src={user.imageUrl} alt="" /> */}
                  <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-rose-500">
                    {this.state.username.length === 1 ? (
                      <span className="text-2xl font-medium leading-none text-white">{this.state.username[0].toUpperCase()}</span>  
                    ) : null}
                    {this.state.username.length === 2 ? (
                      <span className="text-xl font-medium leading-none text-white">{this.state.username}</span>  
                    ) : null}
                    {this.state.username.length >= 3 ? (
                      <span className="text-xl font-medium leading-none text-white">{this.state.username[this.state.username.length-2].toUpperCase()}{this.state.username[this.state.username.length-1].toUpperCase()}</span>  
                    ) : null}
                    {/* <span className="text-2xl font-medium leading-none text-white">{this.state.username[0].toUpperCase()}</span> */}
                  </span>
                </Menu.Button>
              </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >

{/* marking  */}
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className='flex flex-col px-4 py-2'>
                    <span className='text-sm text-gray-700'>
                      {this.state.username}
                    </span>
                    <span className='text-xs text-gray-700'>
                      {this.state.usermail}
                    </span>
                  </div>
                  <div className='border-b border-gray-300 '/>
                  {this.state.userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          onClick={item.func}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
              </Menu>
            </div>
          </div>
        </div>
      )}
      </>
    )
  }
}
