import { reject } from "lodash";

export function InitDB(){

  if(!window.indexedDB){
    alert("지원되지 않는 브라우저입니다.\n크롬 브라우저를 이용해주세요.")
    return false 
  }
  const request = indexedDB.open("screenAirCtnDB")

  request.onerror = function(event) {
    // request.errorCode 에 대해 무언가를 한다!
    console.log('error')
    console.log(event) 
  };

  request.onsuccess = function(event) {
    // request.result 에 대해 무언가를 한다!
    console.log('onsuccess')
  };
  // 여기까지 init 

  request.onupgradeneeded = function(event) {
    // Save the IDBDatabase interface
    const db = event.target.result;
    // Create an objectStore for this database
    const objectStore = db.createObjectStore("content", { autoIncrement : true });
  };
}

export function CtnDataInsert(blob){
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("screenAirCtnDB") 
    let idx; 
    request.onerror = (e) => reject(e.target.error)
    request.onsuccess = (e) => {

      const db = request.result 
      const transaction = db.transaction(['content'], 'readwrite')
      transaction.oncomplete = (e) => resolve(idx) 
      transaction.onerror = (e) => reject(e.target.error)
      const objStore = transaction.objectStore('content')
      const req = objStore.add(blob)
      req.onsuccess = (e) => {idx = e.target.result}
    }
  })
}

export function CtnDataGet(key){
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('screenAirCtnDB')
    request.onerror = (e) => reject(e.target.error)
    request.onsuccess = (e) => {
      const db = request.result 
      const transaction = db.transaction('content')
      transaction.oncomplete = (e) => resolve(e) 
      transaction.onerror = (e) => reject(e.target.error)

      const objStore = transaction.objectStore('content')
      const objStoreReq = objStore.get(key) 
      objStoreReq.onsuccess = (e) => resolve(objStoreReq.result)
    }
  })
}

export function DeleteData(){
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('screenAirCtnDB')
    request.onerror = (e) => reject(e.target.error)
    request.onsuccess = (e) => {
      const db = request.result 
      const transaction = db.transaction('content', 'readwrite')
      transaction.oncomplete = (e) => resolve(e) 
      transaction.onerror = (e) => reject(e.target.error)

      const objStore = transaction.objectStore('content')
      const objStoreReq = objStore.clear()
      objStoreReq.onsuccess = (e) => resolve(objStoreReq.result)
    }
  })
}

// export function CtnDataInsert(blob){
//   const request = indexedDB.open("screenAirCtnDB")
//   let idx; 
//   request.onerror = (e) => {
//     console.log('request error')
//     console.log(e) 
//   }

//   request.onsuccess = (e) => {
//     const db = request.result 
//     const transaction = db.transaction(['content'], 'readwrite')
  
//     transaction.oncomplete = (e) => {
//       console.log('transaction complete')
//       console.log(e) 
//       console.log('transaction complet2e')
//       console.log(idx) 
//     }
//     transaction.onerror = (e) => {
//       console.log('transaction error')
//     }
  
//     const objStore = transaction.objectStore('content')
//     const req = objStore.add(blob)
//     req.onsuccess = (e) => {
//       console.log('onsuccess')
//       idx = e.target.result 
//       console.log(idx) 
//       return idx 
//     }
//   }
// }