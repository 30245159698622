import React from "react"
import jwt_decode from "jwt-decode";

export default class UserConfig extends React.Component{
  constructor(props){

  }

  static userkey = {
    loggedIn : false, 
    accessToken: null, 
    refreshToken: null
  }

  static isLogged(){
    if (this.userkey.loggedIn === true & this.userkey.accessToken !== null & this.userkey.refreshToken !== null){
      return this.userkey.loggedIn
    }else{
      const key = localStorage.getItem('screenAirAccessToken')
      if (key === null || key === undefined || key === ''){
        return false 
      }else{
        const refKey = localStorage.getItem('screenAirRefreshToken')
        this.userkey.refreshToken = refKey 
        this.userkey.accessToken = key 
        this.userkey.loggedIn = true 
        return this.userkey.loggedIn
      }
    }
  }

  static getAccKey(){
    this.isLogged()
    const key = this.userkey.accessToken 
    return key 
  }

  static loginSet(data){
    localStorage.setItem('screenAirAccessToken', data.access)
    localStorage.setItem('screenAirRefreshToken', data.refresh)
    this.userkey.accessToken = data.access 
    this.userkey.refreshToken = data.refresh 
    this.userkey.loggedIn = true 
  }

  static getUserInfo(){
    this.isLogged()
    if (this.userkey.loggedIn === false){
      return false 
    }
    return jwt_decode(this.userkey.accessToken)
  }

  static logout(){
    localStorage.removeItem('screenAirAccessToken')
    localStorage.removeItem('screenAirRefreshToken')
    this.userkey.accessToken = null 
    this.userkey.refreshToken = null 
    this.userkey.loggedIn = false 
  }

}

export function isLogged(){
  const key = localStorage.getItem('screenAirAccessToken')
  if (key === null){
    return false
  }else{
    return true 
  }
}

export function getAcckey(){
  const key = localStorage.getItem('screenAirAccessToken')
  return key 
}