import React, { useState } from 'react'
import ScaIcon from '../../static/img/logo/screenAirLogo.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import configData from 'pages/reactConfig'

export default class PasswordReset extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
      mail: null,
      username : null, 
      mailHash : this.props.match.params.mail,
      userHash : this.props.match.params.username, 
      isLoading : true 
    }; 
    this.emailInput = React.createRef(null) 
    this.errorSpan = React.createRef(null) 
    this.successA = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    let emailaddr = this.emailInput.current.value; 
    let url = this.serverAddr+'/users/pwReset/'
    let data = {'email' : emailaddr}; 
    axios.post(url,data,
    {
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }).then((response) =>{
      const data = response.data 
      if (data['result'] === 'FAIL'){
        if(data['reason'] === 'USER_NOT_EXIST'){
          this.errorSpan.current.innerHTML='이메일 주소를 다시 확인해주세요.'
          this.errorSpan.current.classList.remove('hidden') 
        }
      }else if(data['result'] === 'OK'){
        alert("초기화된 패스워드가 이메일로 전송되었습니다.\n메일 보관함을 확인해주세요.")
        this.successA.current.classList.remove('hidden') 
      }
    }).catch(error=>{
      this.errorSpan.current.innerHTML='예기치 못한 시스템 오류가 발생하였습니다.<br/>관리자에게 문의하여 주십시오.'
      this.errorSpan.current.classList.remove('hidden') 
    })
  }

  inputHandle(){
    const span = this.errorSpan.current
    if (span.classList.contains('hidden') === false){
      span.classList.add('hidden') 
      this.successA.current.classList.add('hidden') 
    }
  }

  render(){
    return(
      <div className='h-screen w-full'>
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-12 w-auto"
                  src={ScaIcon}
                  alt="Workflow"
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">ScreenAir 패스워드 초기화</h2>
                <p className="mt-2 text-sm text-gray-600">이메일 주소로 초기화된 패스워드를 보내드립니다.</p>
              </div>


              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="#" className="space-y-6" onSubmit={this.handleSubmit}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        이메일
                      </label>
                      <div className="mt-1">
                      <input
                          type="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder='가입한 이메일 주소를 입력해주세요'
                          ref={this.emailInput}
                          onKeyUp={(e) => this.inputHandle()}
                        />
                      </div>
                      <span className='text-sm font-medium text-red-600 hidden' ref={this.errorSpan}></span>
                      {/* marking  */}
                      <Link to='/cms/' className='font-medium text-sm text-indigo-600 hover:text-indigo-500 hidden' ref={this.successA}>
                          로그인 페이지로 이동하기
                      </Link>
                    </div>

                    <div className="flex items-center justify-start hidden">
                      <div className="text-sm">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          메일을 받지 못했습니다. 
                        </a>
                      </div>
                    </div>

                    <div>
                      <button 
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                        onSubmit = {this.handleSubmit}
                      >
                        패스워드 초기화
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}
