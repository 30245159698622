import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import Footer from "servicePages/footer"
import UserConfig from "servicePages/config/userManager";
import ServiceHeader from "servicePages/header";
import toast, { Toaster } from 'react-hot-toast';

export default function FindPW(props){

  const {history} = props 
  const [found, setFound] = useState(false) 
  const [done, setDone] = useState(false) 
  const [authActive, setAuthActive] = useState(false) 
  const [smsPK, setSMSPK] = useState(null) 
  const [email, setEmail] = useState(null) 
  const [username, setUserName] = useState(null) 
  const [param, setParam] = useState(null)
  const emailRef = useRef(null) 
  const nameRef = useRef(null) 
  const phoneRef = useRef(null) 
  const authRef = useRef(null) 
  const passwdRef = useRef(null) 
  const passwd2Ref = useRef(null) 
  
  const isNum = (val) => {
    return !isNaN(val)
  }

  const inputVerify = () => {
    // name verify 
    const email = emailRef.current.value 
    const name = nameRef.current.value 
    const phone = phoneRef.current.value 

    if(email === undefined || email === '' || email === null){
      toast.error("이메일을 입력해주세요.")
      return false
    }    

    if(!email.includes('@')){
      toast.error("올바른 이메일을 입력해주세요.")
      return false
    }

    if(name === undefined || name === '' || name === null){
      toast.error("이름을 입력해주세요.")
      return false
    }

    if(phone === undefined || phone === '' || phone === null){
      toast.error("전화번호를 입력해주세요.")
      return false
    }

    if(!isNum(phone)){
      toast.error("올바른 전화번호를 입력해주세요.")
      return false
    }

    if(phone.length !== 11){
      toast.error("올바른 전화번호를 입력해주세요.")
      return false
    }
    authReq()
  }

  const authReq = async() => {
    const name = nameRef.current.value 
    const phone = phoneRef.current.value 
    const reqURI = `${HOST_ADDR}app/v1/users/account/find/smsAuthReq/`
    const data = {username : name, mobile:phone, type:'ID'}
    try{
      const res = await axios.post(reqURI, data)
      const resData = res.data
      if(resData.result === 'OK'){
        emailRef.current.disabled = true 
        nameRef.current.disabled = true 
        phoneRef.current.disabled = true 
        setAuthActive(true) 
        setSMSPK(resData.data)
        authRef.current.focus()
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        toast.error(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  } 

  const auth = async() => {
    const smsCode = authRef.current.value 
    const email = emailRef.current.value 
    const name = nameRef.current.value 
    const phone = phoneRef.current.value 
    const reqURI = `${HOST_ADDR}app/v1/users/account/find/smsAuthReq/check/`
    const data = {username:name, phone:phone, pk:smsPK, type:'PW', code:smsCode, email:email}
    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        setParam(data) 
        setFound(true) 
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        toast.error(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const movePage = (routeTarget) => {
    history.replace(routeTarget, {})
  }

  const passwdReset = async() => {
    const password = passwdRef.current.value 
    const password2 = passwd2Ref.current.value 

    if (password === ''){
      toast.error("비밀번호를 입력해주세요")
      return false 
    }

    if (password !== password2){
      toast.error("입력하신 비밀번호가 서로 일치하지 않습니다.")
      return false 
    }

    if (password.length < 9){
      toast.error("비밀번호는 최소 9글자 이상으로 설정해주세요.")
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/account/reset/`
    const data = param
    data.password = password
    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        setFound(false) 
        setDone(true) 
      }
    }catch(e){
      const error = e.response.data 
      const errorMsg = error.msg 
      toast.error(errorMsg)
      return false 
    }
  }

  return(
    <>
      {/* Header */}
      <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className={ found === false && done === false ? "container mx-auto px-28 py-24 flex flex-row justify-center min-h-screen max-h" : 'hidden'}>
        <div className="flex flex-col justify-start self-start w-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon font-bold text-black self-center">비밀번호 찾기</p>
            <input type='text' className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-12 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="이메일" ref={(ref) => emailRef.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {nameRef.current.focus()}}} />
            <input type='text' maxLength={12} className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-6 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="이름" ref={(ref) => nameRef.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {phoneRef.current.focus()}}} />
            <input type='text' maxLength={11} className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-6 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="전화번호" ref={(ref) => phoneRef.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {inputVerify()}}} />

            <div className={ authActive === true ? "flex flex-col justify-start mt-6 gap-y-2" : 'hidden'}>
              <input type='text' maxLength={6} className="border-gray-400 rounded-lg text-base py-2.5 font-nexon self-start w-full focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
              placeholder="인증번호(6자리)" ref={(ref) => authRef.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {auth()}}} />
              <div className="flex flex-row justify-start gap-x-4 mt-1">
                <p className="text-lg font-nexon text-rose-500 underline cursor-pointer self-center" onClick={() => auth()}>인증하기</p>
                <p className="text-lg font-nexon text-black underline cursor-pointer self-center" onClick={() => authReq()}>재발송</p>
              </div>
            </div>

            <div className={authActive === true ? 'hidden' : "flex flex-row rounded-lg w-full bg-rose-500 py-2 self-center justify-center mt-6 cursor-pointer"} onClick={() => inputVerify()}> 
              <p className="text-lg font-nexon text-white self-center">인증번호 발송</p>
            </div>
          </div>
        </div>
      </div>

      <div className={ found === true ? "container mx-auto px-28 py-24 flex flex-row justify-center min-h-screen max-h" : 'hidden'}>
        <div className="flex flex-col justify-start self-start w-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon font-bold text-black self-center">비밀번호 초기화</p>
            <input type='password' className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-12 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="비밀번호를 입력해주세요" ref={(ref) => passwdRef.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {passwd2Ref.current.focus()}}} />
            <input type='password' maxLength={12} className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-6 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="한번 더 비밀번호를 입력해주세요" ref={(ref) => passwd2Ref.current = ref} onKeyPress={(e) => {if (e.key === 'Enter') {passwdReset()}}} />
            <div className="flex flex-row rounded-lg bg-rose-500 py-2 justify-center cursor-pointer mt-8" onClick={() => passwdReset()}>
              <p className="text-xl font-nexon text-white self-center">비밀번호 초기화</p>
            </div>
          </div>
        </div>
      </div>

      <div className={ done === true ? "container mx-auto px-28 py-24 flex flex-row justify-center min-h-screen max-h" : 'hidden'}>
        <div className="flex flex-col justify-start self-start w-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon font-bold text-black self-center">비밀번호 초기화</p>
            <div className="flex flex-col justify-start mt-12 gap-y-2">
              <p className="text-2xl font-nexon text-black self-center">비밀번호 초기화가 완료되었습니다.</p>
            </div>
            <div className="flex flex-row rounded-lg bg-rose-500 py-2 justify-center cursor-pointer mt-8" onClick={() => movePage('/service/login/')}>
              <p className="text-xl font-nexon text-white self-center">로그인하기</p>
            </div>
          </div>
        </div>
      </div>


      <div className="flex flex-col justify-end self-end flex-grow">
        <Footer props={props}/> 
      </div>

    </> 
  )
}