import React, { Fragment, userRef, useState} from 'react'
import { Menu, Popover, Dialog, Switch, Transition } from '@headlessui/react'
import { AcademicCapIcon, BadgeCheckIcon, BellIcon, CashIcon, ClockIcon, MenuIcon, ReceiptRefundIcon, UsersIcon, XIcon, CalendarIcon} from '@heroicons/react/outline'
import { SearchIcon, QuestionMarkCircleIcon, ExclamationIcon } from '@heroicons/react/solid'
import Topcontainer from '../mixins/container/top/top.js'
import Sidenav from '../mixins/sidenav/sidenav.js'
import AdReportMain from './container/adReportMain.js'
import { NotiModal } from 'pages/util/notification.js'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class AdReport extends React.Component{
  constructor(props){
    super(props);
    this.notiRef = React.createRef(null) 
    this.sideNavDivRef = React.createRef(null)
    this.mainDivRef = React.createRef(null)
    this.state = {

    }
  }

  render(){
    return(
      <div className='flex'>
        <aside className='h-screen md:w-80 md:block md:opacity-100 md:static absolute z-20 w-0 opacity-0 transition-all transform duration-300 delay-100 ease-in-out' ref={this.sideNavDivRef}>
          <Sidenav />
        </aside>
        <main className='w-full bg-gray-100 md:overflow-hidden overflow-visible transition-all transform duration-300 delay-100 ease-in-out' ref={this.mainDivRef}>
            <NotiModal ref={this.notiRef} />
            <div className='container mx-auto sm:px-6 lg:px-8 py-6'>
              <Topcontainer notiRef={this.notiRef} sideNavDiv={this.sideNavDivRef} mainDiv={this.mainDivRef} />
              <AdReportMain/> 
            </div> 
        </main>
      </div>
    )
  }
}