import React, { createRef, useState } from 'react'
import { ReactDOM } from 'react';
import QRCode from "react-qr-code";
import { data } from 'autoprefixer';
import axios from 'axios';
import { useAsync } from 'react-async';
import { NotiModal } from 'pages/util/notification'
import { PwChangeModal } from 'pages/util/notification'
import configData from 'pages/reactConfig'
import 'App.css'; 
import html2canvas from 'html2canvas'

export default class CouponeGet extends React.Component{
  constructor(props){
    super(props)
    // console.log(props.match.params.target); 
    const meta = document.createElement('meta'); 
    // viewport 설정 for mobile 
    meta.name = 'viewport'
    meta.content ="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
    document.getElementsByTagName('head')[0].appendChild(meta); 

    this.state = { 
      shopName : props.match.params.target, 
      identification : props.match.params.identification, 
      item : null, 
      dueDate : null,
      condition : null, 
      isLoading : true, 
      isCaptureTextHide : false, 
      checkAddr : null, 
    }

    this.serverAddr = configData.SERVER_URL
    this.qrRef = null; 
    this.captureDiv = React.createRef(null) 
    this.captureText = React.createRef(null) 
  }
      
  componentDidMount = async() => {

    let result = await this.getInfo(this.state.identification)
    if (result['result'] === 'OK'){
      let item = result['item'] 
      let expireDate = result['expire'] 
      let condition = result['condition'] 
      let expireDateObj = new Date(expireDate) 
      let year = expireDateObj.getFullYear()
      let month = expireDateObj.getMonth()+1 
      let date = expireDateObj.getDate() 
      month < 10 ? month = '0'+month : month = month 
      date < 10 ? date = '0'+date : date = date 
      let expireDateStr = year+"년 "+month+"월 "+date+"일"
      this.setState({
        item : item, 
        dueDate : expireDateStr, 
        condition : condition, 
        isLoading : false,
        checkAddr : this.serverAddr+"/coupon/check/"+this.state.identification+"/"+encodeURIComponent(this.state.shopName)
      })
    }else{
      // 404 page로 대체 필요 
      alert("데이터를 정상적으로 불러오지 못했습니다.\n네크워크 상태를 점검한 뒤 다시 QR코드를 스캔해주세요")
      return false 
    }
  }

  getInfo = async(id) => {
    const {data:result} = await axios.get(this.serverAddr+'/qrAPI/coupon/info/'+id+'/'+this.state.shopName,
    {
      withCredentials : true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  captureTextControl(){
    if (this.state.isCaptureTextHide === false){
      this.setState({isCaptureTextHide:true})
      this.captureDiv.current.classList.add('hidden')
      this.captureText.current.innerHTML = "캡쳐 안내 문구 보기"
    }else{
      this.setState({isCaptureTextHide:false})
      this.captureDiv.current.classList.remove('hidden')
      this.captureText.current.innerHTML = "캡쳐 안내 문구 지우기"
    }
  }

  // (220226, @ssong) web 기반에서 저장기능을 지원하기 위해 구현하였으나 실질적으로 캡쳐하는 것이 더 나을 것 같아 주석처리 
  // saveScreenAsImage(){
  //   if (this.state.isLoading === false){
  //     const container = document.getElementById('container')
  //     const footer = document.getElementById('footer') 
  //     footer.classList.add('hidden')
  //     html2canvas(container).then(canvas=>{
  //       this.onSaveAs(canvas.toDataURL('image/png'), 'coupon.png'); 
  //     })
  //   }
  // }

  // onSaveAs(uri, filename){
  //   let link = document.createElement('a')
  //   document.body.appendChild(link);
  //   link.href=uri; 
  //   link.download = filename; 
  //   link.click(); 
  //   document.body.removeChild(link); 
  //   document.getElementById('footer').classList.remove('hidden') 
  // }

  render(){
    return(
      <>
      { this.state.isLoading === true ? 
        <div id='loadingContainer' className='flex flex-col w-full h-screen justify-center gap-y-4'>
          <svg className="animate-spin h-12 w-12 text-rose-700 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span className='text-lg font-semibold text-gray-800 self-center text-center antialiased'>쿠폰 발행 중...</span>
        </div>
        : 
        <div id='container' className='flex flex-col w-full h-screen justify-center'>

          <div className='flex flex-row justify-center self-center'>
            <div className='flex flex-row rounded-md bg-gradient-to-r from-rose-200 via-indigo-500 to-rose-500 px-1 py-1 bg-animate'>
              <div className='bg-white px-2 py-2'>
              {/* TODO: (220309, @ssong) 실제 서버에 적용할 땐 URL 경로 수정 필요  */}
              <QRCode size={125} value={this.state.checkAddr} level='H' ref={this.qrRef} />
              </div>
            </div>
          </div>
          
          {/* 쿠폰 제공처 및 쿠폰 정보 */}
          <div class='flex flex-col justify-center mt-4'>
            <span className='text-sm font-semibold text-gray-400 self-center antialiased'>{this.state.shopName}</span>
            <span className='text-lg font-semibold text-gray-900 self-center antialiased'>{this.state.item}</span>
            {this.state.condition !== null ? ( 
              <span className='text-sm font-semibold text-red-600 self-center antialiased'>{this.state.condition}</span>
            ) : null}            
          </div>

          {/* 안내사항 */}
          <div class='flex flex-col justify-center mt-4'>
            <span className='text-base font-bold text-gray-900 self-center antialiased leading-8 '>포장{"&"}매장 이용시 사용 가능</span>
            <span className='text-sm text-center font-semibold text-gray-900 self-center antialiased'>쿠폰을 사용하기 위해 사장님께<br/> 해당 쿠폰을 제시하고 QR코드 스캔을 요청해주세요.</span>
          </div>

          <div class='flex flex-col justify-center mt-4'>
            <span className='text-sm font-bold text-gray-400 self-center antialiased leading-8 '>유효기간</span>
            {/* django에서 설정된 한달 이후의 값을 기반으로 설정하도록 */}
            <span className='text-base text-center font-semibold text-gray-900 self-center antialiased'>{this.state.dueDate}</span>
          </div>

          <div class='flex flex-col justify-center mt-8' ref={this.captureDiv}>
            <span className='text-base text-center font-semibold text-red-700 self-center antialiased'>이미지를 캡쳐하여 사용해주세요 🙂</span>
          </div>


          <footer id="footer" className='fixed bottom-0 w-full h-auto flex flex-row bg-rose-500 justify-center py-2.5' onClick={ () => this.captureTextControl()}>
            <span className='text-base font-bold text-white antialiased self-center' ref={this.captureText}>캡쳐 안내 문구 지우기</span>
          </footer>
        </div>
      }
      </>
    )
  }
}