import React, { Fragment, useState } from 'react'
import { Menu, Popover, Transition, Dialog } from '@headlessui/react'
import axios from 'axios';
import configData from 'pages/reactConfig'; 
import moment from 'moment';
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

const { CheckCircleIcon, XIcon, ExclamationCircleIcon } = require("@heroicons/react/outline");

export class PwChangeModal extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      
    }
    this.divRef = React.createRef(null) 
    this.contentDivRef = React.createRef(null) 
    this.currentPWRef = React.createRef(null) 
    this.changePWRef = React.createRef(null) 
    this.errorSpanRef = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){

  }

  modalHandler = (show) => {
    if (show === false){
      this.divRef.current.classList.add('hidden') 
      this.contentDivRef.current.classList.add('hidden') 
    }else{
      // Modal 등장 시 input value 초기화 
      this.currentPWRef.current.value = ''
      this.changePWRef.current.value = ''
      this.divRef.current.classList.remove('hidden') 
      this.contentDivRef.current.classList.remove('hidden') 
    }
  }

  // TODO: 변경 필요 
  passwordChange = async () => {
    const currentPW = this.currentPWRef.current.value; 
    const changePW = this.changePWRef.current.value; 

    if(currentPW === null || currentPW === undefined || currentPW === ''){
      this.errorSpanRef.current.classList.remove('hidden')
      this.errorSpanRef.current.innerHTML = '현재 패스워드를 입력해주세요'
      return false 
    }

    if(changePW === null || changePW === undefined || changePW === ''){
      this.errorSpanRef.current.classList.remove('hidden')
      this.errorSpanRef.current.innerHTML = '변경할 패스워드를 입력해주세요'
      return false 
    }

    if(currentPW == changePW){
      this.errorSpanRef.current.classList.remove('hidden')
      this.errorSpanRef.current.innerHTML = '현재 패스워드와 변경하는 패스워드가 동일합니다.'
      return false 
    }

    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}app/v1/users/account/password/change`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {'currentPW': currentPW, 'changePW':changePW}
    // marking 

    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        alert("비밀번호가 성공적으로 변경되었습니다.\n다음 로그인부터 변경된 비밀번호를 사용해주세요.")
        this.modalHandler(false)        
      }else{
        if (resData.reason === 'CURRENT_PASSWORD_NOT_MATCH'){
          alert("현재 비밀번호가 일치하지 않습니다.")
          return false 
        }else if (resData.reason == 'USER_NOT_EXIST'){
          alert("잘못된 접근입니다.")
          return false 
        }        
      }
    }catch(e){
      alert("데이터를 불러오는데 실패하였습니다.")
    }
  }

  render(){
    return(
      <div className='flex flex-col justify-center relative w-full h-screen hidden' ref={this.divRef}>
        <div className="py-12 transition-all duration-150 ease-in-out z-30 absolute top-64 left-0 right-0 bottom-0 hidden" ref={this.contentDivRef}>
          <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <div className="py-8 px-5 md:px-32 bg-white shadow-md rounded-lg border border-gray-400">
              <div className="w-full flex justify-center text-gray-600 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock" width={56} height={56} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x={5} y={11} width={14} height={10} rx={2} />
                  <circle cx={12} cy={16} r={1} />
                  <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </svg>
              </div>
              <h1 className="text-center text-gray-800 font-lg font-bold tracking-normal leading-tight mb-5">패스워드 변경하기</h1>
              <input type='password' ref={this.currentPWRef} className="mb-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="현재 패스워드" required />
              <input type='password' ref={this.changePWRef} className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="변경할 패스워드" required />
              <span className='text-sm font-medium text-red-600 hidden' ref={this.errorSpanRef}></span>
              <div className="flex items-center justify-center w-full mt-5">
                <button className="focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm" onClick={() => this.passwordChange()}>변경하기</button>
                <button className="focus:outline-none ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" onClick={() => this.modalHandler(false)}>
                    취소하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class NotiModal extends React.Component{
  constructor(props){
    super(props); 
    this.state = { 
      notiRef : React.createRef(null), 
      noti : null, 
      isLoading : true,
      init : true, 
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    this.getNotiMsg()
  }

  getNotiMsg = async() => {
    console.log('ok')
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}users/notification/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      const data = resData.data 
      data.forEach((element, index, object)=>{
        element['regDate'] = moment(element['regDate']).format('YYYY.MM.DD HH시 mm분')
      })
      this.state.init === true ? this.setState({noti:data, isLoading:false, init:false}) : this.setState({noti:data})
    }catch(e){
      console.log('noti data sync error') 
    }
    // const {data:result, status:res} = await axios.get(this.serverAddr+'/users/notification', 
    // {
    //   withCredentials: true, 
    //   headers:{
    //     'Content-Type' : 'application/json', 
    //     'Accept': 'application/json'
    //   }, 
    //   validateStatus: () => true 
    // }); 
    // if (res === 200){
    //   let notiData = result['data']; 
    //   // 날짜 데이터 형식 전처리 (211228, @ssong)
    //   notiData.forEach((element, index, object)=>{
    //     element['regDate'] = moment(element['regDate']).format('YYYY.MM.DD HH시 mm분')
    //   })
    //   this.state.init === true ? this.setState({noti:notiData, isLoading:false, init:false}) : this.setState({noti:notiData})
    // }else{
    //   console.log('error') 
    // }
  }

  notificationHandler(show){
    if (show === false){
      this.state.notiRef.current.classList.remove('translate-x-0') 
      this.state.notiRef.current.classList.add('translate-x-full') 
      setTimeout(() => {this.state.notiRef.current.classList.add('hidden')}, 100) 
    }else{
      this.getNotiMsg()
      this.state.notiRef.current.classList.remove('hidden') 
      setTimeout(() => {this.state.notiRef.current.classList.remove('translate-x-full')}, 100) 
      setTimeout(() => {this.state.notiRef.current.classList.add('translate-x-0')}, 100) 
    }
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <div className="w-8/12 absolute z-20 right-0 h-full overflow-x-hidden transform transition-all translate-x-full ease-in-out duration-500 hidden" ref={this.state.notiRef} id="notification">
          <div className="2xl:w-4/12 bg-gray-50 h-screen overflow-y-auto p-8 absolute right-0">
            <div className="flex items-center justify-between">
              <p className="text-2xl font-semibold leading-6 text-gray-800">Notifications</p>
              <div className="cursor-pointer" onClick={() => this.notificationHandler(false)}>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L6 18" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6 6L18 18" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
              </div>
            </div>
              {this.state.noti.map((noti) => (
                <>
                <div className="w-full p-3 mt-4 bg-white rounded flex items-center">
                  <div className="w-8 h-8 border rounded-full border-green-200 flex flex-shrink-0 items-center justify-center bg-green-200">
                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z" fill="#047857" />
                    </svg>
                  </div>
                  <div className="pl-3 w-full">
                    <div className="flex flex-col items-start justify-start gap-y-1">
                      <p className="text-sm leading-none text-gray-700">{noti.msg}</p>
                      <p className="text-xs leading-none text-gray-500">{noti.regDate}</p>
                    </div>
                  </div>
                </div>
                </>
              ))}

            <div className="flex items-center justiyf-between">
                <hr className="w-full" />
                <p className="text-sm flex flex-shrink-0 leading-normal px-3 py-8 text-gray-500">알림의 마지막입니다.</p>
                <hr className="w-full" />
            </div>
          </div>
        </div>
      )}
      </>
    )
  }; 
}

export default class Notification extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
      type : props.type, 
      show : props.show,
      subject : props.subject,
      text : props.text
    }
  }

  setShow = (isShow) => {
    this.setState({show:isShow}); 
  }

  setShowAutoManaulSet = (isShow, type, subject, text) => {
    this.setState({show:isShow, type:type, subject:subject, text:text}); 
    setTimeout(() => this.setShow(false), 3000); 
  }

  componentDidMount(){
    setTimeout(() => this.setShow(false), 3000); 
  }

  render(){
    return(
      <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={this.state.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {this.state.type === 'success' ? 
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> : 
                    <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{this.state.subject}</p>
                    <p className="mt-1 text-sm text-gray-500">{this.state.text}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => this.setShow(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
    )
  }
}