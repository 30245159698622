import React, { useState } from 'react'
import ScaIcon from '../../static/img/logo/screenAirLogo.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import configData from 'pages/reactConfig'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

export default class Authentication extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
      id : '', 
      password : '', 
      loginKeepChecker : false
    }; 
    this.errorSpan = React.createRef(null) 
    this.isCheckRef = React.createRef(null)
    this.serverAddr = configData['SERVER_URL']
    this.authForm = React.createRef(null) 
  }

  componentDidMount(){
    // (220124, @ssong) cookie inquiry 
    const keepLogin = localStorage.getItem('keepLogin')
    const screenAirAccessToken = localStorage.getItem('screenAirAccessToken')
    const screenAirRefreshToken = localStorage.getItem('screenAirAccessToken')
    if (keepLogin === 'true' && screenAirAccessToken !== null && screenAirRefreshToken !== null){
      const data = {access : screenAirAccessToken, refresh : screenAirRefreshToken}
      UserConfig.loginSet(data) 
      this.props.history.push('/cms/dashboard')
    }
  }

  handleChange = (e) =>{
    let value = e.target.value; 
    if (e.target.name === 'email'){
      this.setState({id : value})
    }else if (e.target.name === 'password'){
      this.setState({password : value})
    }
    if(this.errorSpan.current.classList.contains('hidden') === false){
      this.errorSpan.current.classList.add('hidden')
    }
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    this.login()
  }

  login = async() => {
    let id = this.state.id; 
    let pw = this.state.password; 
    const reqURI = `${HOST_ADDR}app/v1/users/login/`
    const data = {email:id, password:pw, fcmToken:null}
    try{
      const res = await axios.post(reqURI, data)
      const resData = res.data 
      UserConfig.loginSet(resData)
      if(this.state.loginKeepChecker === true){
        localStorage.setItem('keepLogin', true)
      }
      this.props.history.push('/cms/dashboard')
    }catch(error){
      const errorData = error.response 
      this.errorSpan.current.classList.remove('hidden') 
      this.errorSpan.current.innerHTML = '예기치 못한 시스템 오류가 발생하였습니다.\n 관리자에게 문의하여 주십시오.'
    }
  }

  loginKeep = (e) => {    
    if (this.isCheckRef.current.checked === true){
      this.setState({loginKeepChecker:true}, () => {
        alert("보안을 위해 공공장소가 아닌 개인 기기에서만 사용해주세요 :)")
      })
    }else{
      this.setState({loginKeepChecker:false})
    }
  }

  oAuth = () => {
    alert("다른 계정으로 로그인 기능은 준비중입니다.\n빠른 시일 내에 적용하도록 하겠습니다.")
  }

  render(){
    return(
      <div className='h-screen w-full'>
        <div className="min-h-full flex flex-row">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 h-screen relative">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-12 w-auto"
                  src={ScaIcon}
                  alt="Workflow"
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">ScreenAir 호스트 로그인</h2>
                {/* <p className="mt-2 text-sm text-gray-600">또는{' '}
                  <Link
                    to='/signup/'
                    className='font-medium text-indigo-600 hover:text-indigo-500'>
                      회원가입
                  </Link>
                </p> */}
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="#" className="space-y-6" ref={this.authForm} onSubmit={this.handleSubmit}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        이메일 주소 
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        패스워드
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange = {this.handleChange} 
                        />
                      </div>
                      <span className='text-sm font-medium text-red-600 hidden' ref={this.errorSpan}></span>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          ref={this.isCheckRef}
                          defaultChecked={this.state.loginKeepChecker}
                          onChange={(e) => this.loginKeep(e)}
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                          로그인 상태 유지하기 
                        </label>
                      </div>

                      <div className="text-sm">
                        <Link
                          to='/cms/passwordReset/'
                          className='font-medium text-indigo-600 hover:text-indigo-500'>
                            비밀번호를 잊어버리셨나요?
                        </Link>
                      </div>
                    </div>

                    <div>
                      <button 
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                        onSubmit = {this.handleSubmit}
                      >
                        로그인 
                      </button>
                      <p className="sm:block md:hidden text-xs text-gray-500 text-center mt-4">ⓒ 2022 Unique Inc. All Rights Reserved.</p>        
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='hidden md:absolute md:z-20 md:bottom-0 md:left-0 md:flex md:flex-col bg-gray-900 opacity-80 gap-y-1 w-full h-auto justify-center py-4'>
              <p className="text-sm text-white text-center">주식회사 유니크 | 대표 송기범</p>
              <p className="text-sm text-white text-center">주소: 대전 유성구 대학로 28 (봉명동 536-9) 홍인오피스텔 517호 </p>
              <p className="text-sm text-white text-center">이메일 uniquelab.co.ltd@gmail.com | 제휴 문의 uniquelab.co.ltd@gmail.com </p>
              <p className="text-sm text-white text-center">ⓒ 2022 Unique Inc. All Rights Reserved.</p>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
          {/* <div className='absolute bottom-0 z-20 flex flex-col bg-gray-700 opacity-90 h-auto w-full px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 gap-y-2'>
            <p className="mt-4 text-sm text-white text-center">상호명 주식회사 유니크 | 대표 송기범 | 주소: 대전 유성구 대학로 28 (봉명동 536-9) 홍인오피스텔 517호 </p>
            <p className="text-sm text-white text-center">이메일 uniquelab.co.ltd@gmail.com | 제휴 문의 uniquelab.co.ltd@gmail.com </p>
            <p className="text-sm text-white text-center">COPYRIGHTⓒ2022 Unique Inc. All Rights Reserved.</p>
          </div> */}
        </div>
      </div>
    )
  }
}
