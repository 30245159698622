import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
// Configuration 
import configData from 'pages/reactConfig'

export default function Footer(props){
  const {history} = props.props

  const movePage = (routeTarget) => {
    history.push(routeTarget, {})
  }

  return(
    <footer className="w-full bg-rose-500">
      <div className="flex flex-row flex-grow py-8 rounded-tl-lg rounded-tr-lg">
        <div className="container flex flex-col justify-start px-24 mx-auto">
          <div className="flex flex-row flex-wrap justify-start gap-x-4 md:gap-x-2 2xl:gap-x-4">
            <p className="text-xl text-white cursor-pointer font-nanum" onClick={() => movePage('/service/intro')}>회사소개</p>
            <p className="text-xl text-white font-nanum">|</p>
            <p className="text-xl text-white cursor-pointer font-nanum" onClick={() => movePage('/service/policies/term')}>이용약관</p>
            <p className="text-xl text-white font-nanum">|</p>
            <p className="text-xl text-white cursor-pointer font-nanum" onClick={() => movePage('/service/policies/privacy')}>개인정보 처리방침</p>
            <p className="text-xl text-white font-nanum">|</p>
            <p className="text-xl text-white cursor-pointer font-nanum" onClick={() => movePage('/service/partnerReq')}>제휴 문의</p>
            <p className="text-xl text-white font-nanum">|</p>
            <p className="text-xl text-white cursor-pointer font-nanum" onClick={() => movePage('/service/InquirySubmit')}>이용 문의</p>
          </div>
          <div className="flex flex-row w-full h-1 my-4 border-b-2 border-b-white"/> 
          <div className="flex flex-row flex-wrap justify-start my-1 gap-x-4">
            <p className="text-lg text-white font-nanum">주식회사 유니크</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">대표자: 송기범</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">사업자등록번호: 549-87-02426</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">통신판매신고번호: 2022-대전유성-1169</p>
          </div> 
          <div className="flex flex-row flex-wrap justify-start my-1 gap-x-4 ">
            <p className="text-lg text-white font-nanum">주소: 대전광역시 유성구 대학로 28, 지상4층 517호</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">개인정보보호책임자: 송기범</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">대표전화: 070-7762-7064</p>
            <p className="text-lg text-white font-nanum">|</p>
            <p className="text-lg text-white font-nanum">E-mail: support@uniqueinc.co.kr</p>
          </div> 
          <div className="flex flex-row flex-wrap justify-start mt-1 gap-x-4 ">
            <p className="text-lg text-white font-nanum">해당 서비스에 대한 책임과 민원처리는 '(주)유니크'에 있습니다.</p>
          </div> 
          <div className="flex flex-row flex-wrap justify-start my-1 gap-x-4 ">
            <p className="text-lg text-white font-nanum">Copyright © UNIQUE Inc. 2022 All Rights Reserved.</p>
          </div> 
        </div>
      </div>
    </footer>
  )
}

