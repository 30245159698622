import axios from "axios"
import { useEffect, useRef, useState } from "react"
import ServiceHeader from "servicePages/header"
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import Pikaso, {Settings} from 'pikaso'
import { BlockPicker, ChromePicker, CirclePicker, SwatchesPicker } from "react-color"
import { NullCheck, NumberCheck } from "servicePages/utility/dataValidation"
// https://github.com/pikasojs/pikaso

export default function CtnEdit(props){
  const {history} = props 
  const {mediaURL, type} = props.location.state
  const options = {} 
  const containerRef = useRef(null) 
  const [instance, setInstance] = useState(null) 
  const [color, setColor] = useState('#FFFFFF') 
  const [colorActive, setColorActive] = useState(false) 
  const [width, setWidth] = useState(768)
  const [height, setHeight]= useState(1440)
  const inputRefArr = useRef([])

  useEffect(() => {

    if (type === '가로형'){
      setWidth(1440)
      setHeight(768)
    }else{
      setWidth(768)
      setHeight(1440)
    }

    const editor = new Pikaso({
      container: containerRef.current, 
      ...options
    })

    const mediaURI = `${HOST_ADDR.slice(0, -1)}${mediaURL}`
    editor.loadFromUrl(mediaURI)
    setInstance(editor)  
  }, [])


  const addingText = async() => {
    const x = parseInt(width/2)
    const y = parseInt(height/2 )

    const text = inputRefArr.current[0].value 
    const size = inputRefArr.current[1].value 
    let check

    check = NullCheck(text, '텍스트')
    if (check.result === false){
      alert(check.msg)
      return false
    }

    check = NullCheck(size, '폰트 크기')
    if (check.result === false){
      alert(check.msg)
      return false
    }

    check = NumberCheck(size) 
    if (check.result === false){
      alert(check.msg)
      return false
    }

    const textLabel = await instance.shapes.label.insert({
      container: { x: x, y: y}, 
      text: {text: text, fontSize:size, fill:color}
    })

    console.log(instance)

    return true 
  }

  const removeText = async() => {
    instance.board.selection.delete()
  }

  const save = async() => {
    const res = await instance.export.toImage({})
    const a = document.createElement('a')
    a.href = res 
    a.download = "광고 이미지.png"
    a.click()
  }

  return(
    <>
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 
      {/* <a className="text-2xl cursor-pointer" onClick={() => testing()}>testing</a>
      <a className="text-2xl cursor-pointer" onClick={() => testing2()}>testing2</a> */}

        <div className={type === '세로형' ? 'flex flex-row justify-start container mx-auto' : 'flex flex-col justify-start container mx-auto'}>
        
          <div id="container" className="flex flex-col justify-start bg-gray-300 self-center" style={{width:`${width}px`, height:`${height}px`}} ref={(ref) => containerRef.current = ref}/> 

          {type === '가로형' ? (
            <>
              <div className='flex flex-row justify-center gap-x-4 h-16'>
                <div className="flex flex-row justify-center border border-black rounded-lg px-4 py-2 mt-4">
                  <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none" placeholder="텍스트를 입력해주세요." ref={(ref) => inputRefArr.current[0] = ref}/> 
                </div>

                <div className="flex flex-row justify-center border border-black rounded-lg px-4 py-2 mt-4">
                  <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none" placeholder="폰트 크기를 입력해주세요." ref={(ref) => inputRefArr.current[1] = ref}/> 
                </div>

                <div className="flex flex-row justify-center border border-black rounded-lg px-4 py-2 mt-4 relative cursor-pointer">
                  <div onClick={() => setColorActive(!colorActive)}>
                    <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none cursor-pointer" placeholder="폰트 컬러를 설정해주세요." value={color} disabled/> 
                  </div>
                  <ChromePicker className={ colorActive === true ? "absolute top-16 left-2" : 'hidden'} onChange={(color) => setColor(color.hex)} color={color} /> 
                </div>

                <div className="flex flex-row justify-center bg-indigo-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => addingText()}>
                  <p className="text-lg font-nexon text-white self-center"> 텍스트 추가하기</p> 
                </div>

                <div className="flex flex-row justify-center bg-rose-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => removeText()}>
                  <p className="text-lg font-nexon text-white self-center"> 선택한 텍스트 삭제</p> 
                </div>

                <div className="flex flex-row justify-center bg-green-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => save()}>
                  <p className="text-lg font-nexon text-white self-center">이미지 저장</p> 
                </div>
              </div>
            </>
          ) : 
            <>
              <div className='flex flex-col justify-center gap-y-4 pl-24'>
                <div className="flex flex-row justify-start border border-black rounded-lg px-4 py-2 mt-4 w-64">
                  <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none" placeholder="텍스트를 입력해주세요." ref={(ref) => inputRefArr.current[0] = ref}/> 
                </div>

                <div className="flex flex-row justify-start border border-black rounded-lg px-4 py-2 mt-4 w-64">
                  <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none" placeholder="폰트 크기를 입력해주세요." ref={(ref) => inputRefArr.current[1] = ref}/> 
                </div>

                <div className="flex flex-row justify-between border border-black rounded-lg px-4 py-2 mt-4 relative cursor-pointer">
                  <div onClick={() => setColorActive(!colorActive)}>
                    <input className="text-lg font-nexon text-black ring-0 border-0 ring-transparent outline-none cursor-pointer" placeholder="폰트 컬러를 설정해주세요." value={color} disabled/> 
                  </div>
                  <ChromePicker className={ colorActive === true ? "absolute left-72 -top-24" : 'hidden'} onChange={(color) => setColor(color.hex)} color={color} /> 
                </div>

                <div className="flex flex-row justify-center bg-indigo-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => addingText()}>
                  <p className="text-lg font-nexon text-white self-center"> 텍스트 추가하기</p> 
                </div>

                <div className="flex flex-row justify-center bg-rose-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => removeText()}>
                  <p className="text-lg font-nexon text-white self-center"> 선택한 텍스트 삭제</p> 
                </div>

                <div className="flex flex-row justify-center bg-green-500 rounded-lg px-4 py-2 mt-4 cursor-pointer" onClick={() => save()}>
                  <p className="text-lg font-nexon text-white self-center">이미지 저장</p> 
                </div>
              </div>
            </>
          }
      </div> 

    </>
  )
}