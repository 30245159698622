
export function BizNumCheck(data){
  if (data === '' || data === null || data === undefined){
    const res = {result: false, msg: '사업자 등록번호를 입력해주세요.'}
    return res 
  }

  if (isNum(data) === false){
    const res = {result: false, msg: '숫자만 입력해주세요'}
    return res 
  }

  if (data.length !== 10){
    const res = {result: false, msg: '10자리의 올바른 연락처를 입력해주세요.'}
    return res 
  }

  const res = {result: true}
  return res 
}

export function codeCheck(data){
  if (data === '' || data === null || data === undefined){
    const res = {result: false, msg: '인증 코드를 입력해주세요.'}
    return res 
  }

  if (data.length !== 6){
    const res = {result: false, msg: '6자리의 올바른 인증 코드를 입력해주세요.'}
    return res 
  }

  const res = {result: true}
  return res 
}

export function NullCheck(data, type){
  if (data === '' || data === null || data === undefined){
    const res = {result: false, msg: `${type}을(를) 입력해주세요.`}
    return res 
  }

  const res = {result: true}
  return res 
}

export function PhoneNumCheck(data){
  if (data === '' || data === null || data === undefined){
    const res = {result: false, msg: '연락처를 입력해주세요.'}
    return res 
  }

  if (isNum(data) === false){
    const res = {result: false, msg: '숫자만 입력해주세요.'}
    return res 
  }

  if (data.length !== 11){
    const res = {result: false, msg: '11자리의 올바른 연락처를 입력해주세요.'}
    return res 
  }

  const res = {result: true}
  return res 
}

export function NumberCheck(data){
  if (isNum(data) === false){
    const res = {result: false, msg: '숫자만 입력해주세요'}
    return res 
  }
  const res = {result: true}
  return res 
}

const isNum = (val) => {
  return !isNaN(val)
}