import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";

export default function SearchResult(props){
  const {history} = props
  const {keyword} = props.match.params
  const [shopList, setShopList] = useState([])
  const [statusText, setStatusText] = useState('') 
  useEffect(() => {
    getSearchResult()
  }, [keyword])

  const getSearchResult = async() =>{
    const reqURI = `${HOST_ADDR}app/v1/shops/search/${keyword}`
    try{
      const res = await axios.get(reqURI)
      const data = res.data.data 
      if(data === null){
        setShopList([]) 
        setStatusText('일치하는 매장을 찾지 못했어요.')
      }else{
        console.log(data)
        setShopList(data) 
        setStatusText(`${data.length}개의 제휴 매장을 찾았어요.`)
      }
    }catch(e){
      alert("알 수 없는 오류가 발생하였습니다.")
    }
  }

  const movePage = (shopPK) => {
    history.push(`/service/shops/${shopPK}`, {})
  }

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      
      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="flex flex-col flex-grow container mx-auto px-24 pt-12 h-max min-h-screen">
        <p className="text-2xl font-nexonBold self-start text-black">"{keyword}" 검색 결과</p>
        <p className="text-xl font-nexon self-start text-black pt-2">{statusText}</p>

        <div className="flex flex-col justify-start h-full">
          <div className="grid grid-cols-4 gap-x-4 gap-y-4 mt-8">
            {shopList.map((data, idx) => (
              <div className="flex flex-col justify-start rounded-xl cursor-pointer" onClick={() => movePage(data.idx)} key={idx}>
                <img className="w-64 h-64 rounded-2xl" loading="lazy" src={`${HOST_ADDR.slice(0, -1)}${data.thumb}`}/> 
                <div className="flex flex-row rounded-lg bg-rose-500 py-1 justify-center mt-4" style={{width: '25%'}}>
                  <p className="text-base font-nanumBold text-white self-center text-center">배너 광고</p>
                </div>
                <p className="font-nexon font-semibold text-lg text-black self-start pt-1 pl-0.5">{data.shopName}</p>
                <p className="text-base font-nexon text-gray-500 self-start pl-0.5 leading-normal truncate w-72">{data.shopAddr}</p>
                <p className="text-lg font-nexonBold font-semibold text-black self-start pl-0.5">월 {data.adPrice.toLocaleString()}원</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow h-full justify-end mt-24">
        <Footer props={props}/> 
      </div>
    </>
  )
}