import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import { getAcckey, isLogged } from "servicePages/config/userManager";
import Lottie from 'react-lottie'
import emptyAni from '../../../static/animation/emptyAni'

export default function WishList(props){
  const {history} = props
  const {keyword} = props.match.params
  const [statusText, setStatusText] = useState('') 
  const [likeList, setLikeList] = useState([])
  const [isLoading, setLoading] = useState(true) 

  useEffect(() => {
    const isLog = isLogged()
    if (isLog === true){
      const key = getAcckey() 
      dataInit(key) 
    }else{
      history.push('/service/login/')
    }
  }, [])

  const dataInit = async(key) => {
    const reqURI = `${HOST_ADDR}app/v1/users/account/myWishList/`
    const headers = {Authorization : `Bearer ${key}`}
    try{
      const res = await axios.get(reqURI, {headers: headers})
      const resData = res.data 
      setLikeList(resData.data)
      setLoading(false) 
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const movePage = () => {
    history.push('/service/shops/11', {})
  }

  const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: emptyAni,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
    <>
      {isLoading === true ? null : (
        <>
        {/* Header */}
        <div className="container mx-auto px-24 py-12">
          <div className="flex flex-col flex-grow">
            <ServiceHeader props={props}/> 
          </div> 
        </div> 
        
        <div className="border-t-2 border-t-gray-400 h-1"/> 

        <div className="flex flex-col container mx-auto px-24 pt-12 h-max min-h-screen">
          <p className="text-2xl font-nexonBold self-start text-black">나의 찜목록</p>
          <p className="text-xl font-nexon self-start text-black pt-2">{likeList.length === 0 ? "찜해둔 매장이 없어요." : `${likeList.length}개의 찜해둔 매장이 있어요.`}</p>

          
          {likeList.length !== 0 ? (
            <div className="flex flex-col justify-start gap-y-2">
              <div className="grid grid-cols-4 gap-x-4 mt-8">
                {likeList.map((data, idx) => (
                  <div className="flex flex-col justify-start rounded-xl cursor-pointer" onClick={() => movePage()} key={idx}>
                    <img className="w-64 h-64 rounded-2xl" loading="lazy" src={`${HOST_ADDR.slice(0, -1)}${data.thumb}`}/> 
                    <div className="flex flex-row rounded-lg bg-rose-500 py-1 justify-center mt-4" style={{width: '25%'}}>
                      <p className="text-base font-nanumBold text-white self-center text-center">{data.adType}</p>
                    </div>
                    <p className="font-nexon font-semibold text-lg text-black self-start pt-1 pl-0.5">{data.shopName}</p>
                    <p className="text-base font-nexon text-gray-500 self-start pl-0.5 leading-normal truncate w-72">{data.shopAddr}</p>
                    <p className="text-lg font-nexonBold font-semibold text-black self-start pl-0.5 pt-1">월 {data.price.toLocaleString()}원</p>
                  </div>
                ))}
              </div>
            </div>
          ) : ( 
            <div className="flex flex-row justify-center w-full h-96">
              <Lottie options={defaultOptions} width={400} height={400}/>
            </div> 
          )}
        </div>
        <div className="flex flex-col flex-grow justify-end self-end">
          <Footer props={props}/> 
        </div>
        </> 
      )}
    </>
  )
}