import React, { useState } from 'react'
import ScaIcon from '../../static/img/logo/screenAirLogo.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import configData from 'pages/reactConfig'

export default class MailAuthentication extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
      mail: null,
      username : null, 
      mailHash : this.props.match.params.mail,
      userHash : this.props.match.params.username, 
      isLoading : true 
    }; 
    this.authenCodeRef = React.createRef(null) 
    this.errorSpan = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){
    let url = this.serverAddr+'/users/signup/'+this.state.mailHash+'/'+this.state.userHash+'/'
    axios.defaults.withCredentials = true 
    axios.get(url,
      {
        withCredentials: true, 
        headers:{
          'Content-Type' : 'application/json',
          'Accept' : 'application/json'
        }
      }
    ).then((response) => {
      const data = response.data
      if (data['result'] === 'OK'){
        this.setState({mail:data['mail'], username:data['username'], isLoading:false})
      }else if(data['result'] === 'FAIL' && data['reason'] === 'ALREADY_AUTHENTICATED'){
        alert("유효하지 않는 페이지입니다.")
        this.props.history.push({
          pathname:'/'
        })
      }else{
        this.props.history.push({
          pathname:'/'
        })
      }
    })
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    let code = this.authenCodeRef.current.value; 
    // request token 
    let url = this.serverAddr+'/users/signup/'+this.state.mailHash+'/'+this.state.userHash+'/'
    let data = {'code' : this.authenCodeRef.current.value}; 
    axios.defaults.withCredentials = true 
    axios.post(url,data,
    {
      withCredentials: true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }).then((response) =>{
      const data = response.data 
      if (data['result'] === 'FAIL'){
        if(data['reason'] === 'CODE_NOT_MATCH'){
          this.errorSpan.current.innerHTML='인증코드가 일치하지 않습니다.'
          this.errorSpan.current.classList.remove('hidden') 
        }
      }else if(data['result'] === 'OK'){
        let redirecturl = data['redirecturl']
        this.props.history.push('/cms')
      }
    }).catch(error=>{
      this.errorSpan.current.innerHTML='예기치 못한 시스템 오류가 발생하였습니다.<br/>관리자에게 문의하여 주십시오.'
      this.errorSpan.current.classList.remove('hidden') 
    })
  }

  inputHandle(){
    const span = this.errorSpan.current
    if (span.classList.contains('hidden') === false){
      span.classList.add('hidden') 
    }
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <div className='h-screen w-full'>
          <div className="min-h-full flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                  <img
                    className="h-12 w-auto"
                    src={ScaIcon}
                    alt="Workflow"
                  />
                  <h2 className="mt-6 text-3xl font-extrabold text-gray-900">ScreenAir 호스트 가입 인증</h2>
                  <p className="mt-2 text-sm text-gray-600">{this.state.mail}에 전송된 인증코드를 입력해주세요</p>
                </div>


                <div className="mt-8">
                  <div className="mt-6">
                    <form action="#" method="#" className="space-y-6" onSubmit={this.handleSubmit}>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          인증 코드
                        </label>
                        <div className="mt-1">
                        <input
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder='메일로 수신한 인증코드를 입력해주세요'
                            ref={this.authenCodeRef}
                            onKeyUp={(e) => this.inputHandle()}
                          />
                        </div>
                        <span className='text-sm font-medium text-red-600 hidden' ref={this.errorSpan}>인증코드가 일치하지 않습니다.</span>
                      </div>

                      <div className="flex items-center justify-start">
                        <div className="text-sm">
                          <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            메일을 받지 못했습니다. 
                          </a>
                        </div>
                      </div>

                      <div>
                        <button 
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                          onSubmit = {this.handleSubmit}
                        >
                          인증하고 로그인하기
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
      </>
    )
  }
}
