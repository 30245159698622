import React, { Fragment, useRef, useState } from 'react'
import { Menu, Popover, Dialog, Switch, Transition } from '@headlessui/react'
import { AcademicCapIcon, BadgeCheckIcon, BellIcon, CashIcon, ClockIcon, MenuIcon, ReceiptRefundIcon, UsersIcon, XIcon, CalendarIcon} from '@heroicons/react/outline'
import { SearchIcon, QuestionMarkCircleIcon, ExclamationIcon } from '@heroicons/react/solid'
import ScheduleReg from './adScheReg'
import ScheduleMgr from './scheMgr'
// import TimeKeeper from 'react-timekeeper'
// https://reactjsexample.com/a-time-picker-react-component-no-jquery-rely/ Timepicker // new 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// const tabList = {
//   0: <ScheduleReg/>, 
//   1: <ScheduleMgr/>
// }; 


export default class ScheduleManager extends React.Component{
  constructor(props){
    super(); 
    this.state = { 
      tab : 0 
    }
    this.tabs = [
      { index: 0, name: '광고 등록', href: '#', current: true, ref:React.createRef()},
    ]
    this.tabList = { 
      0: <ScheduleReg/>, 
      // 1: <ScheduleMgr/>
    }
  }

  render(){
    return(
      <div className='h-full overflow-y-hidden'>
        <div className="relative mx-auto md:px-8 xl:px-0 h-full overflow-y-hidden">
          <div className="h-full overflow-y-hidden">
            {/* <div className="px-4 sm:px-6 md:px-0">
              <h1 className="text-3xl font-extrabold text-gray-900">Settings</h1>
            </div> */}
            <div className="px-4 sm:px-6 md:px-2 h-full overflow-y-hidden">
              <div className="py-2 h-full overflow-y-hidden">
                {/* Tabs */}
                <div className="lg:hidden">
                  <label htmlFor="selected-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="selected-tab"
                    name="selected-tab"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                    defaultValue={this.tabs.find((tab) => tab.current).name}>
                    {this.tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>

                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8">
                      {this.tabs.map((tab) => (
                        <a
                          key={tab.name}
                          className={classNames(
                            tab.current
                              ? 'border-purple-500 text-purple-600'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                          )}
                          ref={tab.ref}>
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                {/* Tab end */}
                {this.tabList[this.state.tab]}
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }
}
