import React, { createRef } from 'react'
import ScaIcon from '../../static/img/logo/screenAirLogo.png'
import axios from 'axios';
import { Link } from 'react-router-dom';
import configData from 'pages/reactConfig'

export default class Authentication extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      email : null, 
      name : null,
      password : null 
    }; 
    this.emailRef = React.createRef(null) 
    this.nameRef = React.createRef(null) 
    this.passRef = React.createRef(null) 
    this.pass2Ref = React.createRef(null) 
    this.checkRef = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    let email = this.emailRef.current.value; 
    let name = this.nameRef.current.value; 
    let password = this.passRef.current.value; 
    let password2 = this.pass2Ref.current.value; 
    let isCheck = this.checkRef.current.value; 
    
    if (password !== password2){
      alert("입력하신 패스워드가 서로 다릅니다.\n")
      return false
    }else{
      let url = this.serverAddr+'/users/signup/'
      let data = {
        email : email, 
        name : name, 
        password : password,
        type: 'screenAir',
      }
      axios.post(url, data, 
        {
          headers:{
            'Content-Type' : 'application/json',
            'Accept' : 'application/json'
          }
        }
      ).then((response) => {
        const data = response.data
        if (data['result'] === 'OK'){
          if (data['msg'] !== undefined){
            alert(data['msg'])
          }
          const redirecturl = data['redirect']
          this.props.history.push({
            pathname:redirecturl
          })
        }
      })

    }

  }

  render(){
    return(
      <div className='h-screen w-full'>
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-12 w-auto"
                  src={ScaIcon}
                  alt="Workflow"
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">ScreenAir 호스트 회원가입</h2>
                <p className="mt-2 text-sm text-gray-600">또는{' '}
                <Link
                  to='/'
                  className='font-medium text-indigo-600 hover:text-indigo-500'>
                    로그인
                </Link>
              </p>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                {/* onSubmit={} */}
                  <form action="#" method="#" className="space-y-5" onSubmit={(e) => this.handleSubmit(e)} >
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        이메일 주소
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder='이메일 주소를 입력해주세요'
                          ref={this.emailRef}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        회원 이름 
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder='회원님의 이름을 입력해주세요'
                          ref={this.nameRef}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        패스워드
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"                          
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder='패스워드를 설정해주세요'
                          // minLength={8}
                          ref={this.passRef}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        패스워드 확인
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          required
                          placeholder='패스워드를 다시 한번 입력해주세요'
                          // minLength={8}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          ref={this.pass2Ref}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex flex-row">
                        <input className='rounded-sm self-center' type='checkbox' required ref={this.checkRef}/>
                        <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 ml-2">
                          개인정보처리방침에 동의합니다. 
                        </a>
                      </div>

                      <div className="flex flex-row">
                        <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 ml-2">
                          자세히
                        </a>
                      </div>
                    </div>

                    <div>
                      <button 
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                        회원가입 
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}
