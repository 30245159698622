import React, { Fragment, useState } from 'react'
import { Menu, Popover, Transition, Dialog } from '@headlessui/react'
import _ from 'lodash'
import axios from 'axios';
import TimePicker from 'react-gradient-timepicker';
import configData from 'pages/reactConfig'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

export default class OperationTimeModal extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      open : false, 
      cancelButtonRef : React.createRef(null), 
      startTime : null, 
      endTime : null, 
      code : null, 
      isLoading : true, 
      operStartInput : React.createRef(null), 
      operEndInput : React.createRef(null), 
      callback : null,
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount(){

  }

  setOpen = (code, startTime, endTime, callback) =>{
    this.setState({code:code, startTime:startTime, endTime:endTime, open:true, isLoading:false, callback:callback})
  }

  setClose = () => {
    this.setState({open:false})
  }

  setOperTime = async () => {
    const code = this.state.code 
    const startTime = this.state.operStartInput.current.value
    const endTime = this.state.operEndInput.current.value
    const res = await this.operEdit(code, startTime, endTime)
    const status = res.status 
    this.setClose() 
    this.state.callback()
  }

  operEdit = async(code, startTime, endTime) =>{
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}devices/${code}/modifyOper/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {startTime : startTime, endTime: endTime}

    try{
      const res = await axios.put(reqURI, data, headers)
      const resData = res.data 
      return resData
    }catch(e){
      alert("운영 시간 변경 중 오류가 발생하였습니다.")
    }

    // const reqData = {'startTime':startTime, 'endTime':endTIme}
    // const result = await axios.put(this.serverAddr+'/devices/'+code+'/modifyOper/', reqData, 
    // {
    //   withCredentials: true, 
    //   headers:{
    //     'Content-Type' : 'application/json', 
    //     'Accept': 'application/json'
    //   }
    // }); 
    // return result
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <Transition.Root show={this.state.open} as={Fragment}>
        <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={this.setClose}>
          <div className="flex flex-col justify-center align-middle min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className='mt-96'/>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

              <div className='flex flex-row mx-auto justify-center self-center items-center align-middle bg-white rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 py-4'>
                <div className="flex items-center self-center flex-col w-full">
                  <div className='flex flex-row w-full'>
                    <div className="flex flex-col text-left ml-2">   
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      운영시간 변경하기 
                    </Dialog.Title>
                    <span className='text-gray-400 text-xs font-medium mb-4'>운영 시작시간과 종료시간을 설정해주세요</span>

                    <div className='flex flex-row justify-start gap-x-6 my-1'>
                      <span className='text-base font-normal text-gray-600 self-center'>시작시간</span>
                      <input type='time' className='w-48 rounded-lg text-base font-normal self-center cursor-pointer' 
                            defaultValue={this.state.startTime} ref={this.state.operStartInput}/>
                    </div>

                    <div className='flex flex-row justify-start gap-x-6 my-1'>
                      <span className='text-base font-normal text-gray-600 self-center'>종료시간</span>
                      <input type='time' className='w-48 rounded-lg text-base font-normal self-center cursor-pointer' 
                            defaultValue={this.state.endTime} ref={this.state.operEndInput}/>
                    </div>
                    
                    </div>
                  </div> 
                  <div className="flex flex-row w-full justify-end mt-2 gap-x-2">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => this.setClose(false)}
                      ref={this.cancelBtnRef}>
                      취소
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                      onClick={() => this.setOperTime()}>
                      설정
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>

        </Dialog>
      </Transition.Root>
      )}
      </>
    )
  }
}
