import React, {  } from 'react'
import { ViewGridIcon as ViewGridIconSolid, ViewListIcon,} from '@heroicons/react/solid'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/ko'
import _ from 'lodash'
import 'dropzone/dist/dropzone.css'
import 'react-dropzone-component/styles/filepicker.css'
import Notification from 'pages/util/notification'
import configData from 'pages/reactConfig'
import {HOST_ADDR, SERVER_URL} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class ContentRepository extends React.Component{
  constructor(props){
    super(props); 
    const userinfo = UserConfig.getUserInfo() 
    const userAuth = userinfo.userAuth 
    let tab; 
    let option; 
    if (userAuth === 'admin'){
      tab = [
        { name: '매장 콘텐츠', href: '#', current: true },
        { name: '광고 콘텐츠', href: '#', current: false },
        { name: '모두 보기', href: '#', current: false },
      ]
    }else{
      tab = [{ name: '매장 콘텐츠', href: '#', current: true },]
    }

    if (userAuth === 'admin'){
      option = [
        {value : 1, label: "매장 콘텐츠"},
        {value : 2, label: "광고 콘텐츠"},
        {value : 3, label: "모두 보기"},
      ]
    }else{
      option = [{value : 1, label: "매장 콘텐츠"}]
    }
    
    this.serverAddr = configData['SERVER_URL']
    this.state = {
      contents : null, 
      currentContents : null, 
      isLoading : true, 
      host : this.serverAddr,
      tabs : tab, 
      notiShow : false, 
      notiSubject : null, 
      notiText : null, 
      notiType : 'exclamation', 
      userAuth: userAuth
    }
    this.options = option
    this.spinRef = React.createRef(null) 
    this.adSpinRef = React.createRef(null) 
    this.hiddenFileInput = React.createRef(null) 
    this.AdHiddenFileInput = React.createRef(null) 
    this.uploadBtn = React.createRef(null)
    this.AdUploadBtn = React.createRef(null)
  }

  async componentDidMount(){
    this.contentUpdate() 
  }

  contentUpdate = async (tabindex=0) =>{
    const {data, isLoading, error} = await this.getContents(); 
    if(isLoading){
      console.log('isLoading'); 
    }else if(error){
      console.log('content info loading error'); 
      // Need to alert and redirect function 
    }else if(data){
      let tabslist = _.cloneDeep(this.state.tabs)
      // marking 
      tabslist.forEach((element, index, object)=>{
        if (tabindex === index){
          element['current'] = true; 
        }else{
          element['current'] = false; 
        }
      })
      this.setState({contents:data, currentContents:data, isLoading:false})
      this.tabHandle(tabindex) 
    }
  }

  bytesToSize = (bytes) =>{
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  koDateFormat = (date) => {
    return moment(date).format("YYYY년 MM월 DD일 hh시 mm분")
  }

  getContents = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}contents/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      console.log(resData)
      return resData
    }catch(e){
      alert("콘텐츠 데이터를 불러오는데 실패하였습니다.")
    }
  }

  ctnSelect = (idx) => {
    let count = 0; 
    let currentContents = this.state.currentContents
    currentContents.forEach(element =>{
      if (count === idx){
        element['current'] = true 
      }else{
        element['current'] = false 
      }
      count++; 
    })
    this.setState({currentContents:currentContents})
  }

  tabHandle = (idx) => {
    this.setState({isLoading:true}, () => {

      const allCtn = _.cloneDeep(this.state.contents)
      let tabsCopy = this.state.tabs;
      let currentCtn = allCtn; 

      // !권한에 따라 파트너사는 매장 콘텐츠만 보도록 설정 필요   
      if (this.state.userAuth !== 'admin'){
        idx = 0 
      }

      if (idx === 0){
        currentCtn = currentCtn.filter(currentCtn => currentCtn.contentType.indexOf('매장 콘텐츠') !== -1)
      }else if (idx === 1){
        currentCtn = currentCtn.filter(currentCtn => currentCtn.contentType.indexOf('광고 콘텐츠') !== -1)
      }

      if (currentCtn.length === 0){
        // Notification 
        if (idx === 0){
          this.setState({isLoading:false, notiType:'exclamation', notiShow:true, notiSubject:"콘텐츠 보관함 알림", notiText:"매장 콘텐츠가 비어있으므로 모두 보기 탭으로 전환합니다."})
          setTimeout(() => this.setState({notiShow:false}), 100)
        }else if (idx === 1){
          this.setState({isLoading:false, notiType:'exclamation', notiShow:true, notiSubject:"콘텐츠 보관함 알림", notiText:"광고 콘텐츠가 비어있으므로 모두 보기 탭으로 전환합니다."})
          setTimeout(() => this.setState({notiShow:false}), 100)
        }
        idx = 2
      }


      if(idx === 0){
        // 매장 콘텐츠 
        //currentCtn = allCtn
        currentCtn.forEach(element=>{
          element['current'] = false
        })
        currentCtn[0]['current'] = true 

      }else if(idx === 1){
        // 광고 콘텐츠 
        //currentCtn = allCtn
        currentCtn.forEach(element=>{
          element['current'] = false
        })
        currentCtn[0]['current'] = true 
      }else if (idx === 2){
        // 전체 콘텐츠 
        currentCtn = allCtn
        if (currentCtn.length === 0){
          // 현재 탭 표현 (211213, @ssong) 
          tabsCopy.forEach(element=>{
            element['current'] = false 
          })

          this.setState({tabs:tabsCopy, currentContents:currentCtn, isLoading:false}, ()=>{
            console.log(this.state.currentContents)
          })

          return false; 
        }else{
          tabsCopy[idx]['current'] = true 
        }

        currentCtn.forEach(element=>{
          element['current'] = false
        })
        
        currentCtn[0]['current'] = true 
      }
      // 현재 탭 표현 (211213, @ssong) 
      tabsCopy.forEach(element=>{
        element['current'] = false 
      })


      if (currentCtn.length !== 0){
        tabsCopy[idx]['current'] = true 
      }

      this.setState({tabs:tabsCopy, currentContents:currentCtn, isLoading:false}, ()=>{
        console.log(this.state.currentContents)
      })
    })
  }
  

  ctnDelete = async (idx, contentType) => {
    let dataTarget = null 
    if (contentType === '매장 콘텐츠'){
      dataTarget = 'normal'
    }else{
      dataTarget = 'ad'
    }

    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}contents/${idx}/${dataTarget}/delete/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const data = {target : dataTarget}
      const res = await axios.delete(reqURI, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        let tabindex = null 
        this.state.tabs.forEach((element, index, object) => {
          if (element['current'] === true){
            tabindex = index; 
          }
        })
        this.contentUpdate(tabindex)         
      }
    }catch(e){
      alert("콘텐츠 삭제에 실패하였습니다.")
    }
  }

  handleNormalContentUpload = async(e) => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}contents/upload/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    this.spinRef.current.classList.remove('hidden') 
    this.spinRef.current.classList.add('animate-spin') 
    const fileLen = e.target.files.length;
    let successCount = 0; 
    this.uploadBtn.current.disabled = true 
    if (fileLen === 0){
      this.uploadBtn.current.disabled = false
    }

    for(let i =0; i < fileLen; i++){
      let fileData = e.target.files[i]; 
      const data = new FormData() 
      data.append('binary', fileData) 

      try{
        const res = await axios.post(reqURI, data, headers)
        const resData = res.data 
        if (resData.result === 'OK'){
          successCount += 1; 
          if (successCount === fileLen){
            this.uploadBtn.current.disabled = false
            this.spinRef.current.classList.add('hidden') 
            this.spinRef.current.classList.remove('animate-spin') 

            let tabindex = null 
            let tabslist = _.cloneDeep(this.state.tabs)
            tabslist.forEach((element, index, object)=>{
              if(element['current'] === true){
                tabindex = index 
              }
            })
            this.setState({notiType:'success', notiShow:true, notiSubject:"콘텐츠 보관함 알림", notiText:"콘텐츠 업로드가 완료되었습니다."})
            setTimeout(() => this.setState({notiShow:false}), 100)
            this.contentUpdate(tabindex) 
          }          
        }else{
          alert("콘텐츠 업로드에 실패하였습니다.")
          this.uploadBtn.current.disabled = false
          this.spinRef.current.classList.add('hidden') 
          this.spinRef.current.classList.remove('animate-spin') 
          return false 
        }
      }catch(e){
        alert("콘텐츠 업로드에 실패하였습니다.")
        this.uploadBtn.current.disabled = false
        this.spinRef.current.classList.add('hidden') 
        this.spinRef.current.classList.remove('animate-spin') 
      }
    }
  }

  // marking 
  handleAdFileUpload = async(e) => {
    console.log('tesing')
    console.log(e) 
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}app/v1/appAD/web/upload/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    this.adSpinRef.current.classList.remove('hidden') 
    this.adSpinRef.current.classList.add('animate-spin') 

    const fileLen = e.target.files.length;
    let successCount = 0; 
    this.AdUploadBtn.current.disabled = true 
    if (fileLen === 0){
      this.AdUploadBtn.current.disabled = false
    }

    for(let i = 0; i < fileLen; i++){
      const fileData = e.target.files[i] 
      const data = new FormData() 
      data.append('binary', fileData)

      try{
        const res = await axios.post(reqURI, data, headers)
        const resData = res.data 
        if (resData.result === 'OK'){
          successCount += 1  
          if (successCount === fileLen){
            this.AdUploadBtn.current.disabled = false
            this.adSpinRef.current.classList.add('hidden') 
            this.adSpinRef.current.classList.remove('animate-spin') 

            let tabindex = null 
            let tabslist = _.cloneDeep(this.state.tabs)
            tabslist.forEach((element, index, object)=>{
              if(element['current'] === true){
                tabindex = index 
              }
            })
            this.setState({notiType:'success', notiShow:true, notiSubject:"콘텐츠 보관함 알림", notiText:"콘텐츠 업로드가 완료되었습니다."})
            setTimeout(() => this.setState({notiShow:false}), 100)
            this.contentUpdate(tabindex) 
          }
        }else{
          alert("콘텐츠 업로드에 실패하였습니다.")
          this.uploadBtn.current.disabled = false
          this.spinRef.current.classList.add('hidden') 
          this.spinRef.current.classList.remove('animate-spin') 
          return false 
        }
      }catch(e){
        alert("광고 콘텐츠 업로드에 실패하였습니다.")
        this.AdUploadBtn.current.disabled = false
        this.adSpinRef.current.classList.add('hidden') 
        this.adSpinRef.current.classList.remove('animate-spin') 
      }
    }

  }

  handleFileBtn = (e) =>{
    this.hiddenFileInput.current.click() 
  }

  handleAdFileBtn = (e) =>{
    this.AdHiddenFileInput.current.click() 
  }

  render(){
    const isLoading = this.state.isLoading;
    return(
      <>
      {isLoading ? "" : 
        <div className="flex h-full px-1 mt-2">
        <Notification show={this.state.notiShow} subject={this.state.notiSubject} text={this.state.notiText} type={this.state.notiType}/> 
        {/* Content area */}
        <div className="flex flex-col flex-1 overflow-hidden">
          {/* Main content */}
          <div className="flex items-stretch flex-1 overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="w-full px-2 pt-4 mx-auto md:px-0">
                <div className="flex">
                  <h1 className="flex-1 text-2xl font-bold text-gray-900">콘텐츠 보관함</h1>
                  <div className="ml-6 bg-gray-100 p-0.5 rounded-lg flex items-center hidden">
                    <button type="button" className="p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">  
                      <ViewListIcon className="w-5 h-5" aria-hidden="true" />
                      <span className="sr-only">Use list view</span>
                    </button>
                    <button type="button" className="ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" >
                      <ViewGridIconSolid className="w-5 h-5" aria-hidden="true" />
                      <span className="sr-only">Use grid view</span>
                    </button>
                  </div>
                </div>

                <div className="pt-4 bg-gray-100 p-0.5 rounded-lg items-center flex lg:hidden">
                  {/* <ContentUpload/> */}
                  {/* marking  */}
                  <button type="button" ref={this.uploadBtn} onClick={this.handleFileBtn} className="inline-flex p-1.5 rounded-md bg-indigo-700 text-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <svg className="mr-2 h-5 w-5 mt-0.5 text-white hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.spinRef}>
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    매장 콘텐츠 업로드
                    <input
                      type='file'
                      ref={this.hiddenFileInput}
                      onChange={this.handleNormalContentUpload}
                      accept="video/*, image/*"
                      className='hidden'
                      multiple/> 
                  </button>

                  <button type="button" ref={this.AdUploadBtn} onClick={this.handleAdFileBtn} className={ this.state.userAuth === 'admin' ? "inline-flex p-1.5 rounded-md bg-rose-500 text-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ml-4" : 'hidden'}>
                    <svg className="mr-2 h-5 w-5 mt-0.5 text-white hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.adSpinRef}>
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    광고 콘텐츠 업로드
                    <input
                      type='file'
                      ref={this.AdHiddenFileInput}
                      onChange={this.handleAdFileUpload}
                      accept="video/*, image/*"
                      className='hidden'
                      multiple/> 
                  </button>
                </div>

                {/* Tabs */}
                <div className="mt-3 sm:mt-2">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only"> Select a tab </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  </div>
                  <div className="block">
                    <div className="flex items-center border-b border-gray-200">
                      <nav className="flex flex-1 -mb-px space-x-6 xl:space-x-8" aria-label="Tabs">
                        {this.state.tabs.map((tab, idx) => (
                          <a key={idx} href={tab.href} aria-current={tab.current ? 'page' : undefined} onClick={() => this.tabHandle(idx)} className={classNames(
                            tab.current
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                      <div className="ml-6 bg-gray-100 p-0.5 rounded-lg items-center hidden lg:flex">
                        {/* <ContentUpload/> */}
                        {/* marking  */}
                        <button type="button" ref={this.uploadBtn} onClick={this.handleFileBtn} className="inline-flex p-1.5 rounded-md bg-indigo-700 text-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <svg className="mr-2 h-5 w-5 mt-0.5 text-white hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.spinRef}>
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          매장 콘텐츠 업로드
                          <input
                            type='file'
                            ref={this.hiddenFileInput}
                            onChange={this.handleNormalContentUpload}
                            accept="video/*, image/*"
                            className='hidden'
                            multiple/> 
                        </button>

                        <button type="button" ref={this.AdUploadBtn} onClick={this.handleAdFileBtn} className={ this.state.userAuth === 'admin' ? "inline-flex p-1.5 rounded-md bg-rose-500 text-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ml-4" : 'hidden'}>
                          <svg className="mr-2 h-5 w-5 mt-0.5 text-white hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ref={this.adSpinRef}>
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          광고 콘텐츠 업로드
                          <input
                            type='file'
                            ref={this.AdHiddenFileInput}
                            onChange={this.handleAdFileUpload}
                            accept="video/*, image/*"
                            className='hidden'
                            multiple/> 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gallery */}
                <section className="pb-16 mt-8 ml-2 mr-2" aria-labelledby="gallery-heading">
                  <h2 id="gallery-heading" className="sr-only">
                    Recently viewed
                  </h2>
                  {this.state.currentContents.length === 0 ? (
                  <div className='flex flex-col items-center self-center mt-4 text-gray-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className='self-center mt-4 text-xl antialiased font-extrabold text-gray-500'>등록된 콘텐츠가 없습니다.</span>
                    <span className='self-center text-xl antialiased font-extrabold text-gray-500'>콘텐츠를 업로드해주세요.</span>
                  </div>
                  ) : (
                    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                      {this.state.currentContents.map((content, idx) => (
                        <li key={idx} className="relative">
                          <div
                            className={classNames(
                              content.current
                                ? 'ring-2 ring-offset-2 ring-indigo-500'
                                : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                              'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
                            )}>
                            <img src={ content.thumbnail === null ? SERVER_URL + content.contentpath : SERVER_URL + content.thumbnail}
                                alt=""
                                loading='lazy'
                                className={classNames(content.current ? '' : 'group-hover:opacity-75','object-cover pointer-events-none contain')}/>
                              <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => this.ctnSelect(idx)}>
                                <span className="sr-only"> View details for {content.filename}</span>
                              </button>
                          </div>
                          <p className="block mt-2 text-sm font-medium text-gray-900 truncate pointer-events-none">
                            {content.filename}
                          </p>
                          <p className="block text-sm font-medium text-gray-500 pointer-events-none">{this.bytesToSize(content.size)}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </section>
              </div>
            </main>

            {/* Details sidebar */}
            {
              this.state.currentContents.map((content, idx) =>
              content.current === false ? "" : 
                <aside className="sticky top-0 hidden p-8 mt-4 ml-4 overflow-y-hidden bg-white border-l border-gray-200 rounded-lg h-6/12 w-96 lg:block md:block" key={idx}>
                  <div className="space-y-6">
                    <div>
                      <div className="block w-full overflow-hidden rounded-lg aspect-w-10 aspect-h-7">
                        <img src={content.thumbnail === null ? SERVER_URL+content.contentpath : SERVER_URL+content.thumbnail} alt="" className="object-cover" />  
                      </div>
                      <div className="flex items-start justify-between mt-4">
                        <div>
                          <h2 className="text-lg font-medium text-gray-900">
                            <span className="sr-only">Details for </span>
                            {content.filename}
                          </h2>
                          <p className="text-sm font-medium text-gray-500">{this.bytesToSize(content.size)}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Information</h3>
                      <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                      <div className='flex justify-between py-3 text-sm font-medium'>
                          <dt className="text-gray-500">콘텐츠 타입</dt>
                          <dd className="text-gray-900">{content.contentType}</dd>
                        </div> 
                        <div className='flex justify-between py-3 text-sm font-medium'>
                          <dt className="text-gray-500">콘텐츠 파일 타입</dt>
                          <dd className="text-gray-900">{content.contentFileType}</dd>
                        </div> 
                        <div className='flex justify-between py-3 text-sm font-medium'>
                          <dt className="text-gray-500">해상도</dt>
                          <dd className="text-gray-900">{content.resolution}</dd>
                        </div> 
                        <div className='flex justify-between py-3 text-sm font-medium'>
                          <dt className="text-gray-500">업로드 날짜</dt>
                          <dd className="text-gray-900">{this.koDateFormat(content.uploaded)}</dd>
                        </div> 
                      </dl>
                    </div>
                    <div className="flex">
                      <a
                        className="flex-1 px-4 py-2 text-sm font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        href={this.state.host+content.contentpath}
                        download
                        target="_blank">
                        다운로드 
                      </a>
                      <a
                        type="button"
                        className="flex-1 px-4 py-2 ml-3 text-sm font-medium text-center text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => this.ctnDelete(content.idx, content.contentType)}>
                        삭제하기
                      </a>
                    </div>
                  </div>
                </aside>
              )
            }

          </div>
        </div>
        </div>
      }

    </>
    )
  }
}
