import React, { useState } from 'react'
import { data } from 'autoprefixer';
import axios from 'axios';
import { useAsync } from 'react-async';
import Topcontainer from '../mixins/container/top/top.js'; 
import Sidenav from '../mixins/sidenav/sidenav.js';
import { NotiModal } from 'pages/util/notification'
import { TopStat, AdStat, ApexChart } from './container/main/main.js'; 
import { PwChangeModal } from 'pages/util/notification'
import configData from 'pages/reactConfig'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

export default class Dashboard extends React.Component{
  constructor(props){
    super(props)
    this.state={
      isLoading : true, 
      userAuth : null 
    }
    this.notiRef = React.createRef(null) 
    this.serverAddr = configData['SERVER_URL']
    this.sideNavDivRef = React.createRef(null)
    this.mainDivRef = React.createRef(null)
  }
      
  async componentDidMount(){
    const reqURI = `${HOST_ADDR}users/auth/`
    const userToken = UserConfig.getAccKey() 
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data
      const {auth} = resData 
      this.setState({isLoading:false, userAuth:auth})
    }catch(e){
      console.log(e) 
      alert("세션이 만료되었습니다.\n")
      localStorage.clear()
      UserConfig.logout()
      // TODO: logout logic 
      this.props.history.push({
        pathname:'/cms/'
      })
    }
  }

  render(){
    return(
      <>
      {this.state.isLoading === true ? "" : (
        <div className="h-screen flex flex-row">
          <div className='md:w-80 md:block md:opacity-100 md:static absolute z-20 w-0 opacity-0 transition-all transform duration-300 delay-100 ease-in-out' ref={this.sideNavDivRef}>
            <Sidenav />
          </div>
          {/* bg-gray-100 기준점  */}
          <div className='w-full bg-gray-100 md:overflow-hidden overflow-visible transition-all transform duration-300 delay-100 ease-in-out' ref={this.mainDivRef}>
            <NotiModal ref={this.notiRef} /> 
            <div className='container mx-auto sm:px-6 lg:px-8 py-6'>
              <Topcontainer notiRef={this.notiRef} sideNavDiv={this.sideNavDivRef} mainDiv={this.mainDivRef} />
              <TopStat /> 
              <AdStat />
              {this.state.userAuth !== 'admin' ? null : (
                <ApexChart />
              )}
            </div>
          </div>
        </div>    
      )}
      </>
    )
  }
}