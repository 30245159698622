import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import Lottie from 'react-lottie'
import paySuccess from '../../static/animation/paySuccess'
import progressAni from '../../static/animation/progress'
import UserConfig from "servicePages/config/userManager";
import { DeleteData } from "servicePages/config/indexedDB";

export default function PayComplete(props){
  const {history} = props
  const {data} = props.location.state 
  const {adContent, adName, adSchedule} = data 
  const {imp_uid, merchant_uid, method, userPK, totalPrice, product, realPrice, couponPK, shopPK} = data 
  const [progress, setProgress] = useState('progress')
  const [stateText, setStateText] = useState(null)

  useEffect(() => {
    const token = UserConfig.getAccKey()
    contentSync(token)
  }, [])

  const movePage = (target) => {
    history.push('/service', {})
  }

  const successAni = {
    loop: true,
    autoplay: true, 
    animationData: paySuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const progressANi = {
    loop: true,
    autoplay: true, 
    animationData: progressAni,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const contentSync = async(token) => {
    setStateText("콘텐츠를 전송입니다.\n브라우저를 종료하지말고 잠시만 기다려주세요.")
    const reqURI = `${HOST_ADDR}app/v1/appAD/upload/`
    const headers = { headers : {Authorization:`Bearer ${token}`}}
    const content = adContent.base64.split(';base64')[1] 
    const data = {contentName:adName, extension:adContent.ext, resolution:`${adContent.width}x${adContent.height}`, shopPK:shopPK, content:content, type:adContent.type}
    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        const ctnPK = resData.data 
        setStateText("콘텐츠 전송 완료!\n남은 작업 마무리 진행 중입니다.")
        await paymentSync(ctnPK, token) 
      }
    }catch(e){
      console.log(e) 
      setStateText("예기치 못한 오류가 발생하였습니다.\n결제된 금액을 취소합니다.")
      // TODO: 환불 request 
    }
  }

  const paymentSync = async(ctnPK, token) => {
    const reqURI = `${HOST_ADDR}app/v1/payment/ad/`
    const data = {imp_uid:imp_uid, merchant_uid:merchant_uid, method:method, userPK:userPK, totalPrice:totalPrice, shopPK:shopPK, product:product, realPrice:realPrice, couponPK:couponPK}
    const headers = { headers : {Authorization:`Bearer ${token}`}}
    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        const paymentPK = resData.data 
        await scheduleReg(ctnPK, paymentPK, token)
      }
    }catch(e){
      console.log(e) 
      setStateText("예기치 못한 오류가 발생하였습니다.\n결제된 금액을 취소합니다.")
      // TODO: 환불 request 
    }
  }

  const scheduleReg = async(ctnPK, paymentPK, token) => {
    const reqURI = `${HOST_ADDR}app/v1/appAD/setSchedule/`
    const data = {adSchedule: adSchedule, adName:adName, contentPK:ctnPK, paymentPK:paymentPK, shopPK:shopPK}
    const headers = { headers : {Authorization:`Bearer ${token}`}}
    try{
      const res = await axios.post(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        setProgress('done')
        await DeleteData()
        localStorage.removeItem('tempData')
      }
    }catch(e){
      setStateText("예기치 못한 오류가 발생하였습니다.\n결제된 금액을 취소합니다.")
      // TODO: 환불 request 
    }
  }

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="flex flex-col flex-grow justify-start container mx-auto px-24 pt-28 min-h-screen h-max">
        <p className="text-3xl font-nexonBold self-center">결제가 완료되었습니다.</p>
        {progress === 'progress' ? (
          <>
          <div className="flex flex-row justify-center w-full h-96 -mt-12">
            <Lottie options={progressANi} width={400} height={400}/>
          </div> 
          <p className="text-xl font-nexon self-center py-1">{stateText}</p>
          <div className="flex flex-row rounded-lg bg-rose-500 py-2 w-3/12 self-center justify-center mt-8 cursor-pointer" onClick={() => movePage()}>
            <p className="text-xl font-nexon self-center  text-white">메인 페이지로 돌아가기</p>
          </div>
          </>
        ) : ( 
          <>
          <div className="flex flex-row justify-center w-full h-96 -mt-12">
            <Lottie options={successAni} width={400} height={400}/>
          </div> 
          <p className="text-xl font-nexon self-center py-1">매장에서 콘텐츠를 검토하고 있습니다.</p>
          <p className="text-xl font-nexon self-center">자세한 진행사항은 나의 구매내역에서 확인하실 수 있습니다.</p>
          <p className="text-xl font-nexon self-center pt-2">예상 소요일: 1영업일</p>
          <div className="flex flex-row rounded-lg bg-rose-500 py-2 w-3/12 self-center justify-center mt-8 cursor-pointer" onClick={() => movePage()}>
            <p className="text-xl font-nexon self-center  text-white">메인 페이지로 돌아가기</p>
          </div>
          </>
        )}

      </div>

      <div className="flex flex-col flex-grow self-end">
        <Footer props={props}/> 
      </div>
    </>
  )
}