import React, { Component, useState } from 'react';
import { getYear, getMonth } from 'date-fns'  
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { ko } from 'date-fns/esm/locale'
import _ from 'lodash' 

export default class DatePickerCustom extends Component{
  constructor(props){
    super(props);
    this.state = { 
      startDate : new Date(), 
    }
  }

  render(){
    const years = _.range(1990, getYear(new Date()) +1, 1)
    const months = [ 
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    return (
      <DatePicker
        locale={ko}
        showPopperArrow={true} 
        selected={this.state.startDate}
        onChange = {
          (date)=>this.setState({
            startDate : date 
          })
        }
        className='grid-row'
        calendarClassName='rasta-stripes'
      />
    )
  }
}