import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import { emailVerify, getBirth, passwordVerify } from "./util";
import { ApiKeyRepo } from "servicePages/config/apiKeyRepo";
import { BizNumCheck, codeCheck, NullCheck, PhoneNumCheck } from "servicePages/utility/dataValidation";

export default function SignupPartner(props){
  const {history} = props 
  const inputRefArr = useRef([])
  const inputErrRefArr = useRef([])
  const [authActive, setAuthActive] = useState(false)
  const [partnerAuth, setPartnerAuth] = useState(false) 
  const [count, setCount] = useState('3:00')
  const [second, setSecond] = useState(180)
  const [smsPK, setSMSPK] = useState(null) 
  const [smsReqCount, setSMSReqCount] = useState(3) 
  const [vefPhone, setVefPhone] = useState(null) 
  const [phoneAuth, setPhoneAuth] = useState(false) 
  const [shopName, setShopName] = useState(null)
  const checkRefArr = useRef([]) 

  useEffect(() => {

  }, [])

  // SMS Auth counting 
  useEffect(() => {
    if (authActive === true && second >= 0 ){
      const counting = setInterval(() => {
        let min = Math.floor(second / 60) 
        let sec = second - min * 60 
        if (sec < 10){
          sec = '0'+sec 
        }
        setCount(`${min}:${sec}`)
        setSecond(second-1)
      }, 1000)
      return () => clearInterval(counting)
    }
  }, [authActive, second])

  const movePage = (routeTarget) => {
    history.push(routeTarget, {})
  }

  const submitEdit = (e, idx) => {

    // 인증 코드 
    if (idx === 0){
      if (e.key === 'Enter'){
        codeAuth()
        return false 
      }
    }
    
    if (inputErrRefArr.current[idx].classList.contains('hidden') === false){
      inputErrRefArr.current[idx].classList.add('hidden')
    }

    if (e.key === 'Enter'){
      if (idx === 6){
        auth() 
        return false 
      }

      // SMS 인증번호 입력 
      if (idx === 7){
        smsSubmit() 
        return false 
      }
      inputRefArr.current[idx+1].focus()
    }
  }

  const isNum = (val) => {
    return !isNaN(val)
  }

  const codeAuth = async() => {
    let code = inputRefArr.current[0].value 
    const codeValid = codeCheck(code) 

    if (codeValid.result === false){
      inputErrRefArr.current[0].innerHTML = codeValid.msg 
      inputErrRefArr.current[0].classList.remove('hidden')
      return false
    }
    code = code.toUpperCase()

    const reqURI = `${HOST_ADDR}app/v1/users/partner/auth/${code}`
    try{
      const res = await axios.get(reqURI)
      const resData = res.data 
      const shopName = resData.data.shopName 
      setShopName(shopName)
      setPartnerAuth(true) 
      inputRefArr.current[1].value = shopName
    }catch(e){
      const error = e.response.data 
      const errorMsg = error.msg 
      inputErrRefArr.current[0].innerHTML = errorMsg
      inputErrRefArr.current[0].classList.remove('hidden')
      return false
    }
  }

  const auth = async() => {
    const res = await smsNumberReq()
    if (res === true){
      setAuthActive(true) 
      inputRefArr.current[7].focus()
    }else{
      return false 
    }
  }

  const smsCountReset = async() => {
    const res = await smsNumberReq() 
    if (res === true){
      if (smsReqCount > 0){
        setSMSReqCount(smsReqCount-1)
        setSecond(180) 
      }else{
        inputErrRefArr.current[7].classList.remove('hidden') 
        inputErrRefArr.current[7].innerHTML = "재발송횟수를 초과하였습니다."
      }
    }
  }

  const smsNumberReq = async() => {
    const cellPhone = inputRefArr.current[6].value 

    const dataCheck = PhoneNumCheck(cellPhone)
    if (dataCheck.result === false){
      inputErrRefArr.current[6].innerHTML = dataCheck.msg 
      inputErrRefArr.current[6].classList.remove('hidden') 
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/auth/cellphone/`
    const data = {cellPhone: cellPhone}
    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        setVefPhone(cellPhone)
        setSMSPK(resData.data)
        return true 
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        inputErrRefArr.current[6].classList.remove('hidden')
        inputErrRefArr.current[6].innerHTML = data.msg
      }else if (e.request){
        inputErrRefArr.current[6].classList.remove('hidden')
        inputErrRefArr.current[6].innerHTML = "서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요."
      }
    }
  }

  const smsSubmit = async() => {
    const code = inputRefArr.current[7].value 
    if (code === null || code === '' || code == undefined){
      inputErrRefArr.current[7].classList.remove('hidden') 
      inputErrRefArr.current[7].innerHTML = "인증번호를 입력해주세요."
      return false 
    }

    if (code.length !== 6){
      inputErrRefArr.current[7].classList.remove('hidden') 
      inputErrRefArr.current[7].innerHTML = "6자리의 인증번호를 올바르게 입력해주세요"
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/auth/cellphone/`
    const data = {code : code, cellPhone : vefPhone, pk:smsPK}
    try{
      const res = await axios.put(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        setPhoneAuth(true) 
        setAuthActive(null)
        inputRefArr.current[6].disabled = true 
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        inputErrRefArr.current[7].classList.remove('hidden')
        inputErrRefArr.current[7].innerHTML = data.msg
      }else if (e.request){
        inputErrRefArr.current[7].classList.remove('hidden')
        inputErrRefArr.current[7].innerHTML = "서버 연결에 실패하였습니다. 잠시 후 다시 시도해주세요."
      }
    }
  }

  const signupSubmit = async() =>{
    const code = inputRefArr.current[0].value 
    const email = inputRefArr.current[2].value  
    const password = inputRefArr.current[3].value 
    const password2 = inputRefArr.current[4].value  
    const manager = inputRefArr.current[5].value  
    const phone = inputRefArr.current[6].value 
    let res 

    if (partnerAuth === false){
      inputErrRefArr.current[0].classList.remove('hidden')
      inputErrRefArr.current[0].innerHTML = "인증 코드를 입력해주세요."
      return false 
    }

    // 이메일 검증 
    res = emailVerify(email)
    if (res.result === false){
      inputErrRefArr.current[2].classList.remove('hidden')
      inputErrRefArr.current[2].innerHTML = res.msg 
      return false 
    }

    // 패스워드 검증 
    res = passwordVerify(password)
    if (res.result === false){
      inputErrRefArr.current[3].classList.remove('hidden')
      inputErrRefArr.current[3].innerHTML = res.msg 
      return false 
    }

    if (password !== password2){
      inputErrRefArr.current[4].classList.remove('hidden')
      inputErrRefArr.current[4].innerHTML = "비밀번호가 서로 맞지 않습니다."
      return false 
    }

    res = NullCheck(manager, "담당자") 
    if (res.result === false){
      inputErrRefArr.current[5].classList.remove('hidden')
      inputErrRefArr.current[5].innerHTML = res.msg 
      return false 
    }

    if (phoneAuth === false){
      inputErrRefArr.current[6].classList.remove('hidden')
      inputErrRefArr.current[6].innerHTML = "담당자의 휴대폰 인증을 진행해주세요."
      return false 
    }

    const essentialCheck = checkRefArr.current[0].checked
    const essentialCheck1 = checkRefArr.current[1].checked
    const essentialCheck2 = checkRefArr.current[2].checked

    if (essentialCheck === false || essentialCheck1 === false || essentialCheck2 === false ){
      inputErrRefArr.current[8].classList.remove('hidden')
      inputErrRefArr.current[8].innerHTML = "필수 항목은 반드시 체크되어야 합니다."
      return false 
    }
    inputErrRefArr.current[8].classList.add('hidden')

    const selectivePersonalInfo = checkRefArr.current[3].checked
    const smsAgreement = checkRefArr.current[4].checked
    const emailAgreement = checkRefArr.current[5].checked

    const reqURI = `${HOST_ADDR}app/v1/users/register/`
    const data = { 
      type : 'partner', 
      email : email, 
      password : password, 
      cellPhone : phone, 
      code: code, 
      manager: manager, 
      selectivePersonalInfo:selectivePersonalInfo,
      smsAgreement:smsAgreement,
      emailAgreement: emailAgreement
    }
    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        history.push('/service/signup/welcome')
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        inputErrRefArr.current[8].classList.remove('hidden')
        inputErrRefArr.current[8].innerHTML = data.msg
      }else if (e.request){
        inputErrRefArr.current[8].classList.remove('hidden')
        inputErrRefArr.current[8].innerHTML = "서버 연결에 실패하였습니다. 잠시 후 다시 시도해주세요."
      }
    }
  }

  const allChecking = () => {
    let action 
    if (checkRefArr.current[0].checked === true){
      action = false 
    }else{
      action = true 
    }
    console.log(checkRefArr)
    for(let i =0; i < checkRefArr.current.length; i++){
      checkRefArr.current[i].checked = action
    }
  }

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto px-28 py-24 flex flex-row justify-center min-h-screen h-max">
        <div className="flex flex-col justify-start self-start w-full flex-grow h-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon text-black self-start">제휴사 회원가입</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">인증 코드</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <div className="flex flex-row justify-between">
              <input type='text' className={ partnerAuth === false ? "border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2 py-1 w-4/5 uppercase" :
              "border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2 py-1 w-full uppercase"}
              placeholder="발급받은 6자리 인증코드를 입력해주세요." maxLength={6} ref={(ref) => inputRefArr.current[0] = ref} onKeyDown={(e) => submitEdit(e, 0)} disabled={partnerAuth === true ? true : false} /> 
              <button className={ partnerAuth === false ? "rounded-lg bg-rose-500 py-0.5 text-white w-1/5 ml-2" : 'hidden'} onClick={() => codeAuth()}>인증하기</button>
            </div>
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[0] = ref}>Error</p>
            <p className={partnerAuth === true ? "text-base font-nexonBold self-start text-indigo-700" : 'hidden'}>인증 성공</p>
            
            <div className={ partnerAuth === true ? "flex flex-row justify-start mt-8" : 'hidden'}>
              <p className="text-base font-nexon self-start">제휴 매장</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <input type='text' className={ partnerAuth === true ? "border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2" : 'hidden'}
            placeholder="매장명을 입력해주세요." ref={(ref) => inputRefArr.current[1] = ref} disabled={true} /> 
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[1] = ref}>Error</p>
            <p className={ partnerAuth === true ? "text-base font-nexonBold self-start text-indigo-700" : 'hidden'}>인증 성공</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">이메일</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <input type='email' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
            placeholder="이메일을 입력해주세요." ref={(ref) => inputRefArr.current[2] = ref} onKeyDown={(e) => submitEdit(e, 2)} /> 
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[2] = ref}>Error</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">비밀번호</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <input type='password' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
            placeholder="영문 대/소문자, 숫자, 특수문자 조합 8자리 이상" ref={(ref) => inputRefArr.current[3] = ref} onKeyDown={(e) => submitEdit(e, 3)} /> 
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[3] = ref}>Error</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">비밀번호 확인</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <input type='password' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
            placeholder="비밀번호를 한번 더 입력해주세요." ref={(ref) => inputRefArr.current[4] = ref} onKeyDown={(e) => submitEdit(e, 4)} /> 
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[4] = ref}>Error</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">담당자</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <input type='text' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
            placeholder="담당자 이름을 입력해주세요." ref={(ref) => inputRefArr.current[5] = ref} onKeyDown={(e) => submitEdit(e, 5)} /> 
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[5] = ref}>Error</p>

            <div className="flex flex-row justify-start mt-8">
              <p className="text-base font-nexon self-start">연락처</p>
              <p className="text-base font-nexon self-start text-rose-700">*</p>
            </div>

            <div className="flex flex-row justify-between">
              <input type='tel' className={ authActive === false ? "border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2 py-1 w-4/5" :
              "border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2 py-1 w-full"}
              placeholder="휴대폰 번호를 입력해주세요('-'없이)" maxLength={11} ref={(ref) => inputRefArr.current[6] = ref} onKeyDown={(e) => submitEdit(e, 6)} /> 
              <button className={ authActive === false ? "rounded-lg bg-rose-500 py-0.5 text-white w-1/5 ml-2" : 'hidden'} onClick={() => auth()}>인증하기</button>
            </div>
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[6] = ref}>Error</p>

            <div className={ authActive === false || authActive === null ? 'hidden' : "flex flex-row justify-between mt-8"}>
              <div className="flex flex-row justify-start">
                <p className="text-base font-nexon self-center">인증번호</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <div className="flex flex-row justify-end gap-x-4">
                <p className="text-base font-nexon self-center cursor-pointer underline" onClick={() => smsCountReset() }>재발송하기</p>
                <p className="text-base font-nexon self-center cursor-pointer text-rose-700 underline" onClick={() => smsSubmit()}>인증하기</p>
              </div>
            </div>

            <div className={ authActive === false || authActive === null ? 'hidden' : "flex flex-row justify-start relative"}>
              <input type='text' className="border-0 w-full border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="인증번호를 입력해주세요." maxLength={6} ref={(ref) => inputRefArr.current[7] = ref} onKeyDown={(e) => submitEdit(e, 7)} /> 
              <p className="text-base font-nexon absolute right-2 text-right self-end text-rose-500" style={{width: '20%', bottom:'11px'}}>{count}</p>
            </div>
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[7] = ref}>Error</p>


            {/* 개인정보 및 이용약관 동의 part def */}

            <div className="flex flex-row justify-start gap-x-2 mt-8 ">
              <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md" onChange={() => allChecking()}/> 
              <p className="text-base font-nexonBold self-center">전체 동의</p>
            </div>

            <div className="flex flex-row justify-between mt-8">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[0] = ref}/> 
                <p className="text-base font-nexon text-rose-500 self-center pr-1">(필수)</p>
                <p className="text-base font-nexon self-center">만 14세 이상입니다.</p>
              </div>
            </div>

            <div className="flex flex-row justify-between mt-4">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[1] = ref}/> 
                <p className="text-base font-nexon text-rose-500 self-center pr-1">(필수)</p>
                <p className="text-base font-nexon self-center">스크린에어 이용약관 동의</p>
              </div>
              <p className="text-base font-nexon self-center underline cursor-pointer text-gray-400">전체 보기</p>
            </div>

            <div className="flex flex-row justify-between mt-4">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[2] = ref}/> 
                <p className="text-base font-nexon text-rose-500 self-center pr-1">(필수)</p>
                <p className="text-base font-nexon self-center">개인정보 수집 및 이용동의</p>
              </div>
              <p className="text-base font-nexon self-center underline cursor-pointer text-gray-400">전체 보기</p>
            </div>

            <div className="flex flex-row justify-between mt-4">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[3] = ref}/> 
                <p className="text-base font-nexon self-center pr-1">(선택)</p>
                <p className="text-base font-nexon self-center">개인정보 수집 및 이용 동의</p>
              </div>
              <p className="text-base font-nexon self-center underline cursor-pointer text-gray-400">전체 보기</p>
            </div>

            <div className="flex flex-row justify-between mt-4">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[4] = ref}/> 
                <p className="text-base font-nexon self-center pr-1">(선택)</p>
                <p className="text-base font-nexon self-center">SMS 이벤트 등 마케팅 수신 동의</p>
              </div>
            </div>

            <div className="flex flex-row justify-start mt-4">
              <div className="flex flex-row justify-start">
                <input type='checkbox' className="border-1 border-gray-700 focus:outline-none w-6 h-6 rounded-md mr-2" ref={(ref) => checkRefArr.current[5] = ref}/> 
                <p className="text-base font-nexon self-center pr-1">(선택)</p>
                <p className="text-base font-nexon self-center">이메일 이벤트 등 마케팅 수신 동의</p>
              </div>
            </div>
            <p className="text-base font-nexonBold self-start text-rose-700 hidden" ref={(ref) => inputErrRefArr.current[8] = ref}>Error</p>
            {/* 개인정보 및 이용약관 동의 part def end */}

            <div className="flex flex-row rounded-lg bg-rose-500 justify-center mt-4 py-2 cursor-pointer" onClick={() => signupSubmit()}>
              <p className="text-lg font-nexon text-white self-center">회원가입</p>
            </div>

          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow w-full self-end">
        <Footer props={props}/> 
      </div>
    </>
  )
}