import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import Footer from "servicePages/footer"
import UserConfig from "servicePages/config/userManager";
import ServiceHeader from "servicePages/header";
import toast, { Toaster } from 'react-hot-toast';
import { emailVerify } from '../signup/util'

export default function MyInfoManage(props){

  const {history} = props 
  const [userInfo, setUserInfo] = useState(null) 
  const [emailActive, setEmailActive] = useState(false) 
  const [phoneActive, setPhoneActive] = useState(false) 
  const [authActive, setAuthActive] = useState(false) 
  const [smsPK, setSMSPK] = useState(null) 
  const [isLoading, setLoading] = useState(true) 
  const emailRef = useRef(null) 
  const phoneRef = useRef(null) 
  const authRef = useRef(null) 

  useEffect(() => {
    loggedCheck()
  }, [])

  const loggedCheck = () => {
    const isLogged = UserConfig.isLogged() 
    if (isLogged === false){
      alert("잘못된 접근입니다.")
      history.replace('/service')
      return false 
    }else{
      init()
    }
  }

  const init = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}app/v1/users/account/info`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      setUserInfo(resData.data)
      setLoading(false)
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const active = (target) => {
    if (target === 'email'){
      setEmailActive(!emailActive)
    }else if(target === 'phone'){
      setPhoneActive(!phoneActive)
    }else{
      return false 
    }
  }

  const isNum = (val) => {
    return !isNaN(val)
  }

  const emailModify = async() => {
    const value = emailRef.current.value 
    
    const res = emailVerify(value) 
    if (res.result === false){
      toast.error(res.msg)
      return false 
    }
    const userToken = UserConfig.getAccKey()
    const dataType = 'email'
    const reqURI = `${HOST_ADDR}app/v1/users/account/modify/${dataType}`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const data = {email : value}

    try{
      const res = await axios.put(reqURI, data, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        setEmailActive(false) 
        window.location.reload()
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const phoneVerify = async() => {
    const value = phoneRef.current.value     
    if (value === '' || value === undefined || value === null){
      toast.error("전화번호를 입력해주세요")
      return false 
    }

    if(!isNum(value)){
      toast.error("올바른 전화번호를 입력해주세요")
      return false 
    }

    if(value.length !== 11){
      toast.error("11자리의 올바른 전화번호를 입력해주세요.")
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/auth/cellphone/`
    const data = {cellPhone: value}
    try{
      const res = await axios.post(reqURI, data) 
      const resData = res.data 
      if (resData.result === 'OK'){
        phoneRef.current.disabled = true 
        setAuthActive(true) 
        setSMSPK(resData.data)
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        toast.error(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const phoneAuth = async() => {
    const value = authRef.current.value 
    const vefPhone = phoneRef.current.value 
    const userToken = UserConfig.getAccKey() 

    if (value === '' || value === undefined || value === null){
      toast.error("인증번호를 입력해주세요")
      return false 
    }

    if(!isNum(value)){
      toast.error("올바른 인증번호를 입력해주세요")
      return false 
    }

    if(value.length !== 6){
      toast.error("6자리의 올바른 인증번호를 입력해주세요.")
      return false 
    } 
    
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    const reqURI = `${HOST_ADDR}app/v1/users/account/modify/cellPhone`
    const data = {authCode: value, cellPhone: vefPhone}
    try{
      const res = await axios.put(reqURI, data, headers) 
      const resData = res.data 
      if (resData.result === 'OK'){
        window.location.reload()
      }
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        toast.error(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  return(
    <>
    {isLoading === true ? null : ( 
      <>
        {/* Header */}
        <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
        <div className="container mx-auto px-24 py-12">
          <div className="flex flex-col flex-grow">
            <ServiceHeader props={props}/> 
          </div> 
        </div> 

        {/* Divide Line */}
        <div className="border-t-2 border-t-gray-400 h-1"/> 

        <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-80 pt-12 mb-12">
          <p className="text-3xl self-start font-nexonBold">개인정보</p>

          {/* Non-Editable Info */}
          <div className="flex flex-col justify-start mt-12">
            <p className="text-xl self-start font-nexon pb-1">성명</p>
            <p className="text-lg self-start font-nexon text-black">{userInfo[0].value}</p>
          </div>

          <div className="border-t-2 border-t-gray-400 h-1 my-8"/> 

          {/* Email */}
          <div className="flex flex-col justify-start">
            <div className="flex flex-row justify-between">
              <p className="text-xl self-start font-nexon pb-1">이메일</p>
              <p className="text-lg self-start font-nexon text-black underline cursor-pointer" onClick={() => active('email')}>수정하기</p>
            </div>
            <p className="text-xl self-start font-nexon pt-1">{userInfo[1].value}</p>

            {/* Email Modify UI */}
            <div className={ emailActive === true ? "flex flex-row justify-start gap-x-4 mt-4" : 'hidden'}>
              <input type='text' className="w-5/12 text-lg font-nexon self-center border-2 border-gray-300 rounded-lg py-1.5 pl-2 focus:outline-none focus:ring-0 focus:border-rose-500" placeholder="이메일을 입력해주세요."
                ref={(ref) => emailRef.current = ref} onKeyPress={(e) => {if(e.key === 'Enter') {emailModify()}} } /> 
              <div className="flex flex-row justify-center rounded-lg bg-rose-500 py-1.5 px-5 cursor-pointer" onClick={() => emailModify()}>
                <p className="text-lg text-white font-nexon self-center">수정</p>
              </div>
            </div>

          </div>

          <div className="border-t-2 border-t-gray-400 h-1 my-8"/> 

          {/* Cellphone */}
          <div className="flex flex-col justify-start">
            <div className="flex flex-row justify-between">
              <p className="text-xl self-start font-nexon pb-1">전화번호</p>
              <p className="text-lg self-start font-nexon text-black underline cursor-pointer" onClick={() => active('phone')}>수정하기</p>
            </div>
            <p className="text-xl self-start font-nexon pt-1">{userInfo[2].value}</p>

            {/* Cellphone Modify UI */}
            <div className={ phoneActive === true ? "flex flex-row justify-start gap-x-4 mt-4" : 'hidden'}>
              <input className="w-5/12 text-lg font-nexon self-center border-2 border-gray-300 rounded-lg py-1.5 pl-2 focus:outline-none focus:ring-0 focus:border-rose-500" placeholder="전화번호를 입력해주세요. ('-' 없이)"
              ref={(ref) => phoneRef.current = ref} onKeyPress={(e) => {if(e.key === 'Enter') {phoneVerify()}} } /> 
              <div className="flex flex-row justify-center rounded-lg bg-rose-500 py-1.5 px-5 cursor-pointer" onClick={() => phoneVerify()}>
                <p className="text-lg text-white font-nexon self-center">인증번호 발송</p>
              </div>
            </div>

            {/* Cellphone Modify UI */}
            <div className={ authActive === true ? "flex flex-row justify-start gap-x-4 mt-4" : 'hidden'}>
              <input type='text' maxLength={6} className="w-5/12 text-lg font-nexon self-center border-2 border-gray-300 rounded-lg py-1.5 pl-2 focus:outline-none focus:ring-0 focus:border-rose-500" placeholder="인증번호를 입력해주세요."
              ref={(ref) => authRef.current = ref} onKeyPress={(e) => {if(e.key === 'Enter') {phoneAuth()}} } /> 
              <p className="text-lg text-rose-500 font-nexon self-center underline cursor-pointer" onClick={() => phoneAuth()}>인증하기</p>
              <p className="text-lg text-gray-500 font-nexon self-center underline cursor-pointer" onClick={() => phoneVerify()}>재발송</p>
            </div>

          </div>

        </div>

        <div className="flex flex-col justify-end self-end flex-grow">
          <Footer props={props}/> 
        </div>
      </> 
    )}
    </>
  )
}