import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster} from "react-hot-toast";

export default function SignupSurface(props){
  const {history} = props 

  const movePage = (routeTarget) => {
    history.push(routeTarget, {})
  }

  // const info = () => {
  //   toast('현재 고객검증 시험판 버전으로 사업자 및 제휴사 회원가입은 지원되지 않습니다.',
  //   {
  //     icon: '🥹',
  //     style: {
  //       borderRadius: '10px',
  //       background: '#333',
  //       color: '#fff',
  //     },
  //   }
  // );
  // }

  return(
    <>
      {/* Header */}
      <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto px-28 py-12 flex flex-row justify-start min-h-screen max-h mb-24">
        <div className="flex flex-col justify-start self-start w-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon text-black self-start">회원가입</p>
            
            <div className="flex flex-col justify-center rounded-lg border-2 border-gray-400 hover:border-rose-500 w-full mt-8 cursor-pointer py-4 shadow-lg" onClick={() => movePage('/service/signup/personal')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-16 h-16 text-black font-bold self-center pt-4">
                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                <path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/>
              </svg>
              <p className="text-lg font-nexon self-center pt-4">개인 회원</p>
              <p className="text-base font-nexon self-center pb-4">광고를 희망하는 개인 회원</p>
            </div>

            <div className="flex flex-col justify-center rounded-lg border-2 border-gray-400 hover:border-rose-500 w-full mt-8 cursor-pointer py-4 shadow-lg" onClick={() => movePage('/service/signup/business')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-16 h-16 text-black font-bold self-center pt-4">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M176 416v64M80 32h192a32 32 0 0132 32v412a4 4 0 01-4 4H48h0V64a32 32 0 0132-32zM320 192h112a32 32 0 0132 32v256h0-160 0V208a16 16 0 0116-16z"/>
                <path d="M98.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79z"/><ellipse cx="256" cy="176" rx="15.95" ry="16.03" transform="rotate(-45 255.99 175.996)"/><path d="M258.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM400 400a16 16 0 1016 16 16 16 0 00-16-16zM400 320a16 16 0 1016 16 16 16 0 00-16-16zM400 240a16 16 0 1016 16 16 16 0 00-16-16zM336 400a16 16 0 1016 16 16 16 0 00-16-16zM336 320a16 16 0 1016 16 16 16 0 00-16-16zM336 240a16 16 0 1016 16 16 16 0 00-16-16z"/>
              </svg>
              <p className="text-lg font-nexon self-center pt-4">사업자 회원</p>
              <p className="text-base font-nexon self-center pb-4">사업자등록증을 보유한 개인 또는 법인 사업자 회원</p>
            </div>

            <div className="flex flex-col justify-center rounded-lg border-2 border-gray-400 hover:border-rose-500 w-full mt-8 cursor-pointer py-4 shadow-lg" onClick={() => movePage('/service/signup/partner')}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-black font-bold self-center pt-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
              </svg>
              <p className="text-lg font-nexon self-center pt-4">제휴사 회원</p>
              <p className="text-base font-nexon self-center pb-4">제휴를 체결한 회원 전용</p>
            </div>
          </div>
        </div>
      </div>


      <div className="flex flex-col flex-grow w-full self-end">
        <Footer props={props}/> 
      </div>
    </> 
  )
}