import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import Lottie from 'react-lottie'
import paySuccess from '../../../static/animation/paySuccess'

import { emailVerify, getBirth, passwordVerify } from "./util";

export default function SignupWelcome(props){
  const {history} = props 

  const paySuccessAni = {
    loop: true,
    autoplay: true, 
    animationData: paySuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  useEffect(() => {

  }, [])

  const movePage = (routeTarget) =>{
    history.push(routeTarget, {})
  }

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <Lottie options={paySuccessAni} width={400} height={400}/>

      <div className="container mx-auto px-28 flex flex-row justify-center h-screen -mt-12">
        <div className="flex flex-col flex-grow justify-start self-start w-full h-full">
          <p className="text-2xl font-nexonBold text-black self-center">회원가입이 완료되었습니다!</p>
          <p className="text-lg font-nexon text-black self-center pt-2">스크린에어의 회원이 되어주셔서 진심으로 감사드립니다.</p>
          <div className="flex flex-row rounded-lg bg-rose-500 mt-4 w-3/12 self-center justify-center py-2 cursor-pointer" onClick={() => movePage('/service/login/')}>
            <p className="text-lg font-nexon text-white self-center">로그인 페이지로 이동하기</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow self-end">
        <Footer props={props}/> 
      </div>
    </>
  )
}