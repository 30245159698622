import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster } from 'react-hot-toast';
import UserConfig from "servicePages/config/userManager";
import Lottie from "react-lottie";
import emptyAni from '../../static/animation/emptyAni'

export default function BuyHistory(props){
  const {history} = props 
  const [orderList, setOrderList] = useState([])
  const [dateList, setDateList] = useState([])
  const [loading, setLoading] = useState(true) 
  useEffect(() => {
    getPayList()
  }, [])

  const getPayList = async() => {
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}app/v1/payment/get/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data.data
      if (resData === null){
        setLoading(false) 
        return false 
      }
      setOrderList(resData) 
      await dataSorting(resData)
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const dataSorting = async (resData) => {
    const temp = resData
    const dateSort = {} 
    const dateListTemp = new Array()
    let key = 0 
    for(let i =0 ; i < temp.length; i++){
      if (dateSort[temp[i].payDate] === undefined){
        dateSort[temp[i].payDate] = new Array() 
      }
      dateSort[temp[i].payDate].push(temp[i])
      if (dateListTemp.includes(temp[i].payDate) === false){
        dateListTemp.push(temp[i].payDate)
      }
    }
    setOrderList(dateSort)
    setDateList(dateListTemp)
    setLoading(false) 
  }

  const emptyAniOption = {
    loop: true,
    autoplay: true, 
    animationData: emptyAni,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const movePage = (payPK) =>{
    history.push(`/service/orders/${payPK}`, {})
  }

  return(
    <>
    {loading === true ? null : ( 
      <>
        {/* Toast */}
        <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
        {/* Header */}
        <div className="container mx-auto px-24 py-12">
          <div className="flex flex-col flex-grow">
            <ServiceHeader props={props}/> 
          </div> 
        </div> 

        {/* Divide Line */}
        <div className="border-t-2 border-t-gray-400 h-1"/> 

        <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-24 pt-12">
          <p className="text-3xl font-nexonBold self-start">나의 구매내역</p>
          
          {dateList.length === 0 ? (
            <>
              <p className="text-xl font-nexon self-start pt-2">구매 내역이 없습니다.</p>
              <div className="flex flex-row w-full">
                <Lottie options={emptyAniOption} width={400} height={400}/>
              </div> 
            </>
          ) : ( 
            (dateList.map((data, idx) => (
              <div className="flex flex-col justify-start mt-12" key={idx}>
                <p className="text-2xl font-nexon self-start">{data}</p>
                <div className="grid grid-cols-3 gap-x-8">
                  {/* {orderList[data][0].shopName} */}
                  {orderList[data].map((data2, idx2) => (
                    <div className="flex flex-row justify-start my-4 gap-x-4 cursor-pointer" key={idx2} onClick={() => movePage(data2.payPK)}>
                      <img className="w-40 h-40 rounded-2xl" loading="lazy" src={`${HOST_ADDR.slice(0, -1)}${data2.shopThumb}`}/> 
                      <div className="flex flex-col justify-evenly gap-y-1">
                        {data2.payStatus === '결제 완료' ? (
                          <div className="flex flex-row justify-center rounded-lg bg-indigo-700 w-24 py-1 mb-4">
                            <p className="text-lg font-nexon self-start text-white">{data2.payStatus}</p>
                          </div> 
                        ) : ( 
                          <div className="flex flex-row justify-center rounded-lg bg-rose-700 w-24 py-1 mb-4">
                            <p className="text-lg font-nexon self-start text-white">{data2.payStatus}</p>
                          </div> 
                        )}
                        <div className="flex flex-col">
                          <p className="text-lg font-nexon font-bold self-start">{data2.shopName}</p>
                          <p className="text-lg font-nexon self-start">{`광고 기간: ${data2.adCount}개월`}</p>
                          <p className="text-lg font-nexon self-start">결제 금액: {data2.amount.toLocaleString()}원</p>
                          <p className={data2.adStatusCode === 'cancel' ? "text-lg font-nexon self-start text-rose-700" : 'text-lg font-nexon self-star'}>{data2.adStatus}</p>
                        </div> 
                      </div> 
                    </div> 
                  ))}
                </div> 
              </div> 
            )))
          )}
        </div>

        <div className="flex flex-col justify-end self-end flex-grow">
          <Footer props={props}/> 
        </div>
      </> 
    )}

    </> 
  )
}