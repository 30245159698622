import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster} from "react-hot-toast";
import LandImage from '../../static/img/landing/LandingImage.jpeg'

export default function Landing(props){
  const {history} = props 

  const movePage = (routeTarget) => {
    history.push(routeTarget, {})
  }

  return(
    <>
      {/* Header */}
      <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto px-28 py-12 flex flex-row justify-start min-h-screen max-h mb-24">
        <img src={LandImage}/> 
      </div>

      <div className="flex flex-col flex-grow w-full self-end">
        <Footer props={props}/> 
      </div>
    </> 
  )
}