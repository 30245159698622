import logo from './logo.svg';
import './App.css';
import './index.css'; 

// Redirect pages
import LocationMgr from 'LocationMgr';
// Custom pages 
import Authentication from 'pages/auth/authentication';
import Dashboard from './pages/dashboard/dashboard.js'
import AdManager from './pages/adManager/adManager.js'
import AdReq from './pages/adReq/adReq.js'
import DevManager from './pages/deviceManager/devManager.js'
import ContentRepo from './pages/contentRepository/ctnRepo.js'
import Statistic from './pages/statistic/statistic.js'
import SchduleMgr from './pages/scheduleManager/scheduleManager.js'
import Signup from './pages/auth/signup.js'
import MailAuthentication from './pages/auth/mailAuthentication.js'
import PasswordReset from 'pages/auth/pwReset';
import CouponeGet from 'pages/coupone/couponGet';
import CouponCheck from 'pages/coupone/couponCheck'
import Reporting from 'pages/reporting/reporting';
import AdReport from 'pages/adReport/adReport';
//  End 
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import ServiceMain from 'servicePages';
import ShopDetail from 'servicePages/shopDetail';
import AdPayment from 'servicePages/adPayment';
import PayComplete from 'servicePages/adPayment/payComplete';
import Login from 'servicePages/myPage/login';
import SearchResult from 'servicePages/searchRes';
import SignupSurface from 'servicePages/myPage/signup/signupSurface';
import SignupPersonal from 'servicePages/myPage/signup/personalSignup';
import SignupWelcome from 'servicePages/myPage/signup/welcome';
import WishList from 'servicePages/myPage/wishList/wishList';
import PartnerReq from 'servicePages/partnerReq';
import PartnerReqWelcome from 'servicePages/partnerReq/reqSuccess';
import BuyHistory from 'servicePages/buyHistory';
import HistoryDetail from 'servicePages/buyHistory/detail';
import MyInfoManage from 'servicePages/myPage/manage/myInfoManage';
import FindID from 'servicePages/myPage/finding/findID';
import FindPW from 'servicePages/myPage/finding/findPW';
import Landing from 'servicePages/landing';
import Term from 'servicePages/policies/term';
import Privacy from 'servicePages/policies/privacy';
import AdLog from 'servicePages/adLog';
import SignupBusiness from 'servicePages/myPage/signup/businessSignup';
import SignupPartner from 'servicePages/myPage/signup/partnerSignup';
import CtnSurface from 'servicePages/contentTemplate/ctnSurface';
import CtnEdit from 'servicePages/contentTemplate/ctnEdit';
import InquirySubmit from 'servicePages/Inquiry';




class App extends Component{
  state = {
    count : 0, 
  }; 

  render(){
    return(
      <>
        <Router forceRefresh={false}>
          <Switch>
            <Route path='/service/adLog/:shopName/:adName' component={AdLog} exact /> {/* 광고비 결제에 따른 데이터 전달 페이지 */}
            <Route path='/service/payment/ad/complete' component={PayComplete} exact /> {/* 광고비 결제에 따른 데이터 전달 페이지 */}
            <Route path='/service/payment/ad/' component={AdPayment} exact /> {/* 광고비 결제 페이지 */}
            <Route path='/service/mypage/modify' component={MyInfoManage} exact /> {/* 내 정보 수정 페이지 (개인) */}
            <Route path='/service/signup/welcome' component={SignupWelcome} exact /> {/* 회원가입 완료 페이지 (개인) */}
            <Route path='/service/signup/partner' component={SignupPartner} exact /> {/* 회원가입 페이지 (제휴사) */}
            <Route path='/service/signup/business' component={SignupBusiness} exact /> {/* 회원가입 페이지 (사업자) */}
            <Route path='/service/signup/personal' component={SignupPersonal} exact /> {/* 회원가입 페이지 (개인) */}
            <Route path='/service/signup/join' component={SignupSurface} exact /> {/* 회원가입 초기 페이지 */}
            <Route path='/service/login/findPW/' component={FindPW} exact /> {/* 비밀번호 초기화 페이지 */}
            <Route path='/service/login/findID/' component={FindID} exact /> {/* 아이디 찾기 페이지 */}
            <Route path='/service/login/' component={Login} exact /> {/* 로그인 페이지 */}
            <Route path='/service/shops/:pk' component={ShopDetail} exact /> {/* 매장 상세 페이지 */}
            <Route path='/service/search/:keyword' component={SearchResult} exact /> {/* 스크린에어 서비스 페이지 */}
            <Route path='/service/ctnTemplate/Edit/' component={CtnEdit} exact /> {/* 콘텐츠 생성 메인 */}
            <Route path='/service/ctnTemplate/Surface/' component={CtnSurface} exact /> {/* 콘텐츠 생성 surface */}
            <Route path='/service/wishList/' component={WishList} exact /> {/* 나의 찜목록 페이지 */}
            <Route path='/service/InquirySubmit/' component={InquirySubmit} exact /> {/* 이용 문의 페이지 (230218, TODO:) */}
            <Route path='/service/partnerReq/success/' component={PartnerReqWelcome} exact /> {/* 제휴 신청 완료 페이지 */}
            <Route path='/service/partnerReq/' component={PartnerReq} exact /> {/* 제휴 신청 페이지 */}
            <Route path='/service/orders/:shopPK' component={HistoryDetail} exact /> {/* 상세 구매내역 페이지 */}
            <Route path='/service/orders' component={BuyHistory} exact /> {/* 구매내역 페이지 */}
            <Route path='/service/intro' component={Landing} exact /> {/* 스크린에어 랜딩 페이지 */}
            <Route path='/service/policies/privacy' component={Privacy} exact /> {/* 스크린에어 서비스 페이지 */}
            <Route path='/service/policies/term' component={Term} exact /> {/* 스크린에어 서비스 페이지 */}
            <Route path='/service/' component={ServiceMain} exact /> {/* 스크린에어 서비스 페이지 */}

            {/* ScreenAir Admin system */}
            <Route path='/reporting/:originSchName/:beginProd/:endProd/:customSchName/:payPK' component={Reporting} exact /> {/* 송출보고서 출력 페이지 */}
            <Route path='/coupon/check/:identification/:publisher' component={CouponCheck} exact />
            <Route path='/coupon/get/:target/:identification' component={CouponeGet} exact />
            <Route path='/cms/adReport' component={AdReport} exact />
            <Route path='/cms/schedule' component={SchduleMgr} exact />
            <Route path='/cms/statistic' component={Statistic} exact />
            <Route path='/cms/contentRepo' component={ContentRepo} exact />
            <Route path='/cms/devManager' component={DevManager} exact />
            <Route path='/cms/adManager' component={AdManager} exact />
            <Route path='/cms/adReq' component={AdReq} exact />
            <Route path='/cms/dashboard' component={Dashboard} exact />
            <Route path='/cms/signup/v1/:mail/:username' component={MailAuthentication} exact /> {/* CMS 회원가입 페이지 */}
            <Route path='/cms/passwordReset/' component={PasswordReset} exact /> {/* CMS 패스워드 리셋 */}
            <Route path='/cms/signup/' component={Signup} exact /> {/* CMS 회원가입 페이지 */}
            <Route path='/cms/' component={Authentication} exact /> {/* CMS 로그인 페이지 */}

            {/* Redirect Manager */}
            <Route path='/' component={LocationMgr} exact /> 

            {/*  */}
          </Switch>
        </Router>
      </>
    )
  }
}

// export default Example; 
export default App;
