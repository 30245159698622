import React, { createRef, useState } from 'react'
import { ReactDOM } from 'react';
import QRCode from "react-qr-code";
import { data } from 'autoprefixer';
import axios from 'axios';
import { useAsync } from 'react-async';
import { NotiModal } from 'pages/util/notification'
import { PwChangeModal } from 'pages/util/notification'
import configData from 'pages/reactConfig'
import 'App.css'; 
import html2canvas from 'html2canvas'

export default class CouponeGet extends React.Component{
  constructor(props){
    super(props)
    const meta = document.createElement('meta'); 
    // viewport 설정 for mobile 
    meta.name = 'viewport'
    meta.content ="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
    document.getElementsByTagName('head')[0].appendChild(meta); 
    this.defaultTryCount = 5

    this.state = {
      shopName : props.match.params.publisher, 
      identification : props.match.params.identification, 
      item : null,  
      dueDate : null,
      dueDateObj : null, 
      isLoading : true, 
      isCanUse : null, 
      isAuthComplted : null, 
      remainTryCount : this.defaultTryCount, 
    }

    /* TODO: (220309, @ssong) 실제 서버에 적용할 땐 URL 경로 수정 필요  */
    this.serverAddr = configData.SERVER_URL
    this.pwInputArr = [React.createRef(null),React.createRef(null),React.createRef(null),React.createRef(null),React.createRef(null),React.createRef(null)] 
    this.failNotiRef = React.createRef(null)   
    this.failNotiText = React.createRef(null)
    this.inputErrorNotRef = React.createRef(null) 
    this.btnText = React.createRef(null)
  }
      
  componentDidMount = async() => {
    try{
      let result = await this.getInfo()
      console.log(result) 
      let item = result['item'] 
      let expireDate = result['expire'] 
      let expireDateObj = new Date(expireDate) 
      let year = expireDateObj.getFullYear()
      let month = expireDateObj.getMonth()+1 
      let date = expireDateObj.getDate() 
      month < 10 ? month = '0'+month : month = month 
      date < 10 ? date = '0'+date : date = date 
      let expireDateStr = year+"년 "+month+"월 "+date+"일"
      let todayDateObj = new Date(); 
      let isCanUse; 

      if (result['result'] === 'OK'){

        if (todayDateObj > expireDateObj){
          isCanUse = false
        }else{
          isCanUse = true 
        }

        this.setState({
          item : item, 
          dueDate : expireDateStr, 
          isLoading : false,
          isCanUse : isCanUse
        })
      }else{
        if (result['reason'] === 'ALREADY_USED'){

          isCanUse = false 
          this.setState({
            item : item, 
            dueDate : expireDateStr, 
            isLoading : false,
            isCanUse : isCanUse
          }, () => {
            this.btnText.current.innerHTML = "이미 사용된 쿠폰입니다."
          })
        }
        // 404 page로 대체 필요 
        // alert("데이터를 정상적으로 불러오지 못했습니다.\n네크워크 상태를 점검한 뒤 다시 QR코드를 스캔해주세요")
        // return false 
      }   
    }catch(e){
      alert("데이터를 정상적으로 불러오지 못했습니다.\n네크워크 상태를 점검한 뒤 다시 접속해주세요")
      return false 
    }
  }



  
  // TODO: network 데이터 못 불러왔을떄에 대한 error 정의 필요 
  getInfo = async() => {
    const {data:result} = await axios.get(this.serverAddr+'/qrAPI/coupon/info/check/'+this.state.identification+'/'+this.state.shopName,
    {
      withCredentials : true, 
      headers:{
        'Content-Type' : 'application/json', 
        'Accept': 'application/json'
      }
    }); 
    return result
  }

  pwInputHandler = (e, idx) => {
    let maxLen = 1 
    // Error UI handler 
    if (this.inputErrorNotRef.current.classList.contains('hidden') === false){
      this.inputErrorNotRef.current.classList.add('hidden') 
    }
    if (this.failNotiRef.current.classList.contains('hidden') === false){
      this.failNotiRef.current.classList.add('hidden') 
    }
    // Function define related to inputting data 
    if(e.target.value.length > 1){
      this.pwInputArr[idx].current.value = e.target.value.slice(0, 1); 
      return false 
    }else{
      if (idx !== 5){
        if (e.nativeEvent.inputType === 'deleteContentBackward'){
          if (idx === 0){
            return false
          }else{
            this.pwInputArr[idx-1].current.focus()
          }
        }else{
          this.pwInputArr[idx+1].current.focus() 
        }
      }else if (idx === 5){
        if (e.nativeEvent.inputType === 'deleteContentBackward'){
          this.pwInputArr[idx-1].current.focus()
        }else{
          this.pwInputArr[idx].current.blur()
        }
      }
    }
  }

  authentication = async () => {
    let password = ''; 
    this.pwInputArr.forEach(element => {
      password += element.current.value 
    })
    if (password.length !== 6){
      this.inputErrorNotRef.current.classList.remove('hidden') 
    }else{
      let params = {'password' : password}
      try{
        const {data:result} = await axios.put(this.serverAddr+'/qrAPI/coupon/check/'+this.state.identification+'/'+this.state.shopName, params, 
        {
          withCredentials : true, 
          headers:{
            'Content-Type' : 'application/json', 
            'Accept': 'application/json'
          }
        });
        if (result['result'] === 'OK'){
          this.setState({isAuthComplted: true}, () => {
            setTimeout(() => {
              window.close()
            }, 3000); 
          })
        }else{
          if (result['reason'] === 'PASSWORD_INCORRECT'){
            let remainCount = result['count'] 
            this.failNotiRef.current.classList.remove('hidden') 
            this.setState({remainTryCount : this.defaultTryCount-remainCount}, () => {
              if (this.state.remainTryCount <= 0){
                this.setState({isCanUse:false, remainTryCount:0}, () => {
                  this.btnText.current.innerHTML = "접근이 차단되었습니다."
                  this.failNotiRef.current.classList.remove('animate-pulse')
                  this.failNotiText.current.innerHTML = "연속적인 비밀번호 불일치로 인해 접근이 차단됩니다."
                })
              }
            })
          }
        }
      }catch(e){
        alert("데이터를 정상적으로 불러오지 못했습니다.\n네크워크 상태를 점검한 뒤 다시 시도해주세요")
      }

    }
  }

  render(){
    return(
      <>
      { this.state.isLoading === true ? 
        <div id='loadingContainer' className='flex flex-col w-full h-screen justify-center gap-y-4'>
          <svg className="animate-spin h-12 w-12 text-rose-700 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span className='text-lg font-semibold text-gray-800 self-center text-center antialiased'>잠시만 기다려주세요...</span>
        </div>
        : 
        this.state.isAuthComplted !== true ? (
          <div id='container' className='flex flex-col w-full h-screen justify-center overflow-y-hidden'>

          <span className='text-2xl font-semibold text-gray-900 self-center antialiased'>쿠폰 인증</span>
          <span className='text-base font-bold text-gray-400 self-center antialiased'>{this.state.item}</span>

          <div className="flex flex-row justify-center gap-x-3 pb-6 mx-2 mt-6">
            { this.state.isCanUse === true ? (
              <>
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 0)} ref={this.pwInputArr[0]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 1)} ref={this.pwInputArr[1]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 2)} ref={this.pwInputArr[2]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 3)} ref={this.pwInputArr[3]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 4)} ref={this.pwInputArr[4]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" pattern="\d*" onInput={(e) => this.pwInputHandler(e, 5)} ref={this.pwInputArr[5]} />
              </> 
            ) : (
              <>
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 0)} ref={this.pwInputArr[0]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 1)} ref={this.pwInputArr[1]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 2)} ref={this.pwInputArr[2]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 3)} ref={this.pwInputArr[3]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 4)} ref={this.pwInputArr[4]} />
                <input className="w-2/12 focus:outline-none focus:border-rose-700 py-1.5 rounded-lg border-gray-300 border text-3xl font-semibold text-center text-rose-700 appearance-none" type="number" disabled pattern="\d*" onInput={(e) => this.pwInputHandler(e, 5)} ref={this.pwInputArr[5]} />
              </> 
            ) }
          </div>
          {/* (220309, @ssong) failed notification  */}
          <div class='flex flex-col justify-start animate-pulse hidden' ref={this.failNotiRef}>
            <span className='text-sm font-semibold text-red-800 self-center antialiased'>비밀번호가 일치하지 않습니다.</span>
            <span className='text-xs font-semibold text-gray-500 self-center antialiased' ref={this.failNotiText}>{this.state.remainTryCount}회 연속 비밀번호가 일치하지 않을 경우 접근이 차단됩니다.</span>
          </div>

          <div class='flex flex-col justify-start hidden' ref={this.inputErrorNotRef}>
            <span className='text-sm font-semibold text-red-800 self-center antialiased'>인증 번호를 모두 입력해주세요🥺</span>
          </div>

          <div class='flex flex-col justify-start mt-4'>
            <span className='text-sm font-bold text-gray-900 self-center antialiased'>쿠폰 유효기간: {this.state.dueDate}</span>
          </div>

          <div className="flex flex-col justify-center mt-4 gap-y-2">
            <span className='text-base font-semibold text-gray-900 self-center antialiased text-center'>6자리 비밀번호를 입력하고<br/> 인증하기 버튼을 눌러주세요.</span>
            <span className='text-sm font-semibold text-gray-400 self-center antialiased mb-4 text-center'>인증을 통해 쿠폰의 사용가능 여부를 확인할 수 있습니다.</span>
          </div>
          
          {this.state.isCanUse === true ? (
            <footer id="footer" className='fixed bottom-0 w-full h-auto flex flex-row bg-rose-500 justify-center py-2.5' onClick={ () => this.authentication()}>
              <span className='text-base font-bold text-white antialiased self-center' ref={this.btnText}>인증하기</span>
            </footer>            
          ) : (
            <footer id="footer" className='fixed bottom-0 w-full h-auto flex flex-row bg-gray-500 justify-center py-2.5'>
            <span className='text-base font-bold text-white antialiased self-center' ref={this.btnText}>유효기간이 만료된 쿠폰입니다.</span>
          </footer>
          )}
        </div>         
        ) : (
          <div className='flex flex-col w-full h-screen justify-center'>
            <div className='container mx-auto my-auto flex flex-col justify-center gap-y-4'>
              <svg class="checkmark self-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <span className='text-base font-semibold text-gray-900 self-center text-center'>인증이 완료되었습니다.</span>
            </div>
          </div>
        )
      }
      </>
    )
  }
}