import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import UserConfig from "servicePages/config/userManager";

export default function Login(props){
  const {history} = props 
  const { naver } = window
  const emailRef = useRef(null) 
  const passwdRef = useRef(null) 
  const [error, setError] = useState('')

  useEffect(() => {
    if (UserConfig.isLogged() === true) {
      history.push('/service/')
      return false 
    }
    emailRef.current.focus()
  }, []) 

  const loginSubmit = async() => {
    const email = emailRef.current.value 
    const passwd = passwdRef.current.value 
    if (email === '' || email === undefined || email === null){
      setError("이메일을 입력해주세요.")
      return false 
    }
    if (passwd === '' || passwd === undefined || passwd === null){
      setError("패스워드를 입력해주세요.")
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/login/`
    const data = {email:email, password:passwd, fcmToken: null}
    try{
      const res = await axios.post(reqURI, data)
      const resData = res.data 
      UserConfig.loginSet(resData)
      history.push('/service/', {})
    }catch(error){
      const errorData = error.response 
      setError(errorData.data.msg)
    }
  }

  const movePage = (routeTarget) => {
    history.push(routeTarget, {})
  }

  const inputMonitoring = (e, idx) => {
    setError('') 
    if(e.key === 'Enter'){
      if (idx === 0){
        passwdRef.current.focus()
      }else if (idx === 1){
        loginSubmit()
      }
    }
  }

  return(
    <>
      {/* Header */}
      <div className="container mx-auto px-28 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto px-28 py-24 flex flex-row justify-center min-h-screen max-h">
        <div className="flex flex-col justify-start self-start w-full">
          <div className="flex flex-col w-4/12 justify-start self-center">
            <p className="text-2xl font-nexon font-bold text-black self-center">스크린에어 로그인</p>
            <input type='email' className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-12 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="이메일"  onKeyPress={(e) => {inputMonitoring(e, 0)}} ref={(ref) => emailRef.current = ref}/>
            <input type='password' className="border-gray-400 rounded-lg w-full text-base py-2.5 font-nexon self-center mt-6 focus:outline-none focus:ring-0 focus:border-gray-400 placeholder-gray-400 " style={{'border-width':'1.5px'}} 
            placeholder="비밀번호" onKeyPress={(e) => {inputMonitoring(e, 1)}} ref={(ref) => passwdRef.current = ref}/>
            <div className="flex flex-row justify-end w-full self-center mt-4 gap-x-2">
              <p className="text-sm font-nexonBold text-gray-700 cursor-pointer" onClick={() => movePage('/service/login/findID/')}>아이디 찾기</p>
              <p className="text-sm font-nexon text-gray-700">|</p>
              <p className="text-sm font-nexonBold text-gray-700 cursor-pointer" onClick={() => movePage('/service/login/findPW/')}>비밀번호 찾기</p>
            </div>
            <div className="flex flex-row rounded-lg w-full bg-rose-500 py-2 self-center justify-center mt-6 cursor-pointer" onClick={() => loginSubmit()}>
              <p className="text-lg font-nexon text-white self-center">로그인</p>
            </div>
            <div className="flex flex-col w-10/12 justify-start self-center">
              <p className={error === '' ? "hidden" : "text-base font-nexonBold text-rose-500 self-start pt-2"}>{error}</p>
            </div>

            <div className="flex flex-row justify-between mt-12">
              <p className="text-base font-nexonBold text-rose-500">아직 스크린에어 회원이 아니신가요?</p>
              <p className="text-base font-nexonBold text-black cursor-pointer hover:underline" onClick={() => movePage('/service/signup/join')}>회원가입 하기</p>
            </div>
          </div>

        </div>

      </div>


      <div className="flex flex-col justify-end self-end flex-grow">
        <Footer props={props}/> 
      </div>

    </> 
  )
}