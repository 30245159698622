import axios from "axios"
import { useEffect, useState } from "react"
import ServiceHeader from "servicePages/header"
import {HOST_ADDR} from 'servicePages/reactConfig.json'

export default function CtnSurface(props){
  const {history} = props 
  const [sampleList, setSampleList] = useState({})
  const [keyList, setKeyList] = useState([])

  useEffect(() => {
    dataInit()
  }, [])

  const dataInit = async() => {
    const reqURI = `${HOST_ADDR}contents/CtnTemplateUplaoder/`
    try{
      const res = await axios.get(reqURI) 
      const resData = res.data 
      if (resData.result === 'OK'){
        const dataList = resData.data 
        const keys = Object.keys(dataList)

        keys.forEach(key => {
          dataList[key].forEach(element => {
            const cut = element.resolution.split('x')
            const width = parseInt(cut[0])
            const height = parseInt(cut[1])
  
            if (width > height){
              element.type = '가로형'
              console.log(element.filename)
              console.log(element.type)
              console.log(`${width}x${height}`)
            }else{
              element.type = '세로형'
              console.log(element.filename)
              console.log(element.type)
              console.log(`${width}x${height}`)
            }
          });
        })
        console.log(dataList)
        setSampleList(dataList)
        setKeyList(keys) 
      }
    }catch(e){
      console.log(e)
      alert("알 수 없는 오류가 발생하였습니다.\n관리자에게 문의하여 주십시오.")
    }
  }

  const movePage = (routeTarget, mediaURL, type) => {
    history.push(routeTarget, {mediaURL: mediaURL, type: type})
  }

  return(
    <>
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-24 pt-12">
        <p className="text-3xl font-nexonBold self-start">광고 콘텐츠 만들기</p>
        <p className="text-xl font-nexon self-start pt-2">다양한 템플릿을 기반으로 광고 콘텐츠를 만들어보세요 :)</p>

        {
          keyList.map((data, idx) => {
            return(
              <div className="flex flex-col justify-start">
                <p className="text-2xl font-nexonBold self-start pt-12 pb-1">{data}</p> 

                <p className="text-xl font-nexon self-start pt-4">가로형 콘텐츠</p> 
                <div className="flex flex-row flex-1 justify-start gap-x-4 pt-4 flex-wrap gap-y-4">
                  {
                    sampleList[data].map((item , idx2) => (
                      (item.type === '가로형' ? (
                        <img src={`${HOST_ADDR.slice(0, -1)}${item.content}`} className="w-96 h-52 rounded-lg self-center cursor-pointer border border-black" onClick={() => movePage('/service/ctnTemplate/Edit/', item.backMedia, '가로형')} /> 
                      ) : null) 
                      // <p className="text-lg font-nexonBold self-start pt-12 pb-1">{item.filename}</p> 
                    ))                    
                  }
                </div>

                <p className="text-xl font-nexon self-start pt-12">세로형 콘텐츠</p> 
                <div className="flex flex-row flex-1 justify-start gap-x-4 pt-4 flex-wrap gap-y-4">
                {
                  sampleList[data].map((item , idx2) => (
                    (item.type === '세로형' ? (
                      <img src={`${HOST_ADDR.slice(0, -1)}${item.content}`} className="w-52 h-96 rounded-lg self-center border border-black" onClick={() => movePage('/service/ctnTemplate/Edit/', item.backMedia, '세로형')} /> 
                    ) : null) 
                    // <p className="text-lg font-nexonBold self-start pt-12 pb-1">{item.filename}</p> 
                  ))  
                }
                </div>
                <div className="border-t-2 border-t-gray-400 h-1 mt-12"/> 
              </div>
              
            )
          })
        }

        {/* {sampleList.map((data, idx) => (
          <div className="flex flex-col justify-start">
            <p className="text-2xl font-nexonBold self-start pt-12 pb-1">{data.category}</p>
            <div className="border-t-2 border-t-gray-400 h-1"/> 
          </div> 
          
        ))} */}


      </div> 

      

    </> 
  )
}