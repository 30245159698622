import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
// Configuration 
import configData from 'pages/reactConfig'
import { UserIcon } from "@heroicons/react/outline";
import UserConfig, { isLogged } from "servicePages/config/userManager";
import screenAirLogo from '../../static/img/logo/screenAirLogo.png'

export default function ServiceHeader(props){
  const {history} = props.props 
  const searchRef = useRef(null) 
  const [logged, setLogged] = useState(false) 

  useEffect(() => {
    const isLog = isLogged()
    if (isLog === true){
      setLogged(true) 
    }else{
      setLogged(false) 
    }
  }, [])

  const searchRes = () =>{
    const keyword = searchRef.current.value 
    console.log(keyword)
    history.push(`/service/search/${keyword}`, {})
  }

  const onKeyPress = (e) =>{
    if(e.key === 'Enter'){
      searchRes()
    }
  }

  const movePage = (routeTarget) =>{
    // TODO: 로그인 여부에 따라 필터링 
    history.push(routeTarget, {})
  }

  const confirmMove = (routeTarget) =>{
    if (logged === false){
      const confirm = window.confirm("로그인이 필요한 서비스입니다.\n로그인 페이지로 이동할까요?")
      if (confirm === true){
        history.push('/service/login/')
      }else{
        return true 
      }
    }else{
      history.push(routeTarget, {})
    }
    
  }

  const logout = () => {
    UserConfig.logout()
    history.replace('/service', {})
    window.location.reload()
  }

  return(
    // {/* Header */}
    // * 일반 웹페이지 기준 breakpoint 2xl 

    <div className="flex flex-row flex-grow max-h-18">

      <div className="flex flex-row cursor-pointer xl:w-2/6 md:justify-start md:w-20" onClick={() => movePage('/service/')}>
        <img className="self-center md:h-10 lg:h-12 xl:h-14" src={screenAirLogo}/> 
        <p className="self-center pb-1 pl-2 font-bold text-black xl:text-4xl md:hidden lg:text-3xl xl:block">ScreenAir</p>
      </div>

      {/* Search Area */}
      <div className="flex flex-row self-center justify-center xl:h-14 xl:w-2/6 md:h-12 md:w-4/6 ">
        <div className="flex flex-row w-full pl-4 border-2 border-gray-300 rounded-full gap-x-2 focus:border-rose-500 hover:border-rose-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center font-bold text-black w-7 h-7">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
          <input ref={(ref) => searchRef.current = ref} onKeyPress={onKeyPress} className="self-center w-full py-2 mr-4 text-black xl:text-base lg:text-sm font-nanum focus:outline-none" placeholder="주소 또는 매장 이름으로 검색해주세요."/> 
        </div> 
      </div>

      <div className="flex flex-row justify-end w-2/6 lg:gap-x-6 xl:gap-x-8 md:gap-x-4 md:w-2/6">
        {/* 찜 */}
        {logged === false ? ( 
          // {/* 제휴사 모집  */}
          <div className="relative self-center group">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => movePage('/service/partnerReq/')}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
            </svg>
            <div className="absolute z-10 w-24 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-80%'}}>
              <p className="text-sm text-white font-nexon">제휴 신청</p>
              <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
            </div>
          </div>
        ) : ( 
          // {/* 구매내역 */}
          <div className="relative self-center group">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => movePage('/service/orders/')}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
              <p className="text-sm text-white font-nexon">구매내역</p>
              <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
            </div>
          </div>
        )}

          {/* // 찜 목록 */}
          <div className="relative self-center group">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => confirmMove('/service/wishList/')}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
            <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
              <p className="text-sm text-white font-nexon">찜 목록</p>
              <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
            </div>
          </div>


        {/* 로그인 및 내 정보 */}
        {logged === false ? (
          // {/* 미로그인 경우 */}
          <div className="relative self-center group">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => movePage('/service/login/')}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
              <p className="text-sm text-white font-nexon">로그인</p>
              <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
            </div>
          </div>
        ) : ( 

          // {/* 로그인 경우 */}
          <>
            <div className="relative self-center group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => movePage('/service/mypage/modify')}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
                <p className="text-sm text-white font-nexon">내 정보</p>
                <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
              </div>
            </div>

            <div className="relative self-center group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => logout()}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
              </svg>
              <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
                <p className="text-sm text-white font-nexon">로그아웃</p>
                <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
              </div>
            </div>

            {/* <div className="relative self-center group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="self-center w-10 h-10 font-normal text-black cursor-pointer" onClick={() => movePage('/service/login/')}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <div className="absolute z-10 w-20 px-3 py-2 mt-2 ml-1 text-xs text-center text-white bg-black rounded-lg opacity-0 pointer-events-none group-hover:opacity-100 top-full" style={{left:'-61%'}}>
                <p className="text-sm text-white font-nexon">내정보</p>
                <svg className="absolute left-0 w-full h-2 text-black transform rotate-180 bottom-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}