
import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
// Configuration 
import configData from 'pages/reactConfig'
import { UserIcon } from "@heroicons/react/outline";
import ServiceHeader from "./header";
import Footer from "./footer";
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import UserConfig, { isLogged } from "./config/userManager";
import promote2 from '../static/img/promote/promote2.png'
import { InitDB } from "./config/indexedDB";

export default function ServiceMain(props){
  const {history} = props 
  const [deviceList, setDeviceList] = useState([])
  const [likeList, setLikeList] = useState([])

  useEffect(() => {
    document.title ="스크린에어: 디지털 사이니지 매장 광고 플랫폼"
    getList()
    InitDB()
  }, [])

  const MovePage = (pk) => {
    // history.push( {pathname: `/service/shops/${pk}`, state: {test:1} } )
    history.push(`/service/shops/${pk}`, {test:1})
  }

  const getList = async() => {
    const isLogged = UserConfig.isLogged() 
    if (isLogged === true){
      await getLikeShopList()
    }

    const reqURI = HOST_ADDR+'app/v1/shops/get/list/'
    try{
      const res = await axios.get(reqURI)
      const resData = res.data 
      const data = resData.data
      setDeviceList(data) 
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        // TODO: logout 로직 도입 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const getLikeShopList = async() =>{
    const reqURI = HOST_ADDR+'app/v1/shops/get/list/like/'
    const userToken = UserConfig.getAccKey() 
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}
    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      const data = resData.data
      setLikeList(data) 
    }catch(e){
      if (e.response){
        const status = e.response.status 
        const data = e.response.data 
        alert(data.msg)
      }else if (e.request){
        alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
      }
    }
  }

  const shopLike = async(like, pk) => {
    if (isLogged === false){
      const confirm = window.confirm('로그인이 필요한 서비스입니다.\n로그인 페이지로 이동하시겠습니까?')
      if (confirm === true){
        history.push('/service/login/')
      }else{
        return false 
      }
    }else{
      const reqURI = `${HOST_ADDR}app/v1/shops/like/${pk}`
      const userToken = UserConfig.getAccKey() 
      const headers = { headers : {Authorization:`Bearer ${userToken}`}}

      if(like === true){
        try{
          const res = await axios.delete(reqURI, headers)
          const resData = res.data 
          getList()
        }catch(e){
          if (e.response){
            const status = e.response.status 
            const data = e.response.data 
            alert(data.msg)
          }else if (e.request){
            alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
          }
        }
      }else{
        try{
          const res = await axios.post(reqURI, {}, headers)
          const resData = res.data 
          getList()
        }catch(e){
          if (e.response){
            const status = e.response.status 
            const data = e.response.data 
            alert(data.msg)
          }else if (e.request){
            alert("서버와 연결에 실패하였습니다. 잠시 후 다시 시도해주세요.")
          }
        }
      }
    }
  }

  return(
    <>
      {/* Header */}
      <div className="container py-12 mx-auto xl:px-24 lg:px-20 md:px-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 

      {/* FIXME: 작은거부터 잡아나가야함 */}

      {/* Promote Banner */}
      <div className="flex flex-row justify-between flex-grow max-h-108 h-108" style={{backgroundColor: 'rgb(78, 94, 179'}}>
        <div className="container flex flex-col justify-center px-24 mx-auto lg:px-20">
        {/* lg:px-20 */}
        {/* <div className="flex flex-row" style={{width : '15%'}}/>  */}
          <img className="w-auto h-auto 2xl:object-cover 2xl:w-full 2xl:h-full md:object-cover md:self-center md:w-11/12 md:pl-12 md:h-5/6" src={promote2}/> 
        {/* <div className="flex flex-row h-full bg-center bg-no-repeat bg-contain" style={{width : '100%', backgroundImage: 'url(promote/promote1.png'}}/>  */}
        {/* <div className="flex flex-row" style={{width : '15%'}}/>  */}
        </div>
      </div>

      {/* // * 일반 웹페이지 기준 breakpoint 2xl  */}
      <div className="container min-h-screen px-24 mx-auto md:px-20 h-max">
        <div className="flex flex-col flex-grow">
          {/* Shop List */}
          <div className="flex flex-col justify-start flex-grow pt-12">
            <div className="grid justify-center 2xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-x-14">
            {deviceList.map((data, idx) => (
              <Fragment key={idx}>
                <div className="flex flex-row justify-center mb-8 cursor-pointer" key={idx}>
                  <div className="relative flex flex-col justify-start lg:pl-6 rounded-xl">
                    {/* <img src={`${HOST_ADDR.slice(0, -1)}${data.img}`} className='w-72 h-72 rounded-2xl'></img> */}
                    <img src={`${HOST_ADDR.slice(0, -1)}${data.thumb}`} className='relative lg:w-64 lg:h-64 w-72 h-72 rounded-2xl' loading="lazy" onClick={() => MovePage(data.pk)}/>
                    {likeList.includes(data.pk) === true ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="absolute z-20 self-center w-10 h-10 font-normal text-white cursor-pointer right-2 top-2 lg:right-10" onClick={() => shopLike(true, data.pk)}>
                        <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                      </svg>
                    ): ( 
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute z-20 self-center w-10 h-10 font-normal text-white cursor-pointer right-2 top-2 lg:right-10" onClick={() => shopLike(false, data.pk)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                      </svg>
                    )}
                    {/* <div className="bg-center bg-no-repeat bg-cover w-72 h-72 rounded-2xl" style={ {backgroundImage : `url(${data.img})`}}></div> */}
                    <div className="flex flex-col cursor-pointer" onClick={() => MovePage(data.pk)}>
                      <div className="flex flex-row justify-center py-1 mt-4 rounded-lg bg-rose-500" style={{width: '28%'}}>
                        <p className="self-center text-base text-center text-white font-nanumBold">{data.adType}</p>
                      </div>
                      <p className="font-nexon font-semibold text-lg text-black self-start pt-1 pl-0.5">{data.shopName}</p>
                      <p className="text-base font-nexon text-gray-500 self-start pl-0.5 leading-normal truncate w-72">{data.shopAddr}</p>
                      <p className="text-base font-nexon font-semibold text-gray-700 self-start pl-0.5 leading-normal">잔여 구좌: {data.remainAD}개</p>
                      <p className="text-lg font-nexonBold font-semibold text-black self-start pl-0.5 pt-1">월 {data.adPrice.toLocaleString()}원</p>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col self-end justify-end flex-grow">
        <Footer props={props}/> 
      </div>

    </> 
  )
}
