import React, { useCallback, useRef, Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react'
// Icons 
import { ExclamationIcon, DotsVerticalIcon } from "@heroicons/react/solid"
import { ChevronLeftIcon, ChevronRightIcon, XIcon, PencilIcon, PlusCircleIcon, TrashIcon, CogIcon, PhotographIcon, FilmIcon} from "@heroicons/react/outline"
// Network module 
import axios from 'axios';
import _, { conformsTo } from 'lodash';
import { list } from 'purgecss/node_modules/postcss';
// Date Module 
import moment from "moment"
import configData from 'pages/reactConfig'
import {HOST_ADDR} from 'pages/reactConfig.json'
import UserConfig from 'pages/config/userManager';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class scheduleMgrMobile extends React.Component{
  constructor(props){
    super(props)
    this.state = { 
      isLoading : true, 
      scheduleOfDev : Array(), 
      userAuth : null 
    }
    this.serverAddr = configData['SERVER_URL']
  }

  componentDidMount = async() => {
    const userInfo = UserConfig.getUserInfo() 
    const {userAuth} = userInfo
    this.setState({userAuth: userAuth})
    this.getData()
  }

  getData = async() =>{
    
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}schedule/getSchedulePerDev/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.get(reqURI, headers)
      const resData = res.data 
      let tempArray = Array()
      if (resData.result === 'OK'){
        let wholeScheduleCopy = _.cloneDeep(resData.wholeSchedule);
        wholeScheduleCopy.forEach(element => {
          element['paginationStart'] = 0; 
          element['paginationEnd'] = 5; 
          element['currentPageVisual'] = React.createRef(null); 
          element['maxmiumPageVisual'] = React.createRef(null); 
        });
        console.log(wholeScheduleCopy)
        this.setState({isLoading:false, scheduleOfDev:wholeScheduleCopy}, () => {
          console.log(this.state.scheduleOfDev)
        })
      }
    }catch(e){
      alert('네트워크를 점검하여 주시기 바랍니다.')
    }

    // axios.defaults.withCredentials = true 
    // const url = this.serverAddr+'/schedule/getSchedulePerDev/'
    // await axios.get(url,
    // {
    //   withCredentials:true, 
    //   headers:{
    //     'Content-Type' : 'application/json',
    //     'Accept': 'application/json'
    //   }
    // }).then((response) => {
    //   const data = response.data 
    //   let tempArray = Array()
    //   if (data['result'] === 'OK' && response.status === 200){
    //     let wholeScheduleCopy = _.cloneDeep(data['wholeSchedule']);
    //     wholeScheduleCopy.forEach(element => {
    //       element['paginationStart'] = 0; 
    //       element['paginationEnd'] = 5; 
    //       element['currentPageVisual'] = React.createRef(null); 
    //       element['maxmiumPageVisual'] = React.createRef(null); 
    //     });
    //     console.log(wholeScheduleCopy)
    //     this.setState({isLoading:false, scheduleOfDev:wholeScheduleCopy}, () => {
    //       console.log(this.state.scheduleOfDev)
    //     })
    //   }else if(response.status === 403){
    //     alert('비정상적인 접근입니다.')
    //   }
    // }).catch((error) => {
    //   alert('네트워크를 점검하여 주시기 바랍니다.')
    // })
  }

  tablePagination = (direction, idx) => {
    let schedule = _.cloneDeep(this.state.scheduleOfDev)
    let currentPage = schedule[idx]['currentPageVisual'].current; 
    let maximumPage = schedule[idx]['maxmiumPageVisual'].current; 
    let currentPageIdx = currentPage.innerHTML; 
    let maximumPageIdx = maximumPage.innerHTML

    if(direction === 'prev'){
      if (parseInt(currentPageIdx) !== 1){
        currentPageIdx = parseInt(currentPageIdx) 
        currentPageIdx -= 1
        currentPage.innerHTML = currentPageIdx
        schedule[idx]['paginationStart'] = schedule[idx]['paginationStart'] - 5 
        schedule[idx]['paginationEnd'] = schedule[idx]['paginationEnd'] - 5 
        this.setState({scheduleOfDev : schedule})
      }
    }else if(direction === 'next'){
      if (currentPageIdx < maximumPageIdx){
        currentPage.innerHTML = parseInt(currentPageIdx) + 1; 
        schedule[idx]['paginationStart'] = schedule[idx]['paginationEnd'] 
        schedule[idx]['paginationEnd'] = schedule[idx]['paginationEnd'] + 5 
        this.setState({scheduleOfDev : schedule})
      }
    }else{
      return false; 
    } 
  }

  deleteSchedule = async (idx, scheduleName, category, content) => {
    if(category === 'main'){
      category = 'schedule'
    }else{
      category = 'ad'
    }
    const postData = {'idx': idx, 'schedule': scheduleName, 'target':content, 'type':category}
    const userToken = UserConfig.getAccKey() 
    const reqURI = `${HOST_ADDR}schedule/delete/`
    const headers = { headers : {Authorization:`Bearer ${userToken}`}}

    try{
      const res = await axios.post(reqURI, postData, headers)
      const resData = res.data 
      if (resData.result === 'OK'){
        this.getData()
      }
    }catch(e){
      alert("데이터 삭제에 실패하였습니다.")
    }
  }

  render(){
    const isLoading = this.state.isLoading
    return(
      <div className="w-full h-full mt-4 overflow-y-hidden">
        <div className="bg-blue-50 border-l-4 border-indigo-400 p-4 mb-4 ">
          <div className="flex flex-row justify-between">
            <div className='flex flex-row items-center'> 
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
              </div>
              <div className='ml-2'> 
                <p className="text-sm text-blue-700">
                  매장별로 구성되어 있는 스케줄을 {''}
                  <a href="#" className="font-medium underline text-blue-700 hover:text-blue-600">
                    수정하거나 삭제할 수 있습니다
                  </a>
                  :) 
                </p>
              </div> 
            </div> 
          </div>
        </div>
      {isLoading === true ? ( 
        <div className='w-full h-full flex flex-col justify-center'>
          <svg class="animate-spin h-12 w-12 text-rose-500 self-center" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div> 
      ) : ( 
        <>
        <div className='flex flex-col xl:grid xl:grid-cols-2 xl:gap-4'>
          {this.state.scheduleOfDev.map((schedule, idx) => (
            <div className='flex flex-col gap-y-2 justify-start mb-4'>
              <div className='w-full bg-white border rounded-lg py-2 px-1 overflow-x-auto'>
                <div className="flex flex-row py-2 px-1 w-full overflow-x-auto justify-between">
                  <div className='flex flex-row'>  
                    <span className="text-purple-700 bg-purple-50 rounded-lg inline-flex p-3 ring-4 ring-white py-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      </svg>
                    </span>
                    {/* information about device */}
                    <div className="flex flex-col w-full justify-center ml-2">
                      <p className="items-center self-start text-base font-bold antialiased focus:outline-none cursor-default bg-white">{`${schedule.device} (${schedule.screenType})`}</p> 
                    </div>
                  </div>
                  <div className='flex flex-row gap-x-2 justify-center pr-2'>

                    {/* Pagination visualization */}
                    {schedule.schedule !== null ? (
                      <>
                      {/* Page Control UI(Prev) */}
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center cursor-pointer text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" onClick={() => this.tablePagination('prev', idx)}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                      </svg>

                      <div className='flex flex-row'>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center' ref={schedule.currentPageVisual}>1</span>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center'>/</span>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center' ref={schedule.maxmiumPageVisual}>{Math.ceil(schedule.schedule.length/5)}</span>
                      </div>

                      {/* Page Control UI(Next) */}
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center cursor-pointer text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" onClick={() => this.tablePagination('next', idx)}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                      </svg>
                      </> 
                    ) : ( 
                      <>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center cursor-pointer text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                      </svg>

                      <div className='flex flex-row'>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center' ref={schedule.currentPageVisual}>1</span>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center'>/</span>
                        <span className='text-sm font-semibold antialiased text-gray-700 self-center' ref={schedule.maxmiumPageVisual}>1</span>
                      </div>

                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center cursor-pointer text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                      </svg>
                      </> 
                    )}
                  </div> 
                  {/* pagination  */}
                </div>

                <table className='w-full whitespace-nowrap divide-y divide-gray-200 overflow-x-auto border-t border-gray-200 xl:h-72'>
                  <thead className='bg-white'>
                    <tr> 
                      <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap'>
                        삭제
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap'>
                        구분
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap'>
                        콘텐츠
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap'>
                        송출 기간
                      </th>
                      <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap'>
                        등록일
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200 w-full'>
                    { schedule.schedule !== null ? (
                      schedule.schedule.map((sch, idx) => (
                        idx >= schedule.paginationStart && idx < schedule.paginationEnd ? (
                          <tr>
                            <td className='px-6 py-3'>
                              {sch.category === 'main' ? ( 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="w-5 h-5 text-sm text-red-500 self-center cursor-pointer" onClick={() => this.deleteSchedule(sch.idx, sch.scheduleName, sch.category, 'content')}>
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                </svg>
                              ) : (
                                (this.state.userAuth === 'admin' ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="w-5 h-5 text-sm text-red-500 self-center cursor-pointer" onClick={() => this.deleteSchedule(sch.idx, sch.scheduleName, sch.category, 'content')}>
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="w-5 h-5 text-sm text-gray-500 self-center cursor-pointer" onClick={() => alert("광고 콘텐츠 삭제는 운영사에 문의해주세요.")}>
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                  </svg>
                                ) )
                              )}
                            </td>
                            <td className='text-sm font-normal whitespace-nowrap px-4 py-3'>{sch.category === 'main' ? "메인 스케줄" : "광고 스케줄"}</td>
                            <td className='text-sm font-normal whitespace-nowrap px-4 py-3'>{sch.content}</td>
                            <td className='text-sm font-normal whitespace-nowrap px-4 py-3'>{sch.date}</td>
                            <td className='text-sm font-normal whitespace-nowrap px-4 py-3'>{sch.regDate}</td>
                          </tr>
                        ) : null 
                      ))
                    ) : ( 
                      <tr>
                        <td colSpan='5' align='center' valign='center' className='w-full text-sm font-normal px-4 py-3 text-center align-middle'> 스케줄 없음 </td> 
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div> 
          ))}
        </div>
        </>
      )}
      </div> 
    )
  }
}