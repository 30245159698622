import React, { createElement, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'
import axios from 'axios';
import _ from 'lodash'
import {HOST_ADDR} from 'servicePages/reactConfig.json'
import ServiceHeader from "servicePages/header";
import { Marker, NaverMap, RenderAfterNavermapsLoaded } from "react-naver-maps";
import Footer from "servicePages/footer";
import toast, { Toaster } from 'react-hot-toast';
import { NullCheck } from "servicePages/utility/dataValidation";

export default function InquirySubmit(props){
  const {history} = props
  const inputRefArr = useRef([])
  const submitEdit = (e, idx) => {
    if (e.key === 'Enter'){
      inputRefArr.current[idx+1].focus()
    }
  }  

  const isNum = (val) => {
    return !isNaN(val)
  }

  const check = async() => {
    const name = inputRefArr.current[0].value 
    const email = inputRefArr.current[1].value 
    const inquiry = inputRefArr.current[2].value 
    let checkRes; 

    checkRes = NullCheck(name, "이름") 
    if (checkRes.result === false){
      alert(checkRes.msg)
      return false 
    }

    checkRes = NullCheck(email, "이메일") 
    if (checkRes.result === false){
      alert(checkRes.msg)
      return false 
    }

    if (email.indexOf('@') === -1){
      alert("올바른 형식의 이메일 주소를 입력해주세요.")
      return false 
    }

    checkRes = NullCheck(inquiry, "문의 사항")
    if (checkRes.result === false){
      alert(checkRes.msg)
      return false 
    }

    const reqURI = `${HOST_ADDR}app/v1/users/inquiry/`
    const data = {name:name, email:email, inquiry:inquiry}
    try{
      const res = await axios.post(reqURI, data)
      const resData = res.data 
      if (resData.result === 'OK'){
        alert("문의 사항이 접수되었습니다.")
        window.location.reload()
      }
    }catch(e){
      console.log(e) 
      alert("시스템 오류가 발생하였습니다.\n관리자에게 문의하여 주십시오.")
    }
  }

  return(
    <>
      {/* Toast */}
      <div> <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={{ className: '', duration: 5000, style: { background: '#363636', color: '#fff',}, success: { duration: 3000, theme: { primary: 'green', secondary: 'black', }, }, }}/> </div>
      {/* Header */}
      <div className="container mx-auto px-24 py-12">
        <div className="flex flex-col flex-grow">
          <ServiceHeader props={props}/> 
        </div> 
      </div> 
      

      {/* Divide Line */}
      <div className="border-t-2 border-t-gray-400 h-1"/> 

      <div className="container mx-auto flex flex-col flex-grow justify-start min-h-screen max-h px-24 pt-12">
        <div className="flex flex-row justify-between gap-x-8">
          <div className="flex flex-row justify-start w-3/5">
            <div className="flex flex-col justify-start flex-grow pt-4">
              <p className="text-xl font-nexonBold text-black self-start">스크린에어 이용 및 장애 문의</p>
              <p className="text-lg font-nexon text-black self-start pt-4">스크린에어 서비스의 이용 또는 장애에 대한 문의 처리를 위한 페이지입니다.</p>
              <p className="text-lg font-nexon text-black self-start pt-1 w-4/5">우측 폼을 통해 문의를 접수하거나 아래 연락처 정보로 문의하실 수 있습니다.</p>
              <p className="text-lg font-nexon text-black self-start pt-1 w-4/5">문의 사항은 최대 3 영업일 안에 처리됩니다.</p>

              <p className="text-xl font-nexonBold text-black self-start pt-8 mb-2">문의 연락처 정보</p>
              <div className="w-4/5 rounded-lg shadow-lg border border-gray-400 flex flex-col p-2">
                <div className="flex flex-row justify-start gap-x-4 my-1">
                  <p className="text-lg font-nanum text-black">주식회사 유니크</p>
                  <p className="text-lg font-nanum text-black">|</p>
                  <p className="text-lg font-nanum text-black">대표자: 송기범</p>
                  <p className="text-lg font-nanum text-black">|</p>
                  <p className="text-lg font-nanum text-black">사업자등록번호: 549-87-02426</p>
                </div> 
                <div className="flex flex-row justify-start gap-x-4 my-1">
                  <p className="text-lg font-nanum text-black">통신판매신고번호: 2022-대전유성-1169</p>
                </div> 
                <div className="flex flex-row justify-start gap-x-4 my-1">
                  <p className="text-lg font-nanum text-black">주소: 대전광역시 유성구 봉명동, 지상4층 517호</p>
                </div> 
                <div className="flex flex-row justify-start gap-x-4 my-1">
                  <p className="text-lg font-nanum text-black">대표전화: 070-7762-7064</p>
                  <p className="text-lg font-nanum text-black">|</p>
                  <p className="text-lg font-nanum text-black">E-mail: support@uniqueinc.co.kr</p>
                </div> 
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-start w-2/5">
            <div className="flex flex-col flex-grow rounded-lg border-2 border-gray-300 justify-start p-6 shadow-lg">
              <p className="text-xl font-nexonBold text-black self-center">스크린에어 이용 문의</p>
              <div className="flex flex-row justify-start mt-12 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">이름</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='text' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="이름을 입력해주세요." ref={(ref) => inputRefArr.current[0] = ref} onKeyDown={(e) => submitEdit(e, 0)} /> 

              <div className="flex flex-row justify-start mt-8 gap-x-0.5">
                <p className="text-lg font-nexon text-black self-center">이메일</p>
                <p className="text-base font-nexon self-center text-rose-700">*</p>
              </div>
              <input type='tel' className="border-0 border-b-2 border-b-black focus:outline-none focus:ring-0 focus:border-b-2 focus:border-b-black text-black font-nexon pl-0 mt-2"
              placeholder="이메일 주소를 입력해주세요." ref={(ref) => inputRefArr.current[1] = ref} onKeyDown={(e) => submitEdit(e, 1)} /> 

              <p className="text-lg font-nexon text-black self-start pt-8">문의 사항 </p>
              <textarea rows={5} type='text' className="border border-gray-300 rounded-lg text-black font-nexon pl-2 mt-2 h-48 text-left"
              placeholder="문의 내용을 기재해주세요." ref={(ref) => inputRefArr.current[2] = ref}/> 

              <div className="flex flex-row justify-center rounded-lg bg-rose-500 cursor-pointer mt-4 py-2" onClick={() => check()}>
                <p className="text-lg font-nexon text-white self-center">문의 접수하기</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="flex flex-col justify-end self-end flex-grow">
        <Footer props={props}/> 
      </div>
    </>
  )
}