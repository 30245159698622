
export function emailVerify(email){
  if(email === null || email === undefined || email === ''){
    return {result:false, msg:"이메일을 입력해주세요."}
  }

  if (!email.includes('@')){
    return {result:false, msg:"올바른 형식의 이메일을 입력해주세요"}
  }
  
  return {result:true}
}

export function passwordVerify(password){
  if (password.length <= 8){
    return {result:false, msg:"패스워드는 8글자 이상이어야 합니다."}
  }

  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  let isUpperIn = false; let isLowerIn = false ; let isNumIn = false 

  for(let i=0; i < password.length; i++){
    if(!isNaN(password[i]*1)){
      isNumIn = true 
    }else{
      if(specialChars.test(password[i]) === false){
        if (password[i] === password[i].toUpperCase()){
          isUpperIn = true 
        }
        if (password[i] === password[i].toLowerCase()){
          isLowerIn = true 
        }
      }
    }
    if(isUpperIn === true && isLowerIn === true && isNumIn === true){
      return {result:true}
    }
  }
  return {result:false, msg:"규칙에 맞는 비밀번호를 설정해주세요."}
}

export function getBirth(birth){
  if (birth.length !== 8){
    return null 
  }
  if (isNum(birth) !== true){
    return null 
  }

  let birthYear = birth[0]+birth[1]+birth[2]+birth[3] 
  let birthMon = birth[4]+birth[5] 
  let birthDays = birth[6]+birth[7] 

  const birthdate = `${birthYear}-${birthMon}-${birthDays}`
  return birthdate
}

const isNum = (val) => {
  return !isNaN(val)
}